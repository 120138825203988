import { TableView } from "@/components/table/table-component";
import { TablePageView } from "@/components/table/table-page-view";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { TableViewProvider } from "@/contexts/tabs-context/tab-states/table-tab-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { TableMetadata } from "@api/schemas";
import { Files, Play, Table } from "@phosphor-icons/react";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AutosizeInput from "react-input-autosize";
import { toast } from "sonner";

export const LoadingState = observer(() => {
	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Table - Village</title>
			</Helmet>
			<div className="flex w-full flex-col space-y-2 p-4">
				{[...Array(3)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex space-x-2"
					>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 1,
							}}
						/>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 3,
							}}
						/>
						<Skeleton
							className="h-3"
							style={{
								flexGrow: 2,
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
});

// Separate headers for loading and loaded states because the loading state
// uses appContext to show the table name, but the loaded state needs
// the tableContext to show additional info and toggles
export const LoadingTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		return (
			<div className="flex w-full shrink-0 items-center px-2 pt-4">
				<div className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1">
					{tableMetadata.file_name}
				</div>
			</div>
		);
	},
);

export const LoadedTableHeader = observer(() => {
	const tableViewState = useTableViewContext();
	const appContext = useAppContext();

	const [newName, setNewName] = useState(
		tableViewState.tableState.metadata.file_name,
	);
	useEffect(() => {
		setNewName(tableViewState.tableState.metadata.file_name);
	}, [tableViewState.tableState.metadata.file_name]);

	const onSubmit = () => {
		if (newName === tableViewState.tableState.metadata.file_name) {
			return;
		}
		if (newName.trim() === "") {
			toast.error("Table name cannot be empty");
			setNewName(tableViewState.tableState.metadata.file_name);
			return;
		}
		appContext.renameFile({
			fileId: tableViewState.tableState.metadata.table_id,
			newName: newName,
		});
	};

	return (
		<>
			<div className="flex w-full shrink-0 items-center px-4 pt-4">
				<AutosizeInput
					value={newName}
					className="rounded-md border border-transparent bg-white font-semibold text-lg"
					placeholder="Untitled table"
					onChange={(e) => {
						setNewName(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onSubmit();
						}
					}}
					onBlur={onSubmit}
				/>
			</div>

			<div className="flex shrink-0 items-end justify-end border-neutral-100 border-b text-sm">
				{tableViewState.tableState.sortedRecords.length > 0 && (
					<div className="grow">
						<Tabs.List className="flex">
							<Tabs.Trigger
								value="table"
								className={clsx(
									"flex items-center gap-1 px-2 py-2",
									tableViewState.view === "table" && "shadow-[0_1px_0_0_black]",
								)}
							>
								<Table />
								Default
							</Tabs.Trigger>
							<Tabs.Trigger
								value="page"
								className={clsx(
									"flex items-center gap-1 px-2 py-2",
									tableViewState.view === "record" &&
										"shadow-[0_1px_0_0_black]",
								)}
							>
								<Files />
								Page
							</Tabs.Trigger>
						</Tabs.List>
					</div>
				)}

				<div className="flex items-center px-3 py-2">
					{IS_DEV && (
						<div className="ml-2 flex max-w-max items-center gap-2 rounded-md border bg-neutral-50 p-1">
							<Switch
								checked={tableViewState.devMode}
								onCheckedChange={(checked) => {
									runInAction(() => {
										tableViewState.devMode = checked;
									});
								}}
							/>{" "}
							<Label>Dev mode</Label>
						</div>
					)}
					{tableViewState.view === "table" && (
						<Button
							onClick={() =>
								appContext.fillTable({
									tableId: tableViewState.tableState.metadata.table_id,
									tableName: tableViewState.tableState.metadata.file_name,
								})
							}
							className="ml-2 flex h-7 items-center gap-1"
						>
							Fill table
							<Play weight="fill" />
						</Button>
					)}
					{tableViewState.view === "record" && (
						<Button
							onClick={() => {
								// TODO: Implement
							}}
							className="ml-2 flex h-7 items-center gap-1"
						>
							Fill fields
							<Play weight="fill" />
						</Button>
					)}
				</div>
			</div>
		</>
	);
});

export const TablePage = observer(() => {
	const tab = useTab();
	const tabState = tab.state as TabTypeStateMap["table"];
	const tableViewState = tabState.tableViewState;

	if (!tableViewState) {
		return (
			<div className="flex h-full flex-col">
				<LoadingTableHeader tableMetadata={tabState.tableMetadata} />
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			<TableViewProvider tableViewState={tableViewState}>
				<Tabs.Root
					defaultValue="table"
					value={tableViewState.view}
					onValueChange={(value) =>
						tableViewState.setView(value as "table" | "record")
					}
				>
					<LoadedTableHeader />
					<Tabs.Content
						value="table"
						forceMount
						className="data-[state=inactive]:hidden"
					>
						<TableView />
					</Tabs.Content>
					<Tabs.Content
						value="page"
						forceMount
						className="data-[state=inactive]:hidden"
					>
						<TablePageView />
					</Tabs.Content>
				</Tabs.Root>
			</TableViewProvider>
		</div>
	);
});
