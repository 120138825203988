import { ViewOnlyEditor } from "@/components/editor";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverAnchor,
	PopoverContent,
} from "@/components/ui/popover";
import type { ActiveSessionWithStatus } from "@/contexts/app-context/db-store/assistant-session-store";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { formatRelativeDate } from "@/lib/formatting";
import { X } from "@phosphor-icons/react";
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

const columnHelper = createColumnHelper<ActiveSessionWithStatus>();

const columns = [
	columnHelper.accessor("assistantSession.goal", {
		header: "Goal",
		size: 300,
		cell: ({ row }) => {
			return <ViewOnlyEditor content={row.original.assistantSession.goal} />;
		},
	}),
	columnHelper.accessor("status", {
		header: "Status",
		size: 100,
	}),
	columnHelper.accessor("assistantSession.started_at", {
		header: "Started at",
		size: 170,
		cell: ({ row }) => {
			return formatRelativeDate(row.original.assistantSession.started_at);
		},
	}),
];

export const ActiveSessionsDialog = observer(() => {
	const appState = useAppContext();
	const assistantSessionStore = appState.assistantSessionStore;

	const table = useReactTable({
		data: assistantSessionStore.activeSessionsWithStatus,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Popover open={appState.rightSidebarState.activeSessionsDialogOpen}>
			<PopoverAnchor className="fixed bottom-0 left-1/2" />
			<PopoverContent
				className="flex w-fit flex-col gap-2 pt-3"
				align="center"
				side="top"
				sideOffset={24}
			>
				{/* Header */}
				<div className="flex items-center justify-between">
					<span className="font-semibold text-sm">Active Sessions</span>
					<Button
						className="h-7 w-7"
						variant="ghost"
						size="icon"
						onClick={() =>
							appState.rightSidebarState.setActiveSessionsDialogOpen(false)
						}
					>
						<X />
					</Button>
				</div>
				<div aria-label="table" className="border text-sm">
					<div aria-label="thead">
						{table.getHeaderGroups().map((headerGroup) => (
							<div
								key={headerGroup.id}
								aria-label="thead"
								className="flex bg-neutral-50 font-medium text-neutral-500"
							>
								{headerGroup.headers.map((header) => (
									<div
										key={header.id}
										aria-label="th"
										className="px-3 py-2"
										style={{ width: header.getSize() }}
									>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</div>
								))}
							</div>
						))}
					</div>
					<div aria-label="tbody">
						{table.getRowModel().rows.map((row) => (
							<div key={row.id} aria-label="tr" className="flex">
								{row.getVisibleCells().map((cell) => (
									<div
										key={cell.id}
										aria-label="td"
										className="px-3 py-2"
										style={{ width: cell.column.getSize() }}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								))}
							</div>
						))}
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
});
