import type { AppState } from "@/contexts/app-context/app-context";
import { SearchableMap } from "@/contexts/app-context/db-store/searchable-map";
import {
	addLookupFieldAction,
	addPrimitiveFieldAction,
	addRecordAction,
	addRelationshipFieldAction,
	addSelectOptionAction,
	createTableAction,
	deleteFieldAction,
	deleteRecordsAction,
	deleteSelectOptionAction,
	moveFieldAction,
	moveRecordsAction,
	updateCellValuesAction,
	updateFieldAction,
	updateSelectOptionColorAction,
	updateSelectOptionLabelAction,
} from "@/contexts/app-context/db-store/table-handlers";
import { getTableRecordsRoute } from "@api/fastAPI";
import type {
	AddField,
	AddSelectOption,
	CellValue,
	DeleteField,
	DeleteRecords,
	DeleteSelectOption,
	Field,
	FieldId,
	Record,
	RecordId,
	TableId,
	TableMetadata,
	TableUpdate,
	UpdateCells,
	UpdateField,
	UpdateRecordOrders,
	UpdateSelectOptionColor,
	UpdateSelectOptionLabel,
	UpsertRecord,
	UpsertTableMetadata,
} from "@api/schemas";
import { makeAutoObservable, runInAction } from "mobx";

export class TablesStore {
	appState: AppState;
	items: SearchableMap<TableMetadata, "table_id">;

	// Cache of loaded table records
	loadedTableRecords: Map<TableId, Record[]> = new Map();

	constructor(appState: AppState, tables: TableMetadata[]) {
		this.appState = appState;
		this.items = new SearchableMap(tables, {
			id: "table_id",
			fields: ["file_name"],
		});

		makeAutoObservable(this);
	}

	upsertTableMetadataLocally(data: UpsertTableMetadata): void {
		this.items.set(data.table_metadata.table_id, data.table_metadata);
	}

	createTable = createTableAction.bind(this);

	getTableStateById(tableId: TableId): TableState | null {
		const tableMetadata = this.items.get(tableId);
		if (!tableMetadata) {
			throw new Error(`Table metadata for table with ID ${tableId} not found`);
		}

		const tableRecords = this.loadedTableRecords.get(tableId);
		if (!tableRecords) {
			this.fetchTableRecords(tableId);
			return null;
		}

		return new TableState({
			tablesStore: this,
			metadata: tableMetadata,
			records: new Map(
				tableRecords.map((record) => [record.record_id, record]),
			),
		});
	}

	async fetchTableRecords(tableId: TableId) {
		const recordsResponse = await getTableRecordsRoute({
			table_id: tableId,
		});
		const records = recordsResponse.data.records;
		runInAction(() => {
			this.loadedTableRecords.set(tableId, records);
		});
		return records;
	}

	/**
	 * Handle an Update that comes in from the websocket.
	 */
	handleTableUpdate(data: TableUpdate) {
		if (data.type === "upsert_table_metadata") {
			this.upsertTableMetadataLocally(data);
			return;
		}

		const table = this.getTableStateById(
			"table_id" in data ? data.table_id : data.field.table_id,
		);
		if (!table) {
			return;
		}
		switch (data.type) {
			case "upsert_record": {
				table.upsertRecordLocally(data);
				break;
			}

			case "update_record_orders": {
				table.updateRecordOrdersLocally(data);
				break;
			}

			case "update_cells": {
				table.updateCellValuesLocally(data);
				break;
			}

			case "delete_records": {
				table.deleteRecordsLocally(data);
				break;
			}

			case "add_field": {
				table.addFieldLocally(data);
				break;
			}

			case "update_field": {
				table.updateFieldLocally(data);
				break;
			}

			case "delete_field": {
				table.deleteFieldLocally(data);
				break;
			}

			case "add_select_option": {
				table.addSelectOptionLocally(data);
				break;
			}

			case "delete_select_option": {
				table.deleteSelectOptionLocally(data);
				break;
			}

			case "update_select_option_label": {
				table.updateSelectOptionLabelLocally(data);
				break;
			}

			case "update_select_option_color": {
				table.updateSelectOptionColorLocally(data);
				break;
			}

			default: {
				const _exhaustiveCheck: never = data;
				return _exhaustiveCheck;
			}
		}
	}
}

export class FieldsStore {
	appState: AppState;
	items: Map<FieldId, Field>;

	constructor(appState: AppState, fields: Field[]) {
		this.appState = appState;
		this.items = new Map(fields.map((field) => [field.field_id, field]));

		makeAutoObservable(this);
	}

	get(id: FieldId): Field | undefined {
		return this.items.get(id);
	}

	upsertField(field: Field): void {
		this.items.set(field.field_id, field);
	}

	deleteField(fieldId: FieldId): void {
		const field = this.items.get(fieldId);
		if (!field) {
			throw new Error(`Field with ID ${fieldId} not found`);
		}
		field.deleted_at = new Date().toISOString();
	}

	getTableFields(tableId: TableId): Field[] {
		return Array.from(this.items.values()).filter(
			(field) => field.table_id === tableId && field.deleted_at === null,
		);
	}
}
/**
 * The data for a table.
 */
export class TableState {
	tablesStore: TablesStore;
	readonly metadata: TableMetadata;
	records: Map<RecordId, Record>;

	// TODO(John): fix this
	isComputedTable = false;

	constructor(props: {
		tablesStore: TablesStore;
		metadata: TableMetadata;
		records: Map<RecordId, Record>;
	}) {
		this.tablesStore = props.tablesStore;
		this.metadata = props.metadata;
		this.records = props.records;
		makeAutoObservable(this);
	}

	get tableId() {
		return this.metadata.table_id;
	}

	get fields(): ReadonlyMap<FieldId, Field> {
		return new Map(
			this.tablesStore.appState.workspace?.fields
				.getTableFields(this.tableId)
				.map((field) => [field.field_id, field]),
		);
	}

	getFieldById(fieldId: FieldId): Field {
		const field = this.fields.get(fieldId);
		if (!field) {
			throw new Error(`Field with ID ${fieldId} not found`);
		}
		return field;
	}

	getRecordById(recordId: RecordId): Record {
		const record = this.records.get(recordId);
		if (!record) {
			throw new Error(`Record with ID ${recordId} not found`);
		}
		return record;
	}

	getCellValue(recordId: RecordId, field: Field): CellValue {
		return this.getRecordById(recordId).cell_values[field.field_id];
	}

	get sortedFields(): Field[] {
		const fields = this.fields;
		return [...fields.values()].sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});
	}

	get fieldToSortedIndexMap(): Map<FieldId, number> {
		return new Map(this.sortedFields.map((c, i) => [c.field_id, i]));
	}

	get sortedRecords(): Record[] {
		const records = this.records;
		return [...records.values()].sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});
	}

	get recordToSortedIndexMap(): Map<RecordId, number> {
		return new Map(this.sortedRecords.map((r, i) => [r.record_id, i]));
	}

	getRecordBefore(recordId: RecordId): Record | null {
		const index = this.recordToSortedIndexMap.get(recordId);
		if (index === undefined || index === 0) {
			return null;
		}
		return this.sortedRecords.at(index - 1) ?? null;
	}

	getRecordAfter(recordId: RecordId): Record | null {
		const index = this.recordToSortedIndexMap.get(recordId);
		if (index === undefined || index === this.sortedRecords.length - 1) {
			return null;
		}
		return this.sortedRecords.at(index + 1) ?? null;
	}

	getFieldBefore(fieldId: FieldId): Field | null {
		const index = this.fieldToSortedIndexMap.get(fieldId);
		if (index === undefined || index === 0) {
			return null;
		}
		return this.sortedFields.at(index - 1) ?? null;
	}

	getFieldAfter(fieldId: FieldId): Field | null {
		const index = this.fieldToSortedIndexMap.get(fieldId);
		if (index === undefined || index === this.sortedFields.length - 1) {
			return null;
		}
		return this.sortedFields.at(index + 1) ?? null;
	}

	/**
	 * START OF LOCAL UPDATE HANDLERS
	 *
	 * There should be one handler for each event, and the events also track the
	 * API routes on the backend, so the logic should match there.
	 *
	 * Except for creating/deleting tables, which we put on the store.
	 */

	/**
	 * Document CRUD
	 */

	upsertRecordLocally(record: UpsertRecord): void {
		const recordId = record.record.record_id;
		this.records.set(recordId, record.record);
	}

	updateRecordOrdersLocally(data: UpdateRecordOrders): void {
		for (const [recordId, newOrder] of Object.entries(data.new_record_orders)) {
			const record = this.getRecordById(recordId as RecordId);
			record.order = newOrder;
		}
	}

	updateCellValuesLocally(data: UpdateCells): void {
		for (const recordId of data.record_ids) {
			const record = this.getRecordById(recordId);
			record.cell_values[data.field_id] = data.cell_value;
		}
	}

	deleteRecordsLocally(data: DeleteRecords): void {
		for (const recordId of data.record_ids) {
			this.records.delete(recordId);
		}
	}

	/**
	 * Field CRUD
	 */

	addFieldLocally(data: AddField): void {
		this.tablesStore.appState.workspace?.fields.upsertField(data.field);
	}

	updateFieldLocally(data: UpdateField): void {
		const field = this.getFieldById(data.field_id);
		if (data.name !== null && data.name !== undefined) {
			field.name = data.name;
		}
		if (data.order !== null && data.order !== undefined) {
			field.order = data.order;
		}
		if (data.width !== null && data.width !== undefined) {
			field.width = data.width;
		}
	}

	deleteFieldLocally(data: DeleteField): void {
		this.tablesStore.appState.workspace?.fields.deleteField(data.field_id);
		// Also need to delete all field values for this field from all documents
		for (const record of this.records.values()) {
			delete record.cell_values[data.field_id];
		}
	}

	/**
	 * Special select field CRUD
	 */

	addSelectOptionLocally(data: AddSelectOption): void {
		const field = this.getFieldById(data.field_id);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		if (data.new_option.label in field.properties.options) {
			throw new Error(`Option '${data.new_option.label}' already exists.`);
		}
		field.properties.options[data.new_option.label] = data.new_option;
		// If document_id_to_set is provided, update that document's field value
		if (data.record_id_to_set) {
			this.updateCellValuesLocally({
				type: "update_cells",
				table_id: this.tableId,
				record_ids: [data.record_id_to_set],
				field_id: data.field_id,
				cell_value: data.new_option.label,
			});
		}
	}
	/**
	 * Deletes a select option from a field and nulls that field fors documents
	 * that had it selected.
	 *
	 * @returns Modified record IDs
	 * @throws {Error} If the field is not a select field or if the option doesn't exist
	 */
	deleteSelectOptionLocally(data: DeleteSelectOption): RecordId[] {
		const field = this.getFieldById(data.field_id);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		if (!(data.label in field.properties.options)) {
			throw new Error(`Option '${data.label}' does not exist.`);
		}
		delete field.properties.options[data.label];
		// Clear this option from any records that had it selected
		const recordIds = Array.from(this.records.values())
			.filter((record) => record.cell_values[data.field_id] === data.label)
			.map((record) => record.record_id);
		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: recordIds,
			field_id: data.field_id,
			cell_value: null,
		});
		return recordIds;
	}

	/**
	 * Updates the label of a select option.
	 *
	 * @returns Modified record IDs
	 * @throws {Error} If the field is not a select field or if the new label already exists
	 */
	updateSelectOptionLabelLocally(data: UpdateSelectOptionLabel): RecordId[] {
		const field = this.getFieldById(data.field_id);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		if (data.new_label in field.properties.options) {
			throw new Error(`Option '${data.new_label}' already exists.`);
		}
		const option = Object.values(field.properties.options).find(
			(option) => option.label === data.old_label,
		);
		if (!option) {
			throw new Error(`Option with label ${data.old_label} not found`);
		}
		option.label = data.new_label;
		// Update all records that had the old label
		const recordIds = Array.from(this.records.values())
			.filter((record) => record.cell_values[data.field_id] === data.old_label)
			.map((record) => record.record_id);
		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: recordIds,
			field_id: data.field_id,
			cell_value: data.new_label,
		});
		return recordIds;
	}

	updateSelectOptionColorLocally(data: UpdateSelectOptionColor): void {
		const field = this.getFieldById(data.field_id);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		const option = Object.values(field.properties.options).find(
			(option) => option.label === data.label,
		);
		if (!option) {
			throw new Error(`Option with label ${data.label} not found`);
		}
		option.color = data.color;
	}

	/**
	 * END OF LOCAL UPDATE HANDLERS
	 */

	/**
	 * Action bindings from table-handlers
	 */
	addRecords = addRecordAction.bind(this);
	moveRecords = moveRecordsAction.bind(this);
	updateCellValues = updateCellValuesAction.bind(this);
	deleteRecords = deleteRecordsAction.bind(this);
	addPrimitiveField = addPrimitiveFieldAction.bind(this);
	addLookupField = addLookupFieldAction.bind(this);
	addRelationshipField = addRelationshipFieldAction.bind(this);
	updateField = updateFieldAction.bind(this);
	moveField = moveFieldAction.bind(this);
	deleteField = deleteFieldAction.bind(this);
	addSelectOption = addSelectOptionAction.bind(this);
	deleteSelectOption = deleteSelectOptionAction.bind(this);
	updateSelectOptionLabel = updateSelectOptionLabelAction.bind(this);
	updateSelectOptionColor = updateSelectOptionColorAction.bind(this);
}
