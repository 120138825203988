import { ViewOnlyEditor } from "@/components/editor";
import {
	ActiveAssistantStatusIndicator,
	Chat,
	MessageInput,
} from "@/components/layout/right-sidebar/messages";
import { Button } from "@/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import type { AssistantEvent } from "@/contexts/app-context/db-store/event-store";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ObjectLinkComponent, ObjectLinkContent } from "@/plugins/object-link";
import type {
	Action,
	AssistantSessionPathObject,
	CreatedAssistantSessionEvent,
	Event,
	MessageId,
	MessagePathObject,
	SentMessageEvent,
	Step,
} from "@api/schemas";
import {
	CaretDown,
	ChatTeardropDots,
	ClockCounterClockwise,
	Eyeglasses,
	Keyboard,
	MouseSimple,
	X,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

const SentMessageEventDataComponent = observer(
	({ event }: { event: SentMessageEvent["data"] }) => {
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Content
				</span>
				<ViewOnlyEditor
					className="border p-1"
					content={event.message.content}
				/>
			</div>
		);
	},
);

const CreatedAssistantSessionEventDataComponent = observer(
	({ event }: { event: CreatedAssistantSessionEvent["data"] }) => {
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Goal
				</span>
				<ViewOnlyEditor
					className="border p-1"
					content={event.assistant_session.goal}
				/>
			</div>
		);
	},
);

// const TableCellValueComponent = observer(
// 	({ event }: { event: UpdatedCellEvent["data"] }) => {
// 		return (
// 			<div className="inline-flex w-full flex-col">
// 				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
// 					Cell Value
// 				</span>
// 				<ViewOnlyEditor
// 					className="border p-1"
// 					// TODO(John): other cell types
// 					// @ts-ignore
// 					content={event.table_cell_value.cell_value}
// 				/>
// 			</div>
// 		);
// 	},
// );

const EventComponent = observer(({ event }: { event: AssistantEvent }) => {
	const renderEventData = (
		event: Event,
	): {
		header: React.ReactNode;
		content: React.ReactNode;
	} => {
		switch (event.type) {
			case "sent_message": {
				const messageId = event.data.message.message_id;
				const pathObject: MessagePathObject = {
					path: "message",
					message_id: messageId as MessageId,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Sent</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} label="Message" />
							</ObjectLinkComponent>
						</>
					),
					content: <SentMessageEventDataComponent event={event.data} />,
				};
			}

			case "created_assistant_session": {
				const pathObject: AssistantSessionPathObject = {
					path: "assistant-session",
					session_assistant_id:
						event.data.assistant_session.session_assistant_id,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Created</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent
									pathObject={pathObject}
									label="Assistant Session"
								/>
							</ObjectLinkComponent>
						</>
					),
					content: (
						<CreatedAssistantSessionEventDataComponent event={event.data} />
					),
				};
			}

			case "opened_thread": {
				const pathObject: MessagePathObject = {
					path: "message",
					message_id: event.data.thread_id as MessageId,
				};
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} label="Thread" />
							</ObjectLinkComponent>
						</>
					),
					content: null,
				};
			}

			case "opened_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							{/* TODO(John): merge path objects */}
							{/* <ObjectLinkComponent pathObject={event.data.tab.path_object} className="min-w-0">
								<ObjectLinkContent pathObject={event.data.tab.path_object} />
							</ObjectLinkComponent> */}
						</>
					),
					content: null,
				};
			}

			case "navigated_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							{/* TODO(John): merge path objects */}
							{/* <ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} />
							</ObjectLinkComponent> */}
						</>
					),
					content: null,
				};
			}

			case "closed_tab": {
				return {
					// TODO(John): more information about this tab
					header: <span>Closed Tab</span>,
					content: null,
				};
			}

			// case "created_table": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Table</span>,
			// 		content: null,
			// 	};
			// }

			// case "created_row": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Row</span>,
			// 		content: null,
			// 	};
			// }

			// case "created_column": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Column</span>,
			// 		content: null,
			// 	};
			// }

			// case "updated_cell": {
			// 	const tableId = event.data.table_id;
			// 	const pathObject: TablePathObject = {
			// 		path: "table",
			// 		file_id: tableId,
			// 		row_id: null,
			// 	};
			// 	return {
			// 		header: (
			// 			<>
			// 				<span className="mr-1.5 flex-none">Updated</span>
			// 				<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
			// 					<ObjectLinkContent pathObject={pathObject} label="Table" />
			// 				</ObjectLinkComponent>
			// 				<span className="ml-1.5 flex-none">Cell</span>
			// 			</>
			// 		),
			// 		content: <TableCellValueComponent event={event.data} />,
			// 	};
			// }

			case "created_user_session": {
				console.error("Should never happen");
				return {
					header: <span>Created User Session</span>,
					content: null,
				};
			}

			case "ended_session": {
				return {
					header: <span>Ended Session</span>,
					content: null,
				};
			}

			default: {
				const _exhaustiveCheck: never = event;
				return _exhaustiveCheck;
			}
		}
	};

	const { header, content } = renderEventData(event);
	return (
		<div className="flex flex-col gap-1 p-2">
			{/* Header */}
			<div className="flex flex-nowrap items-center justify-between gap-2">
				<div className="flex min-w-0 grow flex-nowrap items-center">
					{header}
				</div>
				<span className="flex-none text-neutral-500">
					{new Date(event.created_at).toLocaleString("en-US", {
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					})}
				</span>
			</div>
			<div className="">{content}</div>
		</div>
	);
});

const StepComponent = observer(({ step }: { step: Step }) => {
	const renderAction = (action: Action, index: number) => {
		switch (action.type) {
			case "edit_text":
				return (
					<div className="flex items-center gap-2" key={index}>
						<Keyboard size={16} weight="fill" />
						<span>{action.replacement}</span>
					</div>
				);
			case "click":
				return (
					<div className="flex items-center gap-2" key={index}>
						<MouseSimple size={16} weight="fill" />
						<span>Click</span>
					</div>
				);
		}
	};
	return (
		<div className="flex flex-col gap-2 px-2">
			<span>Thinking</span>
			<ViewOnlyEditor className="border p-1" content={step.thinking} />
			{step.actions.map((action, index) => renderAction(action, index))}
		</div>
	);
});

/**
 * Component that shows the activity of the assistant.
 */
const AssistantActivityViewer = observer(() => {
	const appContext = useAppContext();
	if (
		appContext.rightSidebarState.activityViewerActiveSessionAssistantId === null
	) {
		if (appContext.assistantSessionStore.assistantSessions.size === 0) {
			return <div>No assistant sessions</div>;
		}
		return (
			<div className="flex h-full w-full flex-col gap-4 overflow-y-auto py-2">
				<div className="flex flex-col gap-2">
					<div className="font-semibold">Currently Active</div>
					<div className="flex flex-wrap gap-2">
						{appContext.assistantSessionStore.activeAssistantSessionStatuses
							.size ? (
							Array.from(
								appContext.assistantSessionStore.activeAssistantSessionStatuses.entries(),
							).map(([sessionAssistantId]) => (
								<ActiveAssistantStatusIndicator
									key={sessionAssistantId}
									sessionAssistantId={sessionAssistantId}
								/>
							))
						) : (
							<div className="text-neutral-500">
								No active assistants. Try sending a message!
							</div>
						)}
					</div>
				</div>
				<Collapsible className="flex flex-col gap-2">
					<CollapsibleTrigger className="flex items-center gap-2 text-left font-semibold">
						<span>Recently Ended</span>
						<CaretDown size={16} className="[[data-state=open]_&]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent className="flex flex-col">
						{Array.from(
							appContext.assistantSessionStore.assistantSessions.entries(),
						).map(([sessionAssistantId, assistantSession]) => (
							<Button
								key={sessionAssistantId}
								variant="ghost"
								className="justify-between rounded-none"
								onClick={() => {
									appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
										sessionAssistantId;
								}}
							>
								<span className="text-sm">
									Session {sessionAssistantId.slice(-4)}
								</span>
								<span>
									{new Date(assistantSession.started_at).toLocaleString(
										"en-US",
										{
											month: "numeric",
											day: "numeric",
											hour: "numeric",
											minute: "numeric",
										},
									)}
								</span>
							</Button>
						))}
					</CollapsibleContent>
				</Collapsible>
			</div>
		);
	}

	const activeAssistantActivity =
		appContext.getSortedAssistantActivityForSession(
			appContext.rightSidebarState.activityViewerActiveSessionAssistantId,
		);
	if (!activeAssistantActivity) {
		console.error("Could not find active assistant activity");
		return <div>Could not find active assistant activity</div>;
	}
	return (
		<div className="flex h-full w-full flex-col">
			{/* Header */}
			<div className="flex flex-none items-center justify-between gap-2 border-b px-2 py-2">
				<span className="font-medium">
					Session{" "}
					{appContext.rightSidebarState.activityViewerActiveSessionAssistantId.slice(
						-4,
					)}
				</span>
				<Button
					variant="ghost"
					onClick={() => {
						appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
							null;
					}}
					className="h-min w-min items-start p-1"
				>
					<X size={16} />
				</Button>
			</div>
			{/* Activity */}
			<div className="flex w-full grow flex-col gap-2 overflow-y-auto">
				{activeAssistantActivity.map((eventOrStep) => {
					if ("event_id" in eventOrStep) {
						return (
							<EventComponent key={eventOrStep.event_id} event={eventOrStep} />
						);
					}
					return <StepComponent key={eventOrStep.step_id} step={eventOrStep} />;
				})}
			</div>
		</div>
	);
});

/**
 * The right sidebar, which shows messages and session activity.
 */
export const RightSidebar = observer(() => {
	const appContext = useAppContext();
	return (
		appContext.rightSidebarState.showRightSidebar && (
			<div className="flex h-full w-full flex-col gap-4 bg-white py-2">
				{/* Header */}
				<div className="flex justify-between px-2">
					<div className="flex grow items-center gap-2">
						<div className="flex h-7 w-7 items-center justify-center bg-neutral-100">
							<Eyeglasses size={16} />
						</div>
						<span className="font-medium">Assistant</span>
					</div>
					{appContext.assistantSessionStore.activeAssistantSessionStatuses
						.size > 0 && (
						<Button
							variant="outline"
							className={clsx(
								"w-24 gap-2 rounded-sm font-normal text-neutral-500 shadow-none",
								appContext.rightSidebarState.activeSessionsDialogOpen &&
									"border-neutral-300 bg-neutral-100 text-neutral-950",
							)}
							onClick={() => {
								appContext.rightSidebarState.toggleActiveSessionsDialog();
							}}
						>
							<span className="relative flex h-2 w-2">
								<span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75" />
								<span className="relative inline-flex h-2 w-2 rounded-full bg-green-500" />
							</span>
							<span>Active</span>
						</Button>
					)}
				</div>
				<Tabs
					value={appContext.rightSidebarState.rightSidebarTab}
					onValueChange={(value) => {
						appContext.rightSidebarState.rightSidebarTab = value as
							| "messages"
							| "assistant_activity";
					}}
					className="flex h-full w-full flex-col bg-white"
				>
					<TabsList className="flex-none">
						<TabsTrigger value="messages" className="gap-1">
							<ChatTeardropDots size={16} />
							<span>Chat</span>
						</TabsTrigger>
						<TabsTrigger value="assistant_activity" className="gap-1">
							<ClockCounterClockwise size={16} />
							<span>Sessions</span>
						</TabsTrigger>
					</TabsList>
					{/* Radix keeps inactive tabs around in the DOM, so we need to
				hide them with data-[state=inactive]:hidden to make sure the
				children's grows don't interfere. */}
					{/* Also, these min-h-0's are important. Otherwise, the "grow"
				elements have a min-h of their contents, which causes them to
				grow beyond the bounds of their parents if their children are
				large. */}
					<TabsContent
						value="messages"
						className="flex min-h-0 grow flex-col data-[state=inactive]:hidden"
					>
						<div className="min-h-0 grow">
							<Chat />
						</div>
						<div className="w-full flex-none">
							<MessageInput />
						</div>
					</TabsContent>
					<TabsContent
						value="assistant_activity"
						className="min-h-0 grow text-sm data-[state=inactive]:hidden"
					>
						<AssistantActivityViewer />
					</TabsContent>
				</Tabs>
			</div>
		)
	);
});
