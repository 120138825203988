import { ToggleRightSidebarButton } from "@/components/layout/toggle-right-sidebar-button";
import { ToggleSidebarButton } from "@/components/layout/toggle-sidebar-button";
import { TopBarSearch } from "@/components/layout/top-bar-search";

export const TopBar = () => {
	return (
		<div className="flex w-full shrink-0 items-center justify-between gap-2 border-b bg-white px-4 py-1.5">
			<ToggleSidebarButton />
			<div className="flex max-w-3xl grow items-center gap-2">
				{/* <button
					type="button"
					onClick={() => {
						console.log(toJS(tabsContext.appState));
					}}
					className="text-nowrap text-neutral-500 text-xs"
				>
					<span>Log AppState</span>
				</button> */}
				{/* <button
					type="button"
					onClick={() => {
						navigate(-1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowLeft />
				</button>
				<button
					type="button"
					onClick={() => {
						navigate(1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowRight />
				</button> */}
				<TopBarSearch />
			</div>
			<ToggleRightSidebarButton />
		</div>
	);
};
