import { CustomEditor } from "@/components/editor";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { FeedItemSidebar } from "@/pages/feed/feed-item-sidebar";
import { useGetFeedItem } from "@api/fastAPI";
import type { FeedItemId } from "@api/schemas";
import { ArrowUpRight, DotOutline, SidebarSimple } from "@phosphor-icons/react";
import { type Editor, generateJSON } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import { StarterKit } from "@tiptap/starter-kit";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { motion } from "motion/react";
import { useMemo, useState } from "react";
import ImageResize from "tiptap-extension-resize-image";

const _FeedItemViewer = observer(
	({ feedItemId }: { feedItemId: FeedItemId }) => {
		const { data: feedItem } = useGetFeedItem({ feed_item_id: feedItemId });
		const [showSidebar, setShowSidebar] = useState(false);

		const [editor, setEditor] = useState<Editor | null>(null);

		const content = useMemo(() => {
			if (feedItem?.data.feed_item_cleaned_html) {
				const tiptapContent = generateJSON(
					feedItem?.data.feed_item_cleaned_html,
					[StarterKit, ImageResize, Link],
				);
				return tiptapContent;
			}
			return null;
		}, [feedItem?.data.feed_item_cleaned_html]);

		if (!feedItem) {
			return null;
		}

		return (
			<>
				<div className="flex h-14 w-full min-w-0 shrink-0 items-center gap-2 truncate border-b px-3 text-sm">
					<div className="flex min-w-48 items-center gap-2 ">
						<button
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setShowSidebar(!showSidebar);
							}}
							className="rounded-lg p-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-800"
						>
							<SidebarSimple size={16} />
						</button>
						<HoverCard>
							<HoverCardTrigger className="flex flex-col justify-center truncate text-sm">
								<h1 className="min-w-0 truncate font-semibold text-neutral-700">
									{feedItem.data.file_name}
								</h1>
								{feedItem.data.feed_item_link && (
									<h2>
										<a
											href={feedItem.data.feed_item_link}
											target="_blank"
											rel="noreferrer"
											className="flex items-center gap-1 truncate text-blue-500 hover:underline"
										>
											View original
											<ArrowUpRight weight="bold" />
										</a>
									</h2>
								)}
							</HoverCardTrigger>
							<HoverCardContent align="start" className="w-96 gap-3 text-sm">
								{feedItem.data.feed_item_og_image && (
									<img
										src={feedItem.data.feed_item_og_image}
										alt={feedItem.data.file_name}
										className="mb-3 flex w-full shrink-0 items-center justify-center rounded object-cover text-xs"
									/>
								)}
								<h1 className="min-w-0 font-semibold text-neutral-700">
									{feedItem.data.file_name}
								</h1>
								{feedItem.data.feed_item_author && (
									<h2 className="mt-0.5 flex items-center text-neutral-500">
										By {feedItem.data.feed_item_author}
										{feedItem.data.feed_item_wordcount && (
											<>
												<DotOutline weight="fill" className="shrink-0" />
												<h2 className="text-neutral-500">
													{feedItem.data.feed_item_wordcount} word
													{feedItem.data.feed_item_wordcount > 1 ? "s" : ""}
												</h2>
											</>
										)}
									</h2>
								)}
								<div className="mt-2 w-full rounded-lg bg-neutral-100 px-2 py-1">
									{feedItem.data.feed_item_pub_date && (
										<h2 className="text-neutral-500">
											Published{" "}
											{dayjs(feedItem.data.feed_item_pub_date).format(
												"MMM D, YYYY h:mm A",
											)}
										</h2>
									)}
									<h2 className="text-neutral-500">
										Retrieved{" "}
										{dayjs(feedItem.data.file_created_at).format(
											"MMM D, YYYY h:mm A",
										)}
									</h2>
								</div>
							</HoverCardContent>
						</HoverCard>
					</div>
				</div>
				<motion.div
					key={feedItemId}
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={{
						hidden: { opacity: 0 },
						visible: { opacity: 1 },
					}}
					transition={{ duration: 0.2 }}
					className="flex h-full min-h-0 w-full"
				>
					{showSidebar && editor ? <FeedItemSidebar editor={editor} /> : null}
					<div
						className="flex h-full min-h-0 w-full min-w-0 justify-center overflow-auto"
						id={"editor-container"}
					>
						<CustomEditor
							className="max-w-screen-md px-4 py-8"
							options={{
								editable: false,
								content,
							}}
							onEditorReady={setEditor}
						/>
					</div>
				</motion.div>
			</>
		);
	},
);

export const FeedItemViewer = observer(
	({ feedItemId }: { feedItemId: FeedItemId }) => {
		const appContext = useAppContext();

		if (!appContext.feedItemsById) {
			return null;
		}

		const feedItemMeta = appContext.feedItemsById.get(feedItemId);

		if (!feedItemMeta) {
			return (
				<div className="flex h-full w-full grow items-center justify-center bg-neutral-100">
					<h1 className="text-neutral-500">Article not found</h1>
				</div>
			);
		}

		return <_FeedItemViewer feedItemId={feedItemId} />;
	},
);
