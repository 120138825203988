import type { CellProps } from "@/components/table/cell/cell-props";
import {
	SelectOptionBackgroundColors,
	SelectOptionBorderColors,
	chooseRandomSelectOptionColor,
} from "@/components/table/cell/select-colors";
import { Badge } from "@/components/ui/badge";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { SelectOptionLabel } from "@api/schemas";
import { Check, Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const SelectCell = observer(
	({ recordId, field, value }: CellProps<"select">) => {
		const tableViewState = useTableViewContext();

		const [open, setOpen] = useState(false);
		const [query, setQuery] = useState("");

		// TODO(John): fix casts!
		const myOption = value ? field.properties.options[value as string] : null;

		return (
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger
					disabled={!tableViewState.editable}
					className={clsx(
						"flex h-full w-full min-w-0 items-start truncate p-1 text-left text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{myOption && (
						<Badge
							variant="outline"
							className={clsx(
								SelectOptionBackgroundColors[myOption.color],
								SelectOptionBorderColors[myOption.color],
								"min-w-0 truncate text-neutral-800",
							)}
						>
							{myOption.label}
						</Badge>
					)}
				</PopoverTrigger>
				<PopoverContent className="w-48 p-0" align="start">
					<Command>
						<CommandInput
							value={query}
							onValueChange={setQuery}
							placeholder="Search options..."
						/>
						<CommandList>
							<CommandEmpty>No options found.</CommandEmpty>
							<CommandGroup>
								{query && !field.properties.options[query] && (
									<>
										<CommandItem
											value={query}
											onSelect={() => {
												tableViewState.tableState.addSelectOption({
													fieldId: field.field_id,
													newOption: {
														label: query as SelectOptionLabel,
														color: chooseRandomSelectOptionColor(),
													},
													recordIdToSet: recordId,
												});
												setOpen(false);
											}}
										>
											<Plus className={clsx("mr-2 h-4 w-4")} />
											Add "{query}"
										</CommandItem>
										<CommandSeparator />
									</>
								)}

								{Object.values(field.properties.options).map((option) => (
									<CommandItem
										key={option.label}
										value={option.label}
										onSelect={(newValue) => {
											tableViewState.tableState.updateCellValues({
												recordId,
												fieldId: field.field_id,
												cellValue: newValue,
											});
											setOpen(false);
										}}
									>
										<Check
											className={clsx(
												"mr-2 h-4 w-4",
												myOption?.label === option.label
													? "opacity-100"
													: "opacity-0",
											)}
										/>
										<Badge
											className={clsx(
												SelectOptionBackgroundColors[option.color],
												SelectOptionBorderColors[option.color],
												"text-neutral-800",
											)}
										>
											{option.label}
										</Badge>
									</CommandItem>
								))}

								{myOption && (
									<>
										<CommandSeparator className="mt-1 mb-1" />
										<CommandItem
											value={`Remove "${myOption.label}"`}
											onSelect={() => {
												tableViewState.tableState.updateCellValues({
													recordId,
													fieldId: field.field_id,
													cellValue: null,
												});
												setOpen(false);
											}}
										>
											<X className={clsx("mr-2 h-4 w-4")} />
											Clear{" "}
											<Badge
												className={clsx(
													SelectOptionBackgroundColors[myOption.color],
													SelectOptionBorderColors[myOption.color],
													"ml-2 text-neutral-800",
												)}
											>
												{myOption.label}
											</Badge>
										</CommandItem>
									</>
								)}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		);
	},
);
