import { Command } from "@/components/ui/command";
import type { WebSearchResultLocal } from "@/contexts/tabs-context/tab-states/web-search-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { clsx } from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useRef } from "react";
import {} from "react-router-dom";
import { BarLoader } from "react-spinners";
import { WebSearchBody } from "./web-search-body";
import { WebSearchComboboxCommandList } from "./web-search-combobox-command-list";

interface WebSearchHeaderProps {
	webSearchResult: WebSearchResultLocal | null;
}

const WebSearchHeader = observer(
	({ webSearchResult }: WebSearchHeaderProps) => {
		const tab = useTab();
		const webSearchState = tab.state as TabTypeStateMap["web-search"];
		const inputRef = useRef<HTMLInputElement>(null);
		const dropdownRef = useRef<HTMLDivElement>(null);

		const { loadingState } = webSearchResult ?? {};
		const query = webSearchState.webSearchFormData.config.query;

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const handleClickOutside = useCallback((event: MouseEvent) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target as Node) &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				webSearchState.setShowCommandList(false);
			}
		}, []);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const handleEscapeKey = useCallback((event: KeyboardEvent) => {
			if (event.key === "Escape") {
				webSearchState.setShowCommandList(false);
				if (inputRef.current) {
					inputRef.current.blur();
				}
			}
		}, []);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			document.addEventListener("mousedown", handleClickOutside);
			document.addEventListener("keydown", handleEscapeKey);

			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
				document.removeEventListener("keydown", handleEscapeKey);
			};
		}, []);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (inputRef.current) {
				webSearchState.searchInputElement = inputRef.current;
			}
		}, [inputRef.current]);

		return (
			<div className="border-b bg-white">
				<div className="relative w-full min-w-0 px-4">
					<div className="flex w-full min-w-0 gap-1 py-4">
						<div className="relative w-full min-w-0">
							<CommandPrimitive.Input
								placeholder="Search"
								value={query}
								className={clsx(
									"flex w-full grow items-center rounded-lg border py-2 pr-1 pl-3 outline-none ring-1",
									webSearchState.showCommandList
										? "border-blue-300 ring-blue-100"
										: "ring-transparent",
								)}
								onValueChange={(value) => {
									runInAction(() => {
										webSearchState.webSearchFormData.config.query = value;
									});
									webSearchState.setShowCommandList(true);
								}}
								onFocus={() => webSearchState.setShowCommandList(true)}
								ref={inputRef}
							/>
							<div
								ref={dropdownRef}
								className={clsx(
									"absolute right-0 left-0 z-20 mt-2 max-h-[300px] overflow-y-auto rounded-lg border border-neutral-200 bg-white shadow-lg",
									webSearchState.showCommandList ? "block" : "hidden",
								)}
							>
								<WebSearchComboboxCommandList
									setShowCommandList={(show) => {
										webSearchState.setShowCommandList(show);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="h-[2px]">
					{loadingState === "searching" && (
						<BarLoader
							color={"black"}
							loading={true}
							height={2}
							width={"100%"}
						/>
					)}
				</div>
			</div>
		);
	},
);

export const WebSearchPage = observer(() => {
	const tab = useTab();
	const webSearchState = tab.state as TabTypeStateMap["web-search"];

	const webSearchResult = webSearchState.savedWebSearchConfig
		? webSearchState.appState.webSearchStore.getOrInitiateWebSearch(
				webSearchState.savedWebSearchConfig,
			)
		: null;

	return (
		<div className="flex h-full min-h-0 w-full min-w-0 flex-col">
			<Command
				className="rounded-none bg-white outline-none"
				shouldFilter={false}
			>
				<WebSearchHeader webSearchResult={webSearchResult} />
				<WebSearchBody webSearchResult={webSearchResult} />
			</Command>
		</div>
	);
});
