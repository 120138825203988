import { FieldType } from "@api/schemas";
import {
	CaretCircleDown,
	CheckSquare,
	Clock,
	Hash,
	type IconProps,
	Link,
	MagnifyingGlass,
	TextAa,
} from "@phosphor-icons/react";

export const FieldTypeIcons: {
	[key in FieldType]: (props: IconProps) => JSX.Element;
} = {
	[FieldType.text]: (props: IconProps) => <TextAa {...props} />,
	[FieldType.boolean]: (props: IconProps) => <CheckSquare {...props} />,
	[FieldType.select]: (props: IconProps) => <CaretCircleDown {...props} />,
	[FieldType.datetime]: (props: IconProps) => <Clock {...props} />,
	[FieldType.number]: (props: IconProps) => <Hash {...props} />,
	[FieldType.relationship]: (props: IconProps) => <Link {...props} />,
	[FieldType.lookup]: (props: IconProps) => <MagnifyingGlass {...props} />,
};

export const FieldTypeLabels: {
	[key in FieldType]: string;
} = {
	[FieldType.text]: "Text",
	[FieldType.boolean]: "Checkbox",
	[FieldType.select]: "Select",
	[FieldType.datetime]: "Time",
	[FieldType.number]: "Number",
	[FieldType.relationship]: "Relationship",
	[FieldType.lookup]: "Lookup",
};
