import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import {
	type CommandKPage,
	getLastCmdKPage,
	openCmdKPage,
	popCmdKPage,
	pushCmdKPage,
	setCmdKOpen,
} from "@/contexts/app-context/cmd-k";
import { AssistantSessionStore } from "@/contexts/app-context/db-store/assistant-session-store";
import {
	type AssistantEvent,
	type AssistantEventMap,
	EventStore,
	createEvent,
} from "@/contexts/app-context/db-store/event-store";
import {
	FeedChannelsStore,
	FeedItemsStore,
} from "@/contexts/app-context/db-store/feed-stores";
import { FoldersStore } from "@/contexts/app-context/db-store/folder-store";
import {
	MessageStore,
	createMessage,
} from "@/contexts/app-context/db-store/message-store";
import { StepStore } from "@/contexts/app-context/db-store/step-store";
import {
	FieldsStore,
	TablesStore,
} from "@/contexts/app-context/db-store/table-stores";
import { UploadsStore } from "@/contexts/app-context/db-store/upload-store";
import {
	feedChannelImmediateChildren,
	feedChannelNodes,
	feedTreeChildrenAccessor,
} from "@/contexts/app-context/feed-tree-handlers";
import {
	type PendingFeedItem,
	addFeedChannelAction,
	feedChannelsById,
	feedItemsById,
	searchFeedItemsByMetadata,
	sortedFeedChannels,
	sortedFeedItemsByChannel,
} from "@/contexts/app-context/feeds";
import { FileSelectorState } from "@/contexts/app-context/file-selector";
import {
	createFolderAction,
	deleteFilesAction,
	moveFilesAction,
	renameFileAction,
} from "@/contexts/app-context/files";
import { RightSidebarState } from "@/contexts/app-context/right-sidebar-state";
import { SidebarState } from "@/contexts/app-context/sidebar-state";
import { fileNodeTree } from "@/contexts/app-context/tree-handlers";
import {
	type PendingUpload,
	createUpload,
	downloadOriginalUploadFile,
	downloadUploadPdf,
	searchUploadsByMetadata,
	sortedIndexedUploads,
	updateUploadMetadataAction,
} from "@/contexts/app-context/uploads";
import { AppContext } from "@/contexts/app-context/use-app-context";
import {
	attemptReconnect,
	handleWorkspaceUpdate,
} from "@/contexts/app-context/workspace-updates";
import { createSyncedAction } from "@/contexts/synced-actions";
import { SearchStore } from "@/contexts/tabs-context/tab-states/search-state";
import { WebSearchStore } from "@/contexts/tabs-context/tab-states/web-search-state";
import { TabStore } from "@/contexts/tabs-context/tabs-context";
import { createNewSessionUserId } from "@/lib/id-generators";
import { bootstrapSession, syncFillTableActionRoute } from "@api/fastAPI";
import type {
	ActiveAssistantSessionStatus,
	AssistantStatus,
	Event,
	FeedItemId,
	FeedItemMetadata,
	File,
	FileId,
	Folder,
	FolderId,
	Message,
	MessageId,
	SentMessageEvent,
	SessionAssistantId,
	SessionId,
	SessionUser,
	SessionUserId,
	Step,
	TableId,
	TableMetadata,
	UploadId,
	UploadMetadata,
	UserId,
	WorkspaceUpdate,
} from "@api/schemas";
import type { User } from "@api/schemas/user";
import { useAuth, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { makeAutoObservable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { type ReactNode, useEffect, useState } from "react";
import { toast } from "sonner";

interface Workspace {
	userId: UserId;
	uploads: UploadsStore;
	folders: FoldersStore;
	feedChannels: FeedChannelsStore;
	feedItems: FeedItemsStore;
	tables: TablesStore;
	fields: FieldsStore;
	users: Map<UserId, User>;
}

// TODO(John): convert these to stores
interface Session {
	sessionsUser: Map<SessionUserId, SessionUser>;
}

export class AppState {
	userId: UserId;
	workspace: Workspace | null = null;

	tabStore: TabStore = new TabStore(this);
	searchStore: SearchStore = new SearchStore(this);
	webSearchStore: WebSearchStore = new WebSearchStore(this);
	sessionUserId: SessionUserId;
	session: Session | null = null;

	// database stores
	messageStore: MessageStore = new MessageStore(this);
	assistantSessionStore: AssistantSessionStore = new AssistantSessionStore(
		this,
	);
	stepStore: StepStore = new StepStore(this);
	eventStore: EventStore = new EventStore(this);

	// UI State
	sidebarState: SidebarState = new SidebarState(this);
	rightSidebarState: RightSidebarState = new RightSidebarState(this);
	fileSelectorState: FileSelectorState = new FileSelectorState(this);

	pdfScale = 1;

	// navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	// Command K
	cmdKOpen = false;
	cmdKPages: CommandKPage[] = [];
	showAddFeedDialog = false;
	showCreateComputedTableDialog = false;

	// WebSocket for workspace-level updates
	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;

	// Files currently being uploaded by the current user, not persisted across sessions
	recentUploads: Map<UploadId, PendingUpload> = new Map();
	get recentUploadsArray() {
		return Array.from(this.recentUploads.values()).sort((a, b) =>
			a.uploadedAt.isBefore(b.uploadedAt) ? -1 : 1,
		);
	}
	// Feed items in a channel just added by the current user, not persisted across sessions
	recentFeedItems: Map<FeedItemId, PendingFeedItem> = new Map();
	get recentFeedItemsArray() {
		return Array.from(this.recentFeedItems.values()).sort((a, b) =>
			a.feedItemPubDate.isBefore(b.feedItemPubDate) ? -1 : 1,
		);
	}

	constructor({
		userId,
		getToken,
	}: {
		userId: UserId;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		this.userId = userId;
		this.getToken = getToken;
		makeAutoObservable(this);
		this.sessionUserId = this.#initializeSessionUserId(userId);
	}

	#initializeSessionUserId(userId: UserId): SessionUserId {
		const STORAGE_KEY = "userIdSessionUserIdRecord";
		let record: Record<UserId, SessionUserId> = {};

		const recordString = localStorage.getItem(STORAGE_KEY);
		if (recordString) {
			try {
				record = JSON.parse(recordString) as Record<UserId, SessionUserId>;
			} catch (error) {
				console.warn("Failed to parse userIdSessionUserIdRecord:", error);
				localStorage.removeItem(STORAGE_KEY);
			}
		}

		if (userId in record) {
			return record[userId];
		}

		const newSessionUserId = createNewSessionUserId();
		record[userId] = newSessionUserId;
		try {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(record));
		} catch (error) {
			console.error("Failed to set userIdSessionUserIdRecord:", error);
		}

		return newSessionUserId;
	}

	async init({
		isReconnect,
	}: {
		isReconnect: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in AppContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		bootstrapSession()
			.then((res) => {
				runInAction(() => {
					this.workspace = {
						uploads: new UploadsStore(this, res.data.uploads),
						folders: new FoldersStore(this, res.data.folders),
						feedChannels: new FeedChannelsStore(this, res.data.feed_channels),
						feedItems: new FeedItemsStore(this, res.data.feed_items),
						tables: new TablesStore(this, res.data.tables),
						fields: new FieldsStore(this, res.data.fields),
						users: new Map(
							res.data.users.map((user) => [user.user_id as UserId, user]),
						),
						userId: this.userId as UserId,
					};
					this.session = {
						sessionsUser: new Map(
							res.data.session_users.map((user) => [
								user.session_user_id,
								user,
							]),
						),
					};
					this.assistantSessionStore.assistantSessions = new Map(
						res.data.session_assistants.map((assistant) => [
							assistant.session_assistant_id,
							assistant,
						]),
					);
					this.assistantSessionStore.activeAssistantSessionStatuses = new Map(
						res.data.assistant_session_statuses.map((status) => [
							status.assistant_session_id,
							status.status,
						]),
					);

					this.messageStore.messages = new Map(
						res.data.messages.map((message) => [message.message_id, message]),
					);
					this.eventStore.events = new Map(
						res.data.events.map((event) => [event.event_id, event]),
					);
					this.stepStore.steps = new Map(
						res.data.steps.map((step) => [step.step_id, step]),
					);
					// Navigate to the last open thread
					this.rightSidebarState.navigateMessages(
						this.messageStore.threads[0]?.message_id ?? null,
					);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to initialize session. Please refresh the page.");
				}
			});

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/sessions/ws?token=${token}&session_user_id=${this.sessionUserId}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to workspace.");
				}
			});
		};

		ws.onmessage = (event) => {
			queueMicrotask(async () => {
				try {
					const data:
						| WorkspaceUpdate
						| Event
						| Step
						| ActiveAssistantSessionStatus = JSON.parse(event.data);
					if ("event_id" in data) {
						this.eventStore.handleEventLocally(data);
					} else if ("step_id" in data) {
						this.stepStore.createStepLocally(data);
					} else if ("assistant_session_id" in data) {
						this.assistantSessionStore.activeAssistantSessionStatuses.set(
							data.assistant_session_id as SessionAssistantId,
							data.status as AssistantStatus,
						);
					} else {
						this.#handleWorkspaceUpdate(data);
					}
				} catch (e) {
					console.error("Error parsing websocket response JSON:", e);
					return;
				}
			});
		};

		ws.onclose = (e) => {
			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}
			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this.#attemptReconnect();
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect();
		};
	}

	/*
	Workspace updates channel methods
	*/
	#attemptReconnect = attemptReconnect.bind(this);
	#handleWorkspaceUpdate = handleWorkspaceUpdate.bind(this);
	/*
	Command K-related methods
	*/
	popCmdKPage = popCmdKPage.bind(this);
	pushCmdKPage = pushCmdKPage.bind(this);
	setCmdKOpen = setCmdKOpen.bind(this);
	openCmdKPage = openCmdKPage.bind(this);
	get lastCmdKPage() {
		return getLastCmdKPage.bind(this)();
	}

	/*
	Library-related methods
	*/
	get workspaceHasLoaded() {
		return this.workspace !== null;
	}
	get sortedIndexedUploads() {
		return sortedIndexedUploads.bind(this)();
	}
	getUploadById(uploadId: UploadId) {
		return this.workspace?.uploads.items.get(uploadId) ?? null;
	}
	getFolderById(folderId: FolderId) {
		return this.workspace?.folders.items.get(folderId) ?? null;
	}
	updateUploadMetadata = updateUploadMetadataAction.bind(this);
	createFolder = createFolderAction.bind(this);
	createUpload = createUpload.bind(this);
	downloadUploadPdf = downloadUploadPdf.bind(this);
	downloadOriginalUploadFile = downloadOriginalUploadFile.bind(this);
	searchUploadsByMetadata = searchUploadsByMetadata.bind(this);
	renameFile = renameFileAction.bind(this);
	deleteFiles = deleteFilesAction.bind(this);
	moveFiles = moveFilesAction.bind(this);

	/*
	Library tree-related methods
	*/
	get fileNodeTree() {
		return fileNodeTree.bind(this)();
	}

	/*
	Feed-related methods
	*/
	addFeedChannel = addFeedChannelAction.bind(this);
	get feedItemsById() {
		return feedItemsById.bind(this)();
	}
	getFeedItemById(feedItemId: FeedItemId) {
		return this.workspace?.feedItems.items.get(feedItemId) ?? null;
	}
	get feedChannelsById() {
		return feedChannelsById.bind(this)();
	}
	get sortedFeedChannels() {
		return sortedFeedChannels.bind(this)();
	}
	get sortedFeedItemsByChannel() {
		return sortedFeedItemsByChannel.bind(this)();
	}
	searchFeedItemsByMetadata = searchFeedItemsByMetadata.bind(this);
	feedTreeChildrenAccessor = feedTreeChildrenAccessor.bind(this);
	get feedChannelNodes() {
		return feedChannelNodes.bind(this)();
	}
	get feedChannelImmediateChildren() {
		return feedChannelImmediateChildren.bind(this)();
	}

	/*
	User-related methods
	*/
	getUserById(userId: UserId) {
		return this.workspace?.users.get(userId) ?? null;
	}

	get tablesAsArray() {
		const allTables = this.workspace
			? this.workspace.tables.items.values
			: null;
		if (!allTables) {
			return null;
		}
		return allTables.filter((table) => !table.file_deleted_at);
	}
	getTableById(tableId: TableId) {
		return this.workspace?.tables.items.get(tableId) ?? null;
	}

	/*
	Page-related methods
	*/
	// I could just get pagesAsArray over this.workspace.tables
	// get pageById would just be over this.workspace.tables as well
	// Does every page have a tableState?
	// What methods does the server need?
	// Create page
	// - Can use createTable but with different metadata?
	// Assistant needs to be able to read pages
	// Assistant needs to be able to create pages
	// Convert page to table?
	// Create table from page while keeping page?
	// createPage = createPageAction.bind(this);

	getSessionById(sessionId: SessionId) {
		if (!this.session) {
			return null;
		}
		const maybeSessionAssistant =
			this.assistantSessionStore.getAssistantSessionById(
				sessionId as SessionAssistantId,
			);
		if (maybeSessionAssistant) {
			return maybeSessionAssistant;
		}
		const maybeSessionUser = this.session.sessionsUser.get(
			sessionId as SessionUserId,
		);
		if (maybeSessionUser) {
			return maybeSessionUser;
		}
		return null;
	}

	createSessionUserLocally = (sessionUser: SessionUser) => {
		this.session?.sessionsUser.set(sessionUser.session_user_id, sessionUser);
	};

	fillTable = createSyncedAction<
		AppState,
		{
			tableId: TableId;
			tableName: string;
		},
		SentMessageEvent,
		void
	>({
		async local({ tableId, tableName }) {
			const event = createEvent({
				eventType: "sent_message",
				userId: this.userId,
				data: {
					session_id: this.sessionUserId,
					message: createMessage({
						content: `<p>Fill out or update the existing cells in <a href='/table/${tableId}'>${tableName}</a> based on the column descriptions.</p>`,
						sessionId: this.sessionUserId,
						parentMessageId: null,
					}),
				},
			});
			this.eventStore.handleEventLocally(event);
			return event;
		},
		async remote(_, localResult) {
			await syncFillTableActionRoute({
				message_event: localResult,
			});
		},
		rollback(_args, localResult) {
			this.eventStore.events.delete(localResult.event_id);
			this.messageStore.messages.delete(localResult.data.message.message_id);
		},
		onRemoteSuccess() {},
	});

	sendMessage(props: {
		content: string;
		parentMessageId: Message["parent_message_id"];
	}) {
		const newMessage = createMessage({
			content: props.content,
			sessionId: this.sessionUserId,
			parentMessageId: props.parentMessageId,
		});
		return this.eventStore.handleEvent(
			{
				eventType: "sent_message",
				data: {
					session_id: this.sessionUserId,
					message: newMessage,
				},
			},
			() => {
				if (this.rightSidebarState.currentActiveMessageId === null) {
					this.rightSidebarState.navigateMessages(newMessage.message_id);
				}
			},
		);
	}

	// Queries
	getSortedAssistantActivityForSession = computedFn(
		(assistantSessionId: SessionAssistantId): (AssistantEvent | Step)[] => {
			const events =
				this.eventStore.getEventsForAssistantSessionId(assistantSessionId);
			const steps =
				this.stepStore.getStepsForAssistantSessionId(assistantSessionId);

			return [...events, ...steps].sort(
				(a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
			);
		},
	);

	// This is an arrow function because we pass it to ObjectLink.configure,
	// which calls it with a different this context.
	searchUploadsAndTables = (
		query: string,
	): {
		id: string;
		label: string;
	}[] => {
		const tableMetadatas = (this.tablesAsArray ?? []).filter((table) =>
			table.file_name.toLowerCase().includes(query.toLowerCase()),
		);
		const uploadResults = this.searchUploadsByMetadata(query);
		const uploadMetadatas = Array.from(uploadResults)
			.map((uploadId) => this.getUploadById(uploadId as UploadId))
			.filter((upload): upload is UploadMetadata => upload !== null);
		return [
			...tableMetadatas.map((table) => ({
				id: `/table/${table.table_id}`,
				label: table.file_name,
			})),
			...uploadMetadatas.map((upload) => ({
				id: `/upload/${upload.upload_id}`,
				label: upload.file_name,
			})),
		];
	};

	get files() {
		const filesMap = new Map<FileId, File>();

		if (!this.workspace) {
			return filesMap;
		}

		for (const table of this.workspace.tables.items.values) {
			if (!table.file_deleted_at) {
				filesMap.set(table.table_id, table);
			}
		}

		for (const upload of this.workspace.uploads.items.values) {
			if (!upload.file_deleted_at) {
				filesMap.set(upload.upload_id, upload);
			}
		}

		for (const folder of this.workspace.folders.items.values) {
			if (!folder.file_deleted_at) {
				filesMap.set(folder.folder_id, folder);
			}
		}
		for (const channel of this.workspace.feedChannels.items.values) {
			if (!channel.file_deleted_at) {
				filesMap.set(channel.feed_channel_id, channel);
			}
		}

		for (const item of this.workspace.feedItems.items.values) {
			if (!item.file_deleted_at) {
				filesMap.set(item.feed_item_id, item);
			}
		}

		return filesMap;
	}

	searchFiles(query: string): {
		uploads: UploadMetadata[];
		feedItems: FeedItemMetadata[];
		tables: TableMetadata[];
		folders: Folder[];
	} {
		if (!this.workspace) {
			return {
				uploads: [],
				feedItems: [],
				tables: [],
				folders: [],
			};
		}
		const feedItemIds = this.workspace.feedItems.items.search(query);
		const uploadIds = this.workspace.uploads.items.search(query);
		const tableIds = this.workspace.tables.items.search(query);
		const folderIds = this.workspace.folders.items.search(query);

		const uploads = uploadIds
			.map((uploadId) => this.getUploadById(uploadId as UploadId))
			.filter(
				(upload): upload is UploadMetadata =>
					upload !== null && !upload.file_deleted_at,
			);
		const feedItems = feedItemIds
			.map((feedItemId) => this.getFeedItemById(feedItemId as FeedItemId))
			.filter(
				(feedItem): feedItem is FeedItemMetadata =>
					feedItem !== null && !feedItem.file_deleted_at,
			);
		const tables = tableIds
			.map((tableId) => this.getTableById(tableId as TableId))
			.filter(
				(table): table is TableMetadata =>
					table !== null && !table.file_deleted_at,
			);
		const folders = folderIds
			.map((folderId) => this.getFolderById(folderId as FolderId))
			.filter(
				(folder): folder is Folder =>
					folder !== null && !folder.file_deleted_at,
			);

		return {
			uploads,
			feedItems,
			tables,
			folders,
		};
	}

	// TODO(John): move to right place
	activeAssistantSessionsViewingThread(
		threadId: MessageId,
	): SessionAssistantId[] {
		if (!this.session) {
			return [];
		}
		const activeAssistantSessionsViewingThread: SessionAssistantId[] = [];
		for (const assistantSession of this.assistantSessionStore.assistantSessions.values()) {
			if (assistantSession.ended_at !== null) {
				continue;
			}
			const assistantSessionEvents =
				this.eventStore.getEventsForAssistantSessionId(
					assistantSession.session_assistant_id,
				);
			if (!assistantSessionEvents) {
				continue;
			}
			const latestThreadOpenEvent = assistantSessionEvents
				.slice()
				.reverse()
				.find(
					(event): event is AssistantEventMap["opened_thread"] =>
						"event_id" in event &&
						event.type === "opened_thread" &&
						event.data.thread_id === threadId,
				);
			if (latestThreadOpenEvent?.data.thread_id === threadId) {
				activeAssistantSessionsViewingThread.push(
					assistantSession.session_assistant_id,
				);
			}
		}
		return activeAssistantSessionsViewingThread;
	}
}

type AppProviderProps = {
	userId: UserId;
	children: ReactNode;
};

let didInit = false;

export const AppProvider = ({ userId, children }: AppProviderProps) => {
	const { getToken } = useAuth();
	const { user } = useUser();
	const [appState] = useState<AppState>(
		() =>
			new AppState({
				getToken,
				userId,
			}),
	);
	const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

	useEffect(() => {
		runInAction(() => {
			appState.sidebarState.setShowSidebar(!isSmallDevice);
		});
	}, [appState, isSmallDevice]);

	useEffect(() => {
		if (!user) {
			return;
		}

		if (!didInit) {
			didInit = true;
			appState.init({ isReconnect: false });
		}
	}, [appState, user]);

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
