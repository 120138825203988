import { Favicon } from "@/components/favicon";
import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Skeleton } from "@/components/ui/skeleton";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { useTabStore } from "@/contexts/tabs-context/use-tab-store";
import { cleanUrlForDisplay, cleanXmlText } from "@/lib/formatting";
import { FeedItemViewer } from "@/pages/feed/feed-item-viewer";
import { refetchFeedChannel } from "@api/fastAPI";
import type { FeedChannelId, FeedItemId } from "@api/schemas";
import {
	ArrowUpRight,
	ArrowsClockwise,
	DotOutline,
} from "@phosphor-icons/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";

const FeedItems = observer(
	({ feedChannelId }: { feedChannelId: FeedChannelId }) => {
		const appContext = useAppContext();
		const feedItems = appContext.sortedFeedItemsByChannel?.get(feedChannelId);
		const [searchQuery, setSearchQuery] = useState("");
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedFeedItemIds = useMemo(() => {
			return appContext.searchFeedItemsByMetadata(searchQuery);
		}, [searchQuery]);
		const tabsContext = useTabStore();
		const navigate = tabsContext.navigate;

		let renderedItems: React.ReactNode;

		if (feedItems === undefined) {
			renderedItems = (
				<div className="h-full w-full min-w-0 overflow-y-scroll">
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
				</div>
			);
		} else {
			renderedItems = (
				<div className="max-h-max min-h-0 w-full min-w-0 overflow-y-scroll">
					{feedItems
						.filter((x) => {
							if (searchQuery.trim() === "") {
								return true;
							}
							return matchedFeedItemIds.has(x.feed_item_id);
						})
						.map((item) => (
							<button
								type="button"
								key={item.feed_item_id}
								className={clsx(
									"flex w-full items-center gap-2 border-l-4 p-2",
									"border-transparent hover:bg-blue-50",
								)}
								onClick={() => {
									navigate({
										path: "feed-item",
										file_id: item.feed_item_id,
									});
								}}
							>
								{item.feed_item_og_image ? (
									<img
										src={item.feed_item_og_image}
										alt={item.file_name}
										className="flex h-16 w-24 shrink-0 items-center justify-center rounded object-cover text-xs"
									/>
								) : (
									<div className="flex h-16 w-24 shrink-0 items-center justify-center rounded bg-neutral-300 text-neutral-500 text-xs" />
								)}
								<div className="grpw flex w-full min-w-0 flex-col items-start">
									<h2 className="flex w-full min-w-0 items-center gap-2 truncate text-left font-semibold text-neutral-800 text-sm">
										<span className="min-w-0 truncate">
											{cleanXmlText(item.file_name)}
										</span>
									</h2>
									<h3 className="w-full min-w-0 truncate text-left text-neutral-600 text-sm">
										{cleanXmlText(item.feed_item_description ?? "")}
									</h3>
									<span className="flex w-full min-w-0 items-center gap-0.5 truncate text-left text-neutral-500 text-sm">
										<span>{cleanXmlText(item.feed_item_author ?? "")}</span>{" "}
										<DotOutline className="shrink-0" weight="fill" />
										<span>
											{dayjs(
												item.feed_item_pub_date ?? item.file_created_at,
											).format("MMM D, YYYY h:mm A")}
										</span>
										{item.feed_item_wordcount && (
											<>
												<DotOutline weight="fill" className="shrink-0" />
												<span>
													{Intl.NumberFormat().format(item.feed_item_wordcount)}{" "}
													words
												</span>
											</>
										)}
									</span>
								</div>
							</button>
						))}
				</div>
			);
		}
		return (
			<>
				<div className="border-b bg-neutral-50 p-3">
					<Input
						placeholder={
							feedItems
								? `Search ${feedItems?.length ?? 0} articles...`
								: "Search articles..."
						}
						className="w-full bg-white p-2"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</div>
				{renderedItems}
			</>
		);
	},
);

const FeedChannelHeader = observer(
	({ feedChannelId }: { feedChannelId: FeedChannelId }) => {
		const appContext = useAppContext();

		const feedChannel = appContext.feedChannelsById?.get(feedChannelId);
		const [refreshing, setRefreshing] = useState(false);

		return (
			<div
				className={clsx(
					"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-3 backdrop-blur",
				)}
			>
				{feedChannel ? (
					<>
						<HoverCard>
							<HoverCardTrigger className="flex items-center gap-2 truncate text-sm">
								{feedChannel.feed_channel_link ? (
									<Favicon
										url={feedChannel.feed_channel_link}
										alt={feedChannel.file_name}
										className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.file_name}
									</h1>
									{feedChannel.feed_channel_link && (
										<h2>
											<a
												href={feedChannel.feed_channel_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center gap-1 truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_channel_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
								</div>
							</HoverCardTrigger>
							<HoverCardContent
								align="start"
								className="flex w-96 gap-3 text-sm"
							>
								{feedChannel.feed_channel_link ? (
									<Favicon
										url={feedChannel.feed_channel_link}
										alt={feedChannel.file_name}
										className="h-16 w-16 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-16 w-16 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.file_name}
									</h1>
									{feedChannel.feed_channel_subtitle && (
										<h1 className="min-w-0 truncate font-semibold text-neutral-700">
											{feedChannel.feed_channel_subtitle}
										</h1>
									)}
									{feedChannel.feed_channel_link && (
										<h2>
											<a
												href={feedChannel.feed_channel_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_channel_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
									{feedChannel.feed_channel_description && (
										<p className="mt-2 min-w-0 text-neutral-500">
											{feedChannel.feed_channel_description}
										</p>
									)}
								</div>
							</HoverCardContent>
						</HoverCard>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									className={clsx("text-lg text-neutral-700")}
									disabled={refreshing}
									onClick={() => {
										setRefreshing(true);
										refetchFeedChannel({
											feed_channel_id: feedChannelId,
										}).finally(() => {
											setRefreshing(false);
										});
									}}
								>
									<ArrowsClockwise
										weight="bold"
										className={clsx(refreshing && "animate-spin")}
									/>
								</Button>
							</TooltipTrigger>

							<TooltipContent>Refresh feed</TooltipContent>
						</Tooltip>
					</>
				) : (
					<>
						<Skeleton className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-6 w-48 shrink-0 rounded-md bg-neutral-200" />
					</>
				)}
			</div>
		);
	},
);

export const FeedChannelPage = observer(() => {
	const appContext = useAppContext();
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
	const tab = useTab();
	const tabState = tab.state as TabTypeStateMap["feed-channel" | "feed-item"];

	let feedChannelId: FeedChannelId;
	let feedItemId: FeedItemId | undefined;
	if (tabState.type === "feed-channel") {
		feedChannelId = tabState.feedChannelNode.file.file_id;
	} else if (tabState.type === "feed-item") {
		feedChannelId = tabState.feedItemNode.file.feed_channel_id;
		feedItemId = tabState.feedItemNode.file.file_id;

		const feedChannel = appContext.feedChannelsById?.get(feedChannelId);

		if (!feedChannel) {
			throw new Error("Feed channel not found for feed item");
		}
		if (feedChannel.file_type !== "feed-channel") {
			throw new Error("Feed item parent is not a feed channel");
		}
	} else {
		throw new Error(`Invalid tab state: ${tabState}`);
	}

	const Layout = isSmallDevice ? (
		<div className="max-h-full min-h-0 w-full min-w-0 grow">
			<FeedChannelHeader feedChannelId={feedChannelId} />
			<FeedItems feedChannelId={feedChannelId} />
			<Drawer
				open={!!feedItemId}
				onOpenChange={(open) => {
					if (!open) {
						//
					}
				}}
			>
				<DrawerContent className="h-[90%]">
					<div
						className={
							"relative mt-4 flex h-full min-h-0 flex-col items-center"
						}
					>
						{feedItemId ? (
							<FeedItemViewer feedItemId={feedItemId} />
						) : (
							<div className="flex h-full items-center justify-center text-neutral-300">
								Open an article to view it here
							</div>
						)}
					</div>
				</DrawerContent>
			</Drawer>
		</div>
	) : (
		// Important: the min-w-0 and relative classes are necessary to make the
		// resizable panels work correctly with the toggleable sidebar
		<ResizablePanelGroup
			direction="horizontal"
			className="h-full max-h-full min-h-0 grow"
		>
			<ResizablePanel minSize={25} order={1} className="flex flex-col">
				<FeedChannelHeader feedChannelId={feedChannelId} />

				<FeedItems feedChannelId={feedChannelId} />
			</ResizablePanel>
			<ResizableHandle />
			<ResizablePanel minSize={25} order={2}>
				<div className="relative flex h-full max-h-full min-h-0 w-full min-w-0 flex-col items-center">
					{feedItemId ? (
						<FeedItemViewer feedItemId={feedItemId} />
					) : (
						<div className="flex h-full w-full grow items-center justify-center bg-neutral-50 text-neutral-300">
							Open an article to view it here
						</div>
					)}
				</div>
			</ResizablePanel>
		</ResizablePanelGroup>
	);

	return (
		<div className="flex h-full max-h-full min-h-0 grow flex-col">{Layout}</div>
	);
});
