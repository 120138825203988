import type { AppState } from "@/contexts/app-context/app-context";
import {
	DisplayedActionError,
	createSyncedAction,
} from "@/contexts/synced-actions";
import { createUploadId } from "@/lib/id-generators";
import {
	createUploadRoute,
	downloadOriginal,
	downloadPdf,
	updateUploadMetadataRoute,
} from "@api/fastAPI";
import {
	type FileId,
	type UploadId,
	type UploadMetadata,
	UploadMimetype,
} from "@api/schemas";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { toast } from "sonner";
import xxhash from "xxhash-wasm";

export enum UploadStatus {
	pending = "pending",
	success = "success",
	failed = "failed",
}

// An upload that the user has recently uploaded, but is still being processed.
export type PendingUpload = {
	uploadId: UploadId;
	uploadedAt: Dayjs;
	filename: string;
	status: UploadStatus;
};

export const updateUploadMetadataAction = createSyncedAction<
	AppState,
	{ uploadId: UploadId; newMetadata: Partial<UploadMetadata> },
	{ updatedUpload: UploadMetadata; originalUpload: UploadMetadata },
	void
>({
	async local({ uploadId, newMetadata }) {
		if (!this.workspace) {
			throw new Error("Workspace not loaded yet!");
		}

		const originalUpload = this.workspace.uploads.items.get(uploadId);
		if (!originalUpload) {
			throw new Error(`Upload with id ${uploadId} not found`);
		}

		const updatedUpload = { ...originalUpload, ...newMetadata };
		this.workspace.uploads.items.set(uploadId, updatedUpload);

		return { updatedUpload: updatedUpload, originalUpload: originalUpload };
	},
	async remote({ uploadId, newMetadata }) {
		await updateUploadMetadataRoute({
			upload_id: uploadId,
			new_upload_title: newMetadata.file_name ?? null,
			new_upload_subtitle: newMetadata.upload_subtitle ?? null,
			new_upload_authors: newMetadata.upload_authors ?? null,
			new_upload_publisher: newMetadata.upload_publisher ?? null,
			new_upload_year_published: newMetadata.upload_year_published ?? null,
			new_upload_type: newMetadata.upload_type ?? null,
		});
	},
	rollback({ uploadId }, { originalUpload }) {
		if (!this.workspace) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.uploads.items.set(uploadId, originalUpload);
	},
	onRemoteSuccess() {
		toast.success("Upload metadata updated successfully.");
	},
});

export const createUpload = createSyncedAction<
	AppState,
	{
		file: File;
		fileParentId: FileId | null;
		inferMetadata: boolean;
	},
	UploadMetadata,
	void
>({
	async local({ fileParentId: parentFileId, file }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const hasher = (await xxhash()).h64Raw;
		const hash = hasher(new Uint8Array(await file.arrayBuffer()), 42n).toString(
			16,
		);

		let upload_filetype: UploadMimetype | null = null;

		if (file.type === "application/pdf") {
			upload_filetype = "pdf";
		} else if (file.type === "application/epub+zip") {
			upload_filetype = "epub";
		} else {
			throw new DisplayedActionError(`Unsupported file type for ${file.name}`);
		}

		const uploadId = createUploadId();
		const newUpload: UploadMetadata = {
			file_id: uploadId,
			upload_id: uploadId,
			upload_filetype,
			file_parent_id: parentFileId,
			upload_authors: null,
			upload_publisher: null,
			upload_title: file.name,
			upload_subtitle: null,
			upload_type: null,
			upload_year_published: null,
			upload_hash: hash,
			file_name: file.name,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted_at: null,
			file_creator_id: this.workspace.userId,
			file_type: "upload",
		};

		this.recentUploads.set(uploadId, {
			uploadId,
			uploadedAt: dayjs(),
			filename: file.name,
			status: UploadStatus.pending,
		});
		return newUpload;
	},
	async remote({ fileParentId, file, inferMetadata }, newUpload) {
		await createUploadRoute({
			file,
			upload_id: newUpload.upload_id,
			infer_metadata: inferMetadata,
			file_parent_id: fileParentId,
		});
	},
	rollback(_, localResult) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.uploads.items.delete(localResult.upload_id);
	},
});

export function downloadUploadPdf(this: AppState, uploadId: UploadId) {
	toast.promise(
		async () => {
			const upload = this.getUploadById(uploadId);

			if (!upload) {
				throw new Error("Upload not found");
			}

			const resp = await downloadPdf(uploadId, {
				responseType: "blob",
			});

			const url = URL.createObjectURL(resp.data as Blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${upload.upload_title ?? upload.file_name}.pdf`;
			a.click();
		},
		{
			loading: "Exporting PDF...",
			success: "PDF exported!",
			error: "Failed to export PDF",
		},
	);
}

export function downloadOriginalUploadFile(this: AppState, uploadId: UploadId) {
	toast.promise(
		async () => {
			const upload = this.getUploadById(uploadId);

			if (!upload) {
				throw new Error("Upload not found");
			}

			let extension = null;
			switch (upload.upload_filetype) {
				case UploadMimetype.pdf:
					extension = "pdf";
					break;
				case UploadMimetype.epub:
					extension = "epub";
					break;
			}

			if (extension === null) {
				throw new Error("Unsupported file type");
			}

			const resp = await downloadOriginal(uploadId, {
				responseType: "blob",
			});

			const url = URL.createObjectURL(resp.data as Blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = upload.file_name;
			a.click();
		},
		{
			loading: "Retrieving original...",
			success: "Original file retrieved!",
			error: "Failed to retrieve original file",
		},
	);
}

export function sortedIndexedUploads(this: AppState): UploadMetadata[] | null {
	if (!this.workspace) {
		return null;
	}
	return Array.from(this.workspace.uploads.items.values)
		.sort((a, b) => {
			const aName = a.upload_title ?? a.file_name;
			const bName = b.upload_title ?? b.file_name;
			return aName.localeCompare(bName);
		})
		.filter((x) => !x.file_deleted_at);
}

export function searchUploadsByMetadata(this: AppState, query: string) {
	if (!this.workspace) {
		return new Set<UploadId>();
	}
	return new Set(this.workspace.uploads.items.search(query));
}
