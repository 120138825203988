/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type TextSearchMode = typeof TextSearchMode[keyof typeof TextSearchMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextSearchMode = {
  keyword: 'keyword',
  semantic: 'semantic',
  hybrid: 'hybrid',
} as const;
