import { CustomEditor, ViewOnlyEditor } from "@/components/editor";
import type { CellProps } from "@/components/table/cell/cell-props";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const TextPopupEditor = observer(
	({
		recordId,
		field,
		value,
		setOpen,
	}: CellProps<"text"> & {
		setOpen: (open: boolean) => void;
	}) => {
		const tableViewState = useTableViewContext();
		const updateFieldValue = (content: string) => {
			if (content !== value) {
				tableViewState.tableState.updateCellValues({
					recordId,
					fieldId: field.field_id,
					cellValue: content,
				});
			}
		};

		return (
			<CustomEditor
				options={{
					// TODO(John): fix casts!
					content: value as string,
					editable: true,
					autofocus: true,
					onBlur: ({ editor }) => {
						updateFieldValue(editor.getHTML());
					},
					editorProps: {
						handleDOMEvents: {
							keydown: (_view, event: KeyboardEvent) => {
								if (event.key === "Enter" && !event.shiftKey) {
									event.preventDefault();
									setOpen(false);
									return true;
								}
								return false;
							},
						},
					},
				}}
			/>
		);
	},
);

export const TextCellComponent = observer(
	({ recordId, field, value }: CellProps<"text">) => {
		const tableViewState = useTableViewContext();
		const currentValue = value ?? "";
		const [open, setOpen] = useState(false);

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
				}}
			>
				<PopoverTrigger
					disabled={!tableViewState.editable}
					className={clsx(
						"flex h-full w-full min-w-0 flex-col items-start justify-start truncate p-1 text-left text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{/* TODO(John): fix casts! */}
					<ViewOnlyEditor content={currentValue as string} />
				</PopoverTrigger>
				<PopoverContent align="start" className="overflow-hidden p-0">
					<TextPopupEditor
						recordId={recordId}
						field={field}
						value={value}
						setOpen={setOpen}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
