import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { UploadStatus } from "@/contexts/app-context/uploads";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { PageResolution, type UploadId } from "@api/schemas";
import { CaretRight, Spinner, X } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "motion/react";
import { useState } from "react";

const UploadStatusIcon = ({
	status,
	uploadId,
}: { status: UploadStatus; uploadId: UploadId }) => {
	switch (status) {
		case UploadStatus.pending:
			return <Spinner className="animate shrink-0 animate-spin" />;
		case UploadStatus.success:
			return (
				<UploadCoverImage
					upload_id={uploadId}
					resolution={PageResolution.thumbnail}
					className="h-4 max-w-6 shrink-0 shadow"
				/>
			);
		case UploadStatus.failed:
			return <X className="shrink-0" />;
	}
};

/**
 * Drawer that shows pending uploads.
 */
export const RecentUploadsDrawer = observer(() => {
	const appContext = useAppContext();
	const [isOpen, setIsOpen] = useState(false);

	if (appContext.recentUploadsArray.length === 0) {
		return null;
	}

	const numPending = appContext.recentUploadsArray.filter(
		(upload) => upload.status === UploadStatus.pending,
	).length;

	const allDone = numPending === 0;

	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			transition={{ duration: 0.3 }}
			className="fixed right-4 bottom-4 z-50 w-[32rem] overflow-hidden border shadow"
		>
			<Collapsible open={isOpen} onOpenChange={setIsOpen}>
				<CollapsibleTrigger className="group flex w-full items-center gap-2 bg-white px-4 py-3">
					<motion.button
						type="button"
						animate={{ rotate: isOpen ? 90 : 0 }}
						transition={{ duration: 0.2 }}
					>
						<CaretRight
							className="text-lg text-neutral-500 group-hover:text-neutral-800"
							weight="bold"
						/>
					</motion.button>
					<span className="grow text-left text-neutral-600 text-sm">
						{allDone ? (
							<>All uploads processed.</>
						) : (
							<>
								Processing {numPending} upload{numPending > 1 ? "s" : ""}
							</>
						)}
					</span>
					{allDone && (
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									appContext.recentUploads = new Map();
								});
							}}
							className="p-1 text-lg"
						>
							<X />
						</button>
					)}
				</CollapsibleTrigger>
				<AnimatePresence>
					{isOpen && (
						<CollapsibleContent forceMount asChild>
							<motion.div
								initial={{ opacity: 0, height: 0 }}
								animate={{ opacity: 1, height: "auto" }}
								exit={{ opacity: 0, height: 0 }}
								transition={{ duration: 0.15 }}
							>
								<div className="flex max-h-[50vh] flex-col gap-0.5 overflow-y-scroll border-t bg-neutral-100 px-4 py-2">
									{appContext.recentUploadsArray.map((upload) => (
										<motion.div
											key={upload.uploadId}
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: -20 }}
											transition={{ duration: 0.2 }}
											className="flex items-center gap-2 px-0.5 py-1 text-sm"
										>
											<UploadStatusIcon
												status={upload.status}
												uploadId={upload.uploadId}
											/>
											<span className="grow truncate text-left text-neutral-600">
												{upload.filename}
											</span>
										</motion.div>
									))}
								</div>
							</motion.div>
						</CollapsibleContent>
					)}
				</AnimatePresence>
			</Collapsible>
		</motion.div>
	);
});
