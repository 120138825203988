import { useAppContext } from "@/contexts/app-context/use-app-context";

export const useTabStore = () => {
	const appContext = useAppContext();
	if (!appContext) {
		throw new Error("useTabsContext must be used within an AppProvider");
	}

	return appContext.tabStore;
};
