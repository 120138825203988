import { PageErrorBoundaryFallback } from "@/components/page-error-boundary-fallback";
import type { SearchResult } from "@/contexts/tabs-context/tab-states/upload-state";
import { pathObjectToString } from "@/lib/paths";
import { FeedChannelPage } from "@/pages/feed/feed-channel-page";
import { FolderPage } from "@/pages/folder/folder-page";
import { ManageFeeds } from "@/pages/manage-feeds";
import { ManageTables } from "@/pages/manage-tables";
import { SearchPage } from "@/pages/search/search-page";
import { TablePage } from "@/pages/table-page";
import { UploadPage } from "@/pages/upload-page";
import { WebSearchPage } from "@/pages/web-search/web-search-page";
import type { TabPathObject } from "@api/schemas";
import { ErrorBoundary } from "@sentry/react";
import { Outlet, createMemoryRouter } from "react-router-dom";

export const createTabRouter = (
	initialPathObject: TabPathObject,
	initialPathState?: SearchResult,
) => {
	return createMemoryRouter(
		[
			{
				path: "/",
				element: (
					<ErrorBoundary
						fallback={(props) => <PageErrorBoundaryFallback {...props} />}
						showDialog
					>
						<Outlet />
					</ErrorBoundary>
				),
				children: [
					{
						path: "search/:searchId?",
						element: <SearchPage />,
					},
					{
						path: "manage-tables",
						element: <ManageTables />,
					},
					{
						path: "manage-feeds",
						element: <ManageFeeds />,
					},
					{
						path: "web-search/:webSearchId?",
						element: <WebSearchPage />,
					},
					{
						path: "upload/:fileId?",
						element: <UploadPage />,
					},
					{
						path: "folder/:fileId?",
						element: <FolderPage />,
					},
					{
						path: "table/:fileId?",
						element: <TablePage />,
					},
					{
						path: "feed-channel/:fileId?",
						element: <FeedChannelPage />,
					},
					{
						path: "feed-item/:fileId?",
						element: <FeedChannelPage />,
					},
				],
			},
		],
		{
			initialEntries: [
				{
					pathname: pathObjectToString(initialPathObject),
					state: initialPathState,
				},
			],
		},
	);
};
