import { TreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import {
	type AnyDirectoryNode,
	FEED_CHANNELS_FOLDER_ID,
} from "@/contexts/app-context/tree-handlers";
import { makeAutoObservable } from "mobx";

export class SidebarState {
	appState: AppState;

	treeState: TreeState;

	showSidebar = true;

	constructor(appState: AppState) {
		this.appState = appState;
		this.treeState = new TreeState({
			appState,
			getData: () => this.visibleNodes,
			handleNodeNavigation: (node) =>
				appState.tabStore.navigate(node.pathObject),
			isDndEnabled: false,
			isMultiSelect: true,
		});
		makeAutoObservable(this);
	}

	get visibleNodes(): AnyDirectoryNode[] {
		return this.appState.fileNodeTree.rootNodes.filter(
			(node) => node.file.file_id !== FEED_CHANNELS_FOLDER_ID,
		);
	}

	setShowSidebar = (show: boolean) => {
		this.showSidebar = show;
	};
}
