import type { AppState } from "@/contexts/app-context/app-context";
import type { DirectoryNode } from "@/contexts/app-context/tree-handlers";
import { FolderState } from "@/contexts/tabs-context/tab-states/folder-state";
import { pathObjectToString } from "@/lib/paths";
import { FileSelectorView } from "@/pages/file-selector";
import type { FileId, TabPathObject } from "@api/schemas";
import type { RouterState } from "@remix-run/router";
import { makeAutoObservable, runInAction } from "mobx";
import { createMemoryRouter } from "react-router-dom";

export class FileSelectorState {
	appState: AppState;

	router: ReturnType<typeof createMemoryRouter>;
	provider: React.ReactNode;
	routerState: RouterState;
	isMultiSelect = false;

	// showFileSelector = false;
	selectCallback:
		| ((props: { fileIds: FileId[]; isCancelled: boolean }) => void)
		| null = null;

	constructor(appState: AppState) {
		this.appState = appState;

		this.router = createMemoryRouter([
			{
				children: [
					{
						path: "/",
						element: <FileSelectorView />,
					},
					{
						path: "folder/:fileId?",
						element: <FileSelectorView />,
					},
				],
			},
		]);
		this.routerState = this.router.state;

		// Initialize a listener for the router that updates the pathname
		this.router.subscribe((state) => {
			runInAction(() => {
				this.routerState = state;
			});
		});

		makeAutoObservable(this);
	}

	get showFileSelector() {
		return this.selectCallback !== null;
	}

	executeSelect() {
		this.selectCallback?.({
			fileIds: [...this.folderState.treeState.selectedNodes.keys()],
			isCancelled: false,
		});
		this.selectCallback = null;
	}

	executeCancel() {
		this.selectCallback?.({ fileIds: [], isCancelled: true });
		this.selectCallback = null;
	}

	navigate(pathObject: TabPathObject) {
		if (pathObject.path === "folder") {
			this.router.navigate(pathObjectToString(pathObject));
		}
	}

	get folderState(): FolderState {
		const match = this.routerState?.matches.at(-1);

		const fileId = match?.params.fileId;

		const rootState = new FolderState({
			appState: this.appState,
			handleNodeNavigation: (node) => this.navigate(node.pathObject),
			folderNode: null,
			isReadonly: false,
			isMultiSelect: false,
		});

		if (!fileId) {
			return rootState;
		}

		const fileNode = this.appState.fileNodeTree.nodeMap.get(fileId as FileId);

		if (!fileNode) {
			console.error("File node not found", fileId);
			return rootState;
		}

		return new FolderState({
			appState: this.appState,
			handleNodeNavigation: (node) => this.navigate(node.pathObject),
			folderNode: fileNode as DirectoryNode<"folder">,
			isReadonly: false,
			isMultiSelect: this.isMultiSelect,
		});
	}
}
