import filePdfIcon from "@/assets/file-pdf.svg";
import { ImageWithFallback } from "@/components/image-with-fallback";
import { API_ENDPOINT_HTTP } from "@/config";
import type { PageResolution, UploadId } from "@api/schemas";
import type { ImgHTMLAttributes } from "react";

type UploadCoverImageProps = ImgHTMLAttributes<HTMLImageElement> & {
	upload_id: UploadId;
	resolution: PageResolution;
};

export const UploadCoverImage = ({
	upload_id,
	resolution,
	...props
}: UploadCoverImageProps) => {
	return (
		<ImageWithFallback
			{...props}
			src={`${API_ENDPOINT_HTTP}/uploads/cover_image/${upload_id}/${resolution}.jpg`}
			alt="Source thumbnail"
			fallbackSrc={filePdfIcon}
		/>
	);
};
