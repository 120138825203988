import type { CellProps } from "@/components/table/cell/cell-props";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { CellValue, RecordId } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const LookupCell = observer(
	({ recordId, field, value }: CellProps<"lookup">) => {
		const tableViewState = useTableViewContext();

		const relationshipField = tableViewState.tableState.getFieldById(
			field.properties.relationship_field_id,
		);
		if (relationshipField.type !== "relationship") {
			throw new Error("Lookup target field is not a relationship field");
		}

		// The LookupCell's values are in the same order as these RecordIds
		const relatedRecordIds = tableViewState.tableState.getCellValue(
			recordId,
			relationshipField,
		);

		if (!relatedRecordIds) {
			return null;
		}

		const zipped = (relatedRecordIds as RecordId[]).map(
			(recordId, index): [RecordId, CellValue] => {
				return [recordId, (value as RecordId[])[index]];
			},
		);

		return (
			<div>
				{zipped.map(([recordId, value]) => (
					<div key={recordId}>
						{recordId}: {JSON.stringify(value)}
					</div>
				))}
			</div>
		);
	},
);
