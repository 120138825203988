import type { AppState } from "@/contexts/app-context/app-context";
import type { SearchLibraryResultWithUpload } from "@/contexts/tabs-context/tab-states/search-state";
import type { UploadId, UploadMetadata } from "@api/schemas";

export type SearchResult = {
	activeGroupedSearchResults: SearchLibraryResultWithUpload[];
	activeSearchResult: SearchLibraryResultWithUpload;
};

export class UploadState {
	type = "upload" as const;
	appState: AppState;

	uploadId: UploadId;

	/**
	 * If the upload is the result of a search, keep the referring search result
	 * so we can render a list of search results in the sidebar and highlight sections
	 * of the document.
	 */
	searchResult: SearchResult | null;

	constructor(
		appState: AppState,
		uploadId: UploadId,
		searchResult: SearchResult | null,
	) {
		this.appState = appState;
		this.uploadId = uploadId;
		this.searchResult = searchResult;
	}

	get upload(): UploadMetadata {
		const upload = this.appState.getUploadById(this.uploadId);
		if (!upload) {
			throw new Error("Upload not found");
		}
		return upload;
	}
}
