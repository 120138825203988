import { Cell } from "@/components/table/cell/cell";
import { ColumnCreationHeader } from "@/components/table/column-creation-header";
import {
	ADD_COLUMN_ID,
	ColumnCreationPopoverTrigger,
	type MyRowType,
	columnHeader,
	columnHelper,
	selectorColumn,
} from "@/components/table/columns";
import type { AppState } from "@/contexts/app-context/app-context";
import type { TableState } from "@/contexts/app-context/db-store/table-stores";
import type { DirectoryNode } from "@/contexts/app-context/tree-handlers";
import { DisplayedActionError } from "@/contexts/synced-actions";
import { TableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { RecordId } from "@api/schemas";
import { Code, Copy } from "@phosphor-icons/react";
import type { ColumnDef } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { toast } from "sonner";

/**
 * View settings for a table within a tab.
 */
export class TableViewState {
	tableState: TableState;

	devMode = false;
	view: "table" | "record" = "table";
	editable: boolean;

	// the record ID of the current record in record view mode
	#visibleRecordId: RecordId | null = null;

	constructor(props: {
		tableState: TableState;
		editable: boolean;
		initialVisibleRecordId?: RecordId;
	}) {
		this.tableState = props.tableState;
		this.editable = props.editable;
		if (props.initialVisibleRecordId) {
			const rowIndex = this.tableState.sortedRecords.findIndex(
				(row) => row.record_id === props.initialVisibleRecordId,
			);
			if (rowIndex !== -1) {
				this.view = "record";
				this.#visibleRecordId = props.initialVisibleRecordId;
			}
		}
		makeAutoObservable(this);
	}

	setView(view: "table" | "record") {
		this.view = view;
	}

	get visibleRecordId(): RecordId | null {
		if (this.#visibleRecordId === null) {
			return null;
		}
		if (!this.tableState.records.has(this.#visibleRecordId)) {
			this.#visibleRecordId = null;
			this.view = "table";
		}
		return this.#visibleRecordId;
	}

	setVisibleRecordId(recordId: RecordId): void {
		this.#visibleRecordId = recordId;
	}

	get visibleRecordRowIndex(): number | null {
		if (this.visibleRecordId === null) {
			return null;
		}
		return (
			this.tableState.recordToSortedIndexMap.get(this.visibleRecordId) ?? null
		);
	}

	checkEditable() {
		if (!this.editable) {
			throw new DisplayedActionError("Table is not editable");
		}
	}

	get tanstackRows(): { recordId: RecordId }[] {
		return this.tableState.sortedRecords.map((row) => {
			return {
				recordId: row.record_id,
			};
		});
	}

	get tanstackColumns(): ColumnDef<MyRowType>[] {
		const columns: ColumnDef<MyRowType>[] = this.tableState.sortedFields.map(
			(field) => {
				return columnHelper.display({
					id: field.field_id,
					header: columnHeader({
						field,
					}),
					cell: observer(({ row: tanstackRow }) => {
						const recordId = tanstackRow.original.recordId;
						const value = this.tableState.getCellValue(recordId, field);
						return <Cell recordId={recordId} field={field} value={value} />;
					}),
					size: field.width,
				});
			},
		);

		if (this.editable) {
			columns.splice(0, 0, selectorColumn(this.tableState.isComputedTable));
			columns.push(
				columnHelper.display({
					id: ADD_COLUMN_ID,
					header: () => (
						<ColumnCreationHeader
							columnCreationPopoverTrigger={<ColumnCreationPopoverTrigger />}
						/>
					),
				}),
			);
		}
		if (this.devMode) {
			columns.splice(
				0,
				0,
				columnHelper.display({
					id: "recordId",
					header: () => (
						<div className="flex h-full w-full select-none items-center gap-2 truncate p-1 font-normal hover:bg-neutral-100">
							<Code />
							Record ID
						</div>
					),
					cell: ({ row }) => (
						<div className="flex items-center gap-1 p-1 text-xs">
							<button
								type="button"
								className="rounded-md border p-1 hover:bg-neutral-100"
								onClick={() => {
									navigator.clipboard.writeText(row.original.recordId);
									toast.success("Record ID copied to clipboard");
								}}
							>
								<Copy />
							</button>
							<pre className="text-neutral-500">{row.original.recordId}</pre>
						</div>
					),
					size: 300,
				}),
			);
		}
		return columns;
	}
}

/**
 * The state of a table tab.
 *
 * The tab might be loading the view.
 */
export class TableTabState {
	type = "table" as const;
	appState: AppState;
	tableNode: DirectoryNode<"table">;

	constructor(props: {
		appState: AppState;
		tableNode: DirectoryNode<"table">;
	}) {
		this.appState = props.appState;
		this.tableNode = props.tableNode;
		makeAutoObservable(this);
	}

	// TODO(John): this might not work well...
	get tableViewState() {
		const tableState = this.appState.workspace?.tables.getTableStateById(
			this.tableNode.file.table_id,
		);
		if (!tableState) {
			return null;
		}
		// Eventually, we will want to make this editable based on permissions
		return new TableViewState({ tableState, editable: true });
	}

	get tableMetadata() {
		return this.tableNode.file;
	}
}

export const TableViewProvider = ({
	tableViewState,
	children,
}: {
	tableViewState: TableViewState;
	children: React.ReactNode;
}) => {
	return (
		<TableViewContext.Provider value={tableViewState}>
			{children}
		</TableViewContext.Provider>
	);
};
