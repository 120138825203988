import {
	ADD_COLUMN_ID,
	DraggableHeader,
	type MyRowType,
} from "@/components/table/columns";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { FieldId, RecordId } from "@api/schemas";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	DragOverlay,
	type DragStartEvent,
	MouseSensor,
	TouchSensor,
	pointerWithin,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	restrictToHorizontalAxis,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { Plus, Trash } from "@phosphor-icons/react";
import {
	type Row,
	type RowSelectionState,
	type TableState,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { motion } from "motion/react";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";

const DraggableRow = observer(
	({
		row,
		overRowId,
		draggingRowIds,
		overColumnId,
	}: {
		row: Row<MyRowType>;
		overRowId: RecordId | null;
		draggingRowIds: Set<RecordId> | null;
		overColumnId: FieldId | null;
		draggedColumnId: FieldId | null;
	}) => {
		const { setNodeRef } = useDroppable({
			id: row.original.recordId,
		});
		const isDragging = draggingRowIds?.has(row.original.recordId);

		return (
			<>
				{/* Marker if dragged row is hovering over this row */}
				{overRowId === row.original.recordId && (
					<div
						key={`row-drop-indicator-${row.id}`}
						className="z-10 h-0 w-full p-0 ring-1 ring-blue-500"
					/>
				)}
				<div
					aria-label="tr"
					className={clsx(
						"flex cursor-pointer items-stretch",
						row.getIsSelected() ? "bg-blue-50" : "",
						"group/table-row",
						isDragging ? "opacity-50" : "",
					)}
					ref={setNodeRef}
					key={`row-body-${row.id}`}
				>
					{row.getVisibleCells().map((cell) => {
						const isPinned = cell.column.getIsPinned();

						return (
							<Fragment key={`cell-${cell.id}`}>
								{/* Marker if dragged column is hovering over this column */}
								{overColumnId === cell.column.id && (
									<div className="z-10 w-0 p-0 ring-1 ring-blue-500" />
								)}
								<div
									aria-label="td"
									className={clsx(
										// a set height is required to have the cell contents use height: 100%
										// without this, the cell contents will be vertically centered
										"whitespace-break-space flex shrink-0 items-center border-neutral-100 border-b bg-white p-0",
										cell.column.id !== ADD_COLUMN_ID && "border-r",
									)}
									style={{
										width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
										position: isPinned ? "sticky" : "relative",
										zIndex: isPinned ? 1 : 0,
										left: isPinned ? `${cell.column.getStart("left")}px` : 0,
									}}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</div>
							</Fragment>
						);
					})}
				</div>
			</>
		);
	},
);

// from https://github.com/TanStack/table/discussions/2498#discussioncomment-8649218
export const useResizeObserver = (
	state: TableState,
	callback: (columnId: string, columnSize: number) => void,
) => {
	// This Ref will contain the id of the column being resized or undefined
	const columnResizeRef = useRef<string | false>();
	useEffect(() => {
		// We are interested in calling the resize event only when "state.columnResizingInfo?.isResizingColumn" changes from
		// a string to false, because it indicates that it WAS resizing but it no longer is.
		if (
			state.columnSizingInfo &&
			!state.columnSizingInfo?.isResizingColumn &&
			columnResizeRef.current
		) {
			// Trigger resize event
			callback(
				columnResizeRef.current,
				state.columnSizing[columnResizeRef.current],
			);
		}
		columnResizeRef.current = state.columnSizingInfo?.isResizingColumn;
	}, [callback, state.columnSizingInfo, state.columnSizing]);
};

export const TableView = observer(() => {
	const tableViewState = useTableViewContext();

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const [draggedRowId, setDraggedRowId] = useState<RecordId | null>(null);
	const [overRowId, setOverRowId] = useState<RecordId | null>(null);
	const [draggingRowIds, setDraggingRowIds] = useState<Set<RecordId> | null>(
		null,
	);

	const [draggedColumnId, setDraggedColumnId] = useState<FieldId | null>(null);
	const [overColumnId, setOverColumnId] = useState<FieldId | null>(null);

	const table = useReactTable({
		data: tableViewState.tanstackRows,
		columns: tableViewState.tanstackColumns,
		getCoreRowModel: getCoreRowModel(),
		enableRowSelection: tableViewState.editable,
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
		},
		initialState: {},
		getRowId: (row) => row.recordId,
		enableColumnResizing: tableViewState.editable,
		columnResizeMode: "onChange",
		defaultColumn: {
			minSize: 60,
			maxSize: 800,
		},
	});

	useResizeObserver(table.getState(), (columnId, columnSize) => {
		tableViewState.tableState.updateField({
			fieldId: columnId as FieldId,
			newWidth: columnSize,
		});
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedRowIds = useMemo(() => {
		return new Set(
			table.getSelectedRowModel().rows.map((row) => row.original.recordId),
		);
	}, [rowSelection]);

	function handleColumnDragStart(event: DragStartEvent) {
		const activeDroppableId = event.active.id;
		setDraggedColumnId(activeDroppableId as FieldId);
	}

	function handleRowDragStart(event: DragStartEvent) {
		const activeDroppableId = event.active.id;

		const draggedId = activeDroppableId as RecordId;
		// Get selected ids from the current state
		setDraggedRowId(draggedId);

		const draggingIds = selectedRowIds.has(draggedId)
			? selectedRowIds
			: new Set([draggedId]);
		setDraggingRowIds(draggingIds);
	}

	function handleColumnDragOver(event: DragOverEvent) {
		const over = event.over;
		if (!over) return;
		setOverColumnId(over.id as FieldId);
	}

	function handleRowDragOver(event: DragOverEvent) {
		const over = event.over;
		if (!over) return;
		setOverRowId(over.id as RecordId);
	}

	function handleColumnDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		setDraggedColumnId(null);
		setOverColumnId(null);

		if (!over) return;

		const draggedColumnId = active.id as FieldId;
		const overColumnId = over.id as FieldId;

		if (draggedColumnId === overColumnId) return;

		const draggedColumn =
			tableViewState.tableState.getFieldById(draggedColumnId);
		const overColumn = tableViewState.tableState.getFieldById(overColumnId);

		if (!draggedColumn || !overColumn) return;

		tableViewState.tableState.moveField({
			fieldId: draggedColumnId,
			precedingFieldId: overColumnId,
		});
	}
	function handleRowDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		setDraggedRowId(null);
		setOverRowId(null);
		setDraggingRowIds(null);

		if (!over) return;

		const draggedRowId = active.id as RecordId;
		const overRowId = over.id as RecordId;
		// Get selected ids from the current state
		const draggingIds = selectedRowIds.has(draggedRowId)
			? selectedRowIds
			: new Set([draggedRowId]);

		tableViewState.tableState.moveRecords({
			recordIds: draggingIds,
			precedingRecordId: overRowId,
		});
	}

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				// Used to separate click events (e.g. for toggling header menus) from drag events
				delay: 250,
				distance: 5,
			},
		}),
		useSensor(TouchSensor, {}),
	);

	// Get rows being dragged for drag overlay
	const draggingRows = useMemo(() => {
		if (!draggingRowIds) return [];
		return table
			.getRowModel()
			.rows.filter((row) => draggingRowIds.has(row.original.recordId));
	}, [table.getRowModel, draggingRowIds]);

	/**
	 * Instead of calling `column.getSize()` on every render for every header
	 * and especially every data cell (very expensive),
	 * we will calculate all column sizes at once at the root table level in a useMemo
	 * and pass the column sizes down as CSS variables to the <table> element.
	 */

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const columnSizeVars = useMemo(() => {
		const headers = table.getFlatHeaders();
		const colSizes: { [key: string]: number } = {};
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			colSizes[`--header-${header.id}-size`] = header.getSize();
			colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
		}
		return colSizes;
	}, [
		table.getState().columnSizingInfo,
		table.getState().columnSizing,
		table.getAllColumns(),
	]);

	const rowData = table.getRowModel().rows;

	return (
		<>
			<div className="grow overflow-y-scroll">
				<div
					aria-label="table"
					className="relative flex grow border-collapse flex-col"
					style={{
						...columnSizeVars,
						width: table.getCenterTotalSize(),
					}}
				>
					{/* Drag and drop context for columns */}
					<DndContext
						collisionDetection={pointerWithin}
						modifiers={[restrictToHorizontalAxis]}
						onDragStart={handleColumnDragStart}
						onDragOver={handleColumnDragOver}
						onDragEnd={handleColumnDragEnd}
						sensors={tableViewState.editable ? sensors : []}
					>
						<div
							aria-label="thead"
							className="sticky top-0 z-10 flex bg-white text-neutral-700"
						>
							<div className="relative flex w-full">
								{table
									.getHeaderGroups()
									.map((headerGroup) =>
										headerGroup.headers.map((header) => (
											<DraggableHeader
												key={header.id}
												header={header}
												overId={overColumnId}
												draggedColumnId={draggedColumnId}
											/>
										)),
									)}
							</div>
						</div>
						{/* Drag previews for columns */}
						<DragOverlay>
							<div className="flex">
								{/* Tanstack doesn't have a function to get headers by ID,
								 so we have to do this the hard way */}
								{table
									.getHeaderGroups()
									.map((headerGroup) =>
										headerGroup.headers.map((header) =>
											header.id === draggedColumnId ? (
												<DraggableHeader
													key={`drag-preview-${header.id}`}
													header={header}
													overId={overColumnId}
													draggedColumnId={draggedColumnId}
												/>
											) : null,
										),
									)}
							</div>
						</DragOverlay>
					</DndContext>
					<DndContext
						collisionDetection={pointerWithin}
						modifiers={[restrictToVerticalAxis]}
						onDragStart={handleRowDragStart}
						onDragOver={handleRowDragOver}
						onDragEnd={handleRowDragEnd}
						sensors={tableViewState.editable ? sensors : []}
					>
						<div aria-label="tbody" className="h-full">
							{rowData.length ? (
								rowData.map((row) => (
									<DraggableRow
										key={`row-${row.id}`}
										row={row}
										overRowId={overRowId}
										draggingRowIds={draggingRowIds}
										overColumnId={overColumnId}
										draggedColumnId={draggedColumnId}
									/>
								))
							) : (
								<></>
							)}
							{draggedRowId && overRowId === null && (
								<div className="z-10 h-0 w-full p-0 ring-2 ring-blue-500" />
							)}

							{tableViewState.editable && (
								<button
									type="button"
									onClick={() => {
										tableViewState.tableState.addRecords({
											precedingRecordId:
												tableViewState.tableState.sortedRecords.at(-1)
													?.record_id ?? null,
										});
									}}
									className="flex w-full items-center gap-1 text-left text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
								>
									<span className="flex h-8 w-8 items-center justify-center">
										<Plus weight="bold" />
									</span>
									<span className="text-sm">New row</span>
								</button>
							)}
						</div>

						{/* Drag previews for rows */}
						<DragOverlay>
							{draggedRowId ? (
								<div
									className="flex flex-col border"
									style={{
										width: table.getCenterTotalSize(),
									}}
								>
									{draggingRows.map((row) => (
										<DraggableRow
											key={`drag-preview-${row.id}`}
											row={row}
											overRowId={null}
											draggingRowIds={draggingRowIds}
											overColumnId={overColumnId}
											draggedColumnId={draggedColumnId}
										/>
									))}
								</div>
							) : null}
						</DragOverlay>
					</DndContext>
				</div>
			</div>
			{tableViewState.editable && selectedRowIds.size > 0 ? (
				<motion.div
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 25 }}
					transition={{ duration: 0.15 }}
					className="absolute bottom-4 flex w-full justify-center"
				>
					<div className="flex items-center gap-2 rounded-lg border bg-white p-2 shadow-md">
						<div className="rounded-md rounded-l-lg border border-blue-200 bg-blue-50 px-4 py-1 text-blue-500 text-sm shadow-inner">
							{selectedRowIds.size} row
							{selectedRowIds.size > 1 ? "s" : ""} selected
						</div>
						<button
							type="button"
							onClick={() => {
								table.resetRowSelection();
								tableViewState.tableState.deleteRecords({
									recordIds: selectedRowIds,
								});
							}}
							className="flex h-full items-center gap-2 rounded-md px-3 text-sm hover:bg-neutral-100"
						>
							<Trash weight="bold" /> Delete
						</button>
					</div>
				</motion.div>
			) : null}
		</>
	);
});
