import type { CellProps } from "@/components/table/cell/cell-props";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import type { RecordId } from "@api/schemas";
import { Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const RelationshipCell = observer((props: CellProps<"relationship">) => {
	const { recordId, field, value } = props;
	const initialSelectedIds = (value ?? []) as RecordId[];
	const tableViewState = useTableViewContext();
	const foreignTable = tableViewState.tableState.tablesStore.getTableStateById(
		field.properties.foreign_table_id,
	);
	const foreignTableRecords = foreignTable?.sortedRecords ?? [];

	const [mainPopoverOpen, setMainPopoverOpen] = useState(false);
	const [selectorOpen, setSelectorOpen] = useState(false);
	const [query, setQuery] = useState("");
	const [selectedIds, setSelectedIds] = useState(initialSelectedIds);

	// Reset local state when cell value changes externally
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setSelectedIds(initialSelectedIds);
	}, [value]);

	const selectedRecords = foreignTableRecords.filter((record) =>
		selectedIds.includes(record.record_id),
	);
	const unselectedRecords = foreignTableRecords.filter(
		(record) => !selectedIds.includes(record.record_id),
	);

	const removeRecord = (recordIdToRemove: RecordId) => {
		setSelectedIds((prev) => prev.filter((id) => id !== recordIdToRemove));
	};

	const addRecord = (recordIdToAdd: RecordId) => {
		setSelectedIds((prev) => [...prev, recordIdToAdd]);
		setSelectorOpen(false);
	};

	const handleMainPopoverClose = (open: boolean) => {
		if (mainPopoverOpen) {
			// Only update if there were actual changes
			if (JSON.stringify(selectedIds) !== JSON.stringify(initialSelectedIds)) {
				tableViewState.tableState.updateCellValues({
					recordId,
					fieldId: field.field_id,
					cellValue: selectedIds,
				});
			}
		}
		setMainPopoverOpen(open);
	};

	return (
		<Popover open={mainPopoverOpen} onOpenChange={handleMainPopoverClose}>
			<PopoverTrigger
				disabled={!tableViewState.editable}
				className={clsx(
					"flex h-full w-full min-w-0 items-start truncate p-1 text-left text-neutral-800 text-sm",
					mainPopoverOpen && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{selectedRecords.length > 0 ? (
					<span className="truncate">
						{selectedRecords.map((record) => record.record_id).join(", ")}
					</span>
				) : (
					<span className="text-neutral-400">Select records...</span>
				)}
			</PopoverTrigger>
			<PopoverContent className="w-64 p-2" align="start">
				<div className="mb-2 flex flex-wrap gap-1">
					{selectedRecords.map((record) => (
						<div
							key={record.record_id}
							className="flex items-center gap-1 rounded-full bg-neutral-100 px-2 py-1 text-sm"
						>
							<span className="truncate">{record.record_id}</span>
							<button
								type="button"
								onClick={() => removeRecord(record.record_id)}
								className="rounded-full p-0.5 hover:bg-neutral-200"
							>
								<X className="h-3 w-3" />
							</button>
						</div>
					))}
					<Popover open={selectorOpen} onOpenChange={setSelectorOpen}>
						<PopoverTrigger asChild>
							<Button
								variant="outline"
								size="sm"
								className="h-6 w-6 rounded-full p-0"
							>
								<Plus className="h-4 w-4" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-64 p-0" align="start">
							<Command>
								<CommandInput
									value={query}
									onValueChange={setQuery}
									placeholder="Search records..."
								/>
								<CommandList>
									<CommandEmpty>No records found.</CommandEmpty>
									<CommandGroup>
										{unselectedRecords.map((record) => (
											<CommandItem
												key={record.record_id}
												value={String(record.record_id)}
												onSelect={() => addRecord(record.record_id)}
											>
												<span className="truncate">{record.record_id}</span>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
				</div>
			</PopoverContent>
		</Popover>
	);
});
