import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { FeedItemStatus } from "@/contexts/app-context/feeds";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { CaretRight, Rss, Spinner, X } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "motion/react";
import { useState } from "react";

const FeedItemStatusIcon = ({ status }: { status: FeedItemStatus }) => {
	switch (status) {
		case FeedItemStatus.pending:
			return <Spinner className="animate shrink-0 animate-spin" />;
		case FeedItemStatus.success:
			return <Rss className="h-4 max-w-6 shrink-0 shadow" />;
		case FeedItemStatus.failed:
			return <X className="shrink-0" />;
	}
};

/**
 * Drawer that shows pending feed items when a user has immediately added a feed channel.
 */
export const RecentFeedItemsDrawer = observer(() => {
	const appContext = useAppContext();
	const [isOpen, setIsOpen] = useState(false);

	if (appContext.recentFeedItemsArray.length === 0) {
		return null;
	}

	const numPending = appContext.recentFeedItemsArray.filter(
		(feedItem) => feedItem.status === FeedItemStatus.pending,
	).length;

	const allDone = numPending === 0;

	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			transition={{ duration: 0.3 }}
			className="fixed right-4 bottom-4 z-50 w-[32rem] overflow-hidden border shadow"
		>
			<Collapsible open={isOpen} onOpenChange={setIsOpen}>
				<CollapsibleTrigger className="group flex w-full items-center gap-2 bg-white px-4 py-3">
					<motion.button
						type="button"
						animate={{ rotate: isOpen ? 90 : 0 }}
						transition={{ duration: 0.2 }}
					>
						<CaretRight
							className="text-lg text-neutral-500 group-hover:text-neutral-800"
							weight="bold"
						/>
					</motion.button>
					<span className="grow text-left text-neutral-600 text-sm">
						{allDone ? (
							<>All feed items processed.</>
						) : (
							<>
								Processing {numPending} article{numPending > 1 ? "s" : ""}
							</>
						)}
					</span>
					{allDone && (
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									appContext.recentFeedItems = new Map();
								});
							}}
							className="p-1 text-lg"
						>
							<X />
						</button>
					)}
				</CollapsibleTrigger>
				<AnimatePresence>
					{isOpen && (
						<CollapsibleContent forceMount asChild>
							<motion.div
								initial={{ opacity: 0, height: 0 }}
								animate={{ opacity: 1, height: "auto" }}
								exit={{ opacity: 0, height: 0 }}
								transition={{ duration: 0.15 }}
							>
								<div className="flex max-h-[50vh] flex-col gap-0.5 overflow-y-scroll border-t bg-neutral-100 px-4 py-2">
									{appContext.recentFeedItemsArray.map((feedItem) => (
										<motion.div
											key={feedItem.feedItemId}
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: -20 }}
											transition={{ duration: 0.2 }}
											className="flex items-center gap-2 px-0.5 py-1 text-sm"
										>
											<FeedItemStatusIcon status={feedItem.status} />
											<span className="grow truncate text-left text-neutral-600">
												{feedItem.feedItemTitle}
											</span>
										</motion.div>
									))}
								</div>
							</motion.div>
						</CollapsibleContent>
					)}
				</AnimatePresence>
			</Collapsible>
		</motion.div>
	);
});
