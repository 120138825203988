import type { AppState } from "@/contexts/app-context/app-context";
import { createSyncedAction } from "@/contexts/synced-actions";
import { createNewEventId } from "@/lib/id-generators";
import { syncUserEventRoute } from "@api/fastAPI";
import type {
	Event,
	EventId,
	EventType,
	SessionAssistantId,
	SessionId,
	UserId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";

export type EventTypeMap = {
	[T in Event as T["type"]]: T;
};

type EventTypeAndData = {
	[K in keyof EventTypeMap]: {
		eventType: K;
		data: EventTypeMap[K]["data"];
	};
}[keyof EventTypeMap];

export function createEvent<T extends EventType>({
	eventType,
	userId,
	data,
}: {
	eventType: T;
	userId: UserId;
	data: EventTypeMap[T]["data"];
}): EventTypeMap[T] {
	return {
		event_id: createNewEventId(),
		user_id: userId,
		type: eventType,
		created_at: new Date().toISOString(),
		data,
	} as EventTypeMap[T];
}

type SessionEvent = Extract<Event, { data: { session_id: SessionId } }>;

export type AssistantEventMap = {
	[K in SessionEvent as K["type"]]: Omit<K, "data"> & {
		data: Omit<K["data"], "session_id"> & { session_id: SessionAssistantId };
	};
};

export type AssistantEvent = AssistantEventMap[keyof AssistantEventMap];

export class EventStore {
	appState: AppState;

	events: Map<EventId, Event> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	// Mutations
	handleEventLocally<T extends EventType>(event: EventTypeMap[T]) {
		this.events.set(event.event_id, event);
		switch (event.type) {
			case "sent_message": {
				this.appState.messageStore.createMessageLocally(event.data.message);
				break;
			}
			case "created_assistant_session": {
				this.appState.assistantSessionStore.createSessionAssistantLocally(
					event.data.assistant_session,
				);
				break;
			}
			case "created_user_session": {
				this.appState.createSessionUserLocally(event.data.session_user);
				break;
			}
			case "ended_session": {
				this.appState.assistantSessionStore.activeAssistantSessionStatuses.delete(
					event.data.session_id as SessionAssistantId,
				);
				break;
			}
		}
	}

	handleEvent = createSyncedAction<EventStore, EventTypeAndData, Event, void>({
		async local({ eventType, data }) {
			const event = createEvent({
				eventType,
				userId: this.appState.userId,
				data,
			});
			this.handleEventLocally(event);
			return event;
		},
		async remote(_, localResult) {
			await syncUserEventRoute({
				event: localResult,
			});
		},
		rollback(_args, localResult) {
			this.events.delete(localResult.event_id);
			switch (localResult.type) {
				case "sent_message": {
					this.appState.messageStore.messages.delete(
						localResult.data.message.message_id,
					);
					break;
				}
			}
		},
		onRemoteSuccess() {},
	});

	// Queries
	getEventsForAssistantSessionId = computedFn(
		(assistantSessionId: SessionAssistantId): AssistantEvent[] => {
			return Array.from(this.events.values()).filter(
				(event): event is AssistantEvent => {
					// Check if event has session_id
					if (!("session_id" in event.data)) {
						return false;
					}

					// Get the assistant session
					const sessionId = event.data.session_id;
					const assistantSession =
						this.appState.assistantSessionStore.getAssistantSessionById(
							sessionId as SessionAssistantId,
						);
					if (!assistantSession) {
						return false;
					}

					// Verify ownership and session match
					const isCorrectUser =
						assistantSession.user_id === this.appState.workspace?.userId;
					const isCorrectSession =
						assistantSession.session_assistant_id === assistantSessionId;

					return isCorrectUser && isCorrectSession;
				},
			);
		},
	);
}
