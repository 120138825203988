import type { AppState } from "@/contexts/app-context/app-context";
import { SearchableMap } from "@/contexts/app-context/db-store/searchable-map";
import type { UploadMetadata } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export class UploadsStore {
	appState: AppState;
	items: SearchableMap<UploadMetadata, "upload_id">;

	constructor(appState: AppState, uploads: UploadMetadata[]) {
		this.appState = appState;
		this.items = new SearchableMap(uploads, {
			id: "upload_id",
			fields: [
				"upload_title",
				"upload_subtitle",
				"upload_authors",
				"file_name",
			],
		});

		makeAutoObservable(this);
	}
}
