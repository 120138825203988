import type { AppState } from "@/contexts/app-context/app-context";
import { FeedItemStatus } from "@/contexts/app-context/feeds";
import { UploadStatus } from "@/contexts/app-context/uploads";
import type { WorkspaceUpdate } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

export function attemptReconnect(this: AppState) {
	Sentry.captureMessage("WebSocket connection lost", "error");

	if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
		console.error("Max reconnection attempts reached");
		toast.error("Unable to reconnect. Please refresh the page.");
		return;
	}
	toast.error("Connection lost. Reconnecting...");

	const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

	this.reconnectTimeoutId = setTimeout(() => {
		this.reconnectAttempts++;
		this.init({
			isReconnect: true,
		});
	}, delay);
}

export function handleWorkspaceUpdate(this: AppState, update: WorkspaceUpdate) {
	switch (update.type) {
		case "upsert_uploads": {
			for (const upload of update.uploads) {
				// upsert upload in library
				this.workspace?.uploads.items.set(upload.upload_id, upload);
			}
			break;
		}

		case "upsert_folders": {
			for (const folder of update.folders) {
				this.workspace?.folders.items.set(folder.folder_id, folder);
			}
			break;
		}

		case "move_files": {
			for (const file_id of update.file_ids) {
				const file = this.files.get(file_id);
				if (file) {
					file.file_parent_id = update.new_parent_id;
				}
			}
			break;
		}

		case "delete_files": {
			for (const file_id of update.file_ids) {
				const file = this.files.get(file_id);
				if (file) {
					file.file_deleted_at = update.deleted_at;
				}
			}
			break;
		}

		case "rename_file": {
			const file = this.files.get(update.file_id);
			if (file) {
				file.file_name = update.new_name;
			}
			break;
		}

		case "upsert_feed_channel": {
			const channel = update.feed_channel;
			this.workspace?.feedChannels.items.set(channel.feed_channel_id, channel);
			break;
		}

		case "update_feed_channel_refetching": {
			const channel = this.workspace?.feedChannels.items.get(
				update.feed_channel_id,
			);
			if (channel) {
				channel.feed_channel_refetching = update.feed_channel_refetching;
			}
			break;
		}

		case "upsert_feed_items": {
			for (const item of update.feed_items) {
				this.workspace?.feedItems.items.set(item.feed_item_id, item);
			}
			break;
		}

		case "upsert_table_metadata": {
			this.workspace?.tables.upsertTableMetadataLocally(update);
			break;
		}

		// TODO(John): probably want two levels of dispatch
		case "upsert_record":
		case "update_record_orders":
		case "update_cells":
		case "delete_records":
		case "add_field":
		case "update_field":
		case "delete_field":
		case "add_select_option":
		case "delete_select_option":
		case "update_select_option_label":
		case "update_select_option_color": {
			this.workspace?.tables.handleTableUpdate(update);
			break;
		}

		case "web_search_extract_content_update": {
			this.webSearchStore.handleWebSearchExtractContentUpdate(update);
			break;
		}

		case "web_search_synthesis": {
			this.webSearchStore.handleSynthesisUpdate(update);
			break;
		}

		// TODO(Tae): Reconsider this
		case "search_reranking": {
			break;
		}

		case "search_synthesis": {
			this.searchStore.handleSynthesisUpdate(update);
			break;
		}

		case "upload_failure": {
			const upload = this.recentUploads.get(update.upload_id);
			if (upload) {
				upload.status = UploadStatus.failed;
			}
			break;
		}

		case "upload_success": {
			const upload = this.recentUploads.get(update.upload_id);
			if (upload) {
				upload.status = UploadStatus.success;
				this.workspace?.uploads.items.set(update.upload_id, update.upload);
			}
			break;
		}

		case "feed_item_success": {
			const feedItem = this.recentFeedItems.get(update.feed_item_id);
			if (feedItem) {
				feedItem.status = FeedItemStatus.success;
			}
			break;
		}

		case "feed_item_failure": {
			const feedItem = this.recentFeedItems.get(update.feed_item_id);
			if (feedItem) {
				feedItem.status = FeedItemStatus.failed;
			}
			break;
		}

		default: {
			const _exhaustiveCheck: never = update;
			return _exhaustiveCheck;
		}
	}
}
