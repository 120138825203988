/**
 * We have many paths in our app.
 *
 * Paths are just strings. Paths can be inserted into any editor as links, and
 * the proper action should be taken upon clicking those links.
 *
 * We need to be able to parse a path to know what to do with it.
 * We also, in our code, want to only have to deal with those typed, parsed paths.
 */

import type {
	FeedChannelId,
	FeedItemId,
	File,
	FilePathObject,
	FolderId,
	MessageId,
	PathObject,
	RecordId,
	SearchId,
	SessionAssistantId,
	TableId,
	UploadId,
	WebSearchId,
} from "@api/schemas";

export function pathObjectToString(pathObject: PathObject): string {
	const basePath = `/${pathObject.path}`;

	switch (pathObject.path) {
		case "feed-channel":
		case "feed-item": {
			return `${basePath}/${pathObject.file_id}`;
		}
		case "table": {
			let path = `${basePath}/${pathObject.file_id}`;
			if (pathObject.record_id) {
				path += `?record_id=${pathObject.record_id}`;
			}
			return path;
		}
		case "folder": {
			if (pathObject.file_id) {
				return `${basePath}/${pathObject.file_id}`;
			}
			return basePath;
		}
		case "upload": {
			let path = `${basePath}/${pathObject.file_id}`;
			if (pathObject.page_range) {
				const params = new URLSearchParams({
					start_page: pathObject.page_range[0].toString(),
					end_page: pathObject.page_range[1].toString(),
				});
				path += `?${params.toString()}`;
			}
			return path;
		}
		case "assistant-session": {
			return `${basePath}/${pathObject.session_assistant_id}`;
		}
		case "search": {
			if (!pathObject.search_id) {
				return basePath;
			}
			return `${basePath}/${pathObject.search_id}`;
		}
		case "manage-tables":
			return basePath;
		case "manage-feeds":
			return basePath;
		case "web-search": {
			if (!pathObject.web_search_id) {
				return basePath;
			}
			return `${basePath}/${pathObject.web_search_id}`;
		}
		case "message": {
			return pathObject.message_id
				? `${basePath}/${pathObject.message_id}`
				: basePath;
		}
		default: {
			const _exhaustiveCheck: never = pathObject;
			return _exhaustiveCheck;
		}
	}
}

export function pathStringToObject(path: string): PathObject | Error {
	let url: URL;
	try {
		url = new URL(path, window.location.origin);
	} catch (e) {
		return new Error(`Couldn't create URL from path: ${path}, ${e}`);
	}

	const pathParts = url.pathname.split("/");
	if (pathParts.length < 2) {
		return new Error(
			`Path ${path} has parts ${pathParts}, must have at least 2 parts`,
		);
	}

	const mainPath = pathParts[1];
	const searchParams = Object.fromEntries(url.searchParams);

	switch (mainPath) {
		case "table": {
			if (pathParts.length < 3) {
				return new Error("Missing file_id");
			}
			const file_id = pathParts[2] as TableId;
			let recordId: RecordId | null = null;
			if (searchParams.record_id) {
				recordId = searchParams.record_id as RecordId;
			}
			return { path: "table", file_id, record_id: recordId };
		}
		case "folder": {
			if (pathParts.length < 3) {
				return new Error("Missing file_id");
			}
			return { path: "folder", file_id: pathParts[2] as FolderId };
		}
		case "feed-channel": {
			if (pathParts.length < 3) {
				return new Error("Missing file_id");
			}
			return { path: "feed-channel", file_id: pathParts[2] as FeedChannelId };
		}
		case "feed-item": {
			if (pathParts.length < 3) {
				return new Error("Missing file_id");
			}
			return { path: "feed-item", file_id: pathParts[2] as FeedItemId };
		}
		case "upload": {
			if (pathParts.length < 3) {
				return new Error("Missing file_id");
			}
			const file_id = pathParts[2] as UploadId;
			let pageRange: [number, number] | null = null;

			if (searchParams.start_page && searchParams.end_page) {
				const startPage = Number.parseInt(searchParams.start_page);
				const endPage = Number.parseInt(searchParams.end_page);
				if (Number.isNaN(startPage) || Number.isNaN(endPage)) {
					return new Error("Invalid page range parameters");
				}
				pageRange = [startPage, endPage];
			}

			let highlightedText: [string, string] | null = null;
			if (
				searchParams.highlighted_text_start &&
				searchParams.highlighted_text_end
			) {
				const start = searchParams.highlighted_text_start as string;
				const end = searchParams.highlighted_text_end as string;
				highlightedText = [start, end];
			}

			return {
				path: "upload",
				file_id,
				page_range: pageRange,
				highlighted_text: highlightedText,
			};
		}
		case "search": {
			return {
				path: "search",
				search_id: pathParts.length > 2 ? (pathParts[2] as SearchId) : null,
			};
		}
		case "manage-tables":
			return { path: mainPath };
		case "manage-feeds":
			return { path: mainPath };
		case "web-search": {
			return {
				path: mainPath,
				web_search_id:
					pathParts.length > 2 ? (pathParts[2] as WebSearchId) : null,
			};
		}
		case "message": {
			return {
				path: "message",
				message_id: pathParts.length > 2 ? (pathParts[2] as MessageId) : null,
			};
		}
		case "assistant-session": {
			if (pathParts.length < 3) {
				return new Error("Missing session_assistant_id");
			}
			return {
				path: "assistant-session",
				session_assistant_id: pathParts[2] as SessionAssistantId,
			};
		}
		default: {
			return new Error(`Invalid path: ${path}`);
		}
	}
}

export function pathObjectForFile(file: File): FilePathObject {
	return {
		path: file.file_type,
		file_id: file.file_id,
		...(file.file_type === "upload" ? { page_range: null } : {}),
	} as FilePathObject;
}
