// import { Button } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { SearchModeMeta } from "@/contexts/tabs-context/tab-states/search-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { useTabStore } from "@/contexts/tabs-context/use-tab-store";
import { X } from "@phosphor-icons/react";
import { matchSorter } from "match-sorter";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { toast } from "sonner";

interface SearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
}

export const SearchComboboxCommandList = observer(
	({ setShowCommandList }: SearchComboboxCommandListProps) => {
		const tabStore = useTabStore();
		const tab = useTab();
		const searchState = tab.state as TabTypeStateMap["search"];
		const {
			config: { query, search_mode, included_upload_ids },
		} = searchState.searchFormData;
		const includedUploadIds = new Set(included_upload_ids);

		const filteredSearchHistory = useMemo(
			() =>
				matchSorter(
					searchState.appState.searchStore.uniqueSearchHistory,
					query,
					{
						keys: ["query"],
					},
				),
			[searchState.appState.searchStore.uniqueSearchHistory, query],
		);

		return (
			<CommandList className="max-h-full">
				{query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								searchState.handleSearch();
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{query}"
								</h3>
								<p className="flex items-center gap-1 text-neutral-500 text-xs">
									<span className="text-sm">
										{SearchModeMeta[search_mode].icon}
									</span>
									{includedUploadIds.size > 0 ? (
										<>
											{includedUploadIds.size}
											{includedUploadIds.size === 1 ? "" : "s"}
										</>
									) : (
										"All uploads"
									)}
								</p>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				<CommandGroup
					className="p-2"
					heading={
						<span className="flex items-baseline justify-between gap-2">
							<h2 className="flex items-center gap-1">
								<span className="text-xs">Recent searches</span>
							</h2>{" "}
							<Button
								className="h-max rounded p-1 text-xs"
								variant="ghost"
								onClick={() => {
									toast.error("Not implemented");
								}}
							>
								Clear
							</Button>
						</span>
					}
				>
					<CommandEmpty>No recent searches</CommandEmpty>
					{/** TODO: This is not actually a search history since it just draws from the search store? */}
					{filteredSearchHistory.map((search, idx) => {
						const {
							search_id,
							config: { query, search_mode, included_upload_ids },
						} = search.searchConfig;
						return (
							<CommandItem
								key={search_id}
								// we use the index to ensure that the value is unique,
								// otherwise hovering on one item will highlight all items with the same query
								// (but possibly different search modes)
								value={`${query} ${idx}`}
								className="group flex w-full items-center gap-2 rounded-md"
								onSelect={() => {
									tabStore.navigate({
										path: "search",
										search_id: search_id,
									});
									setShowCommandList?.(false);
								}}
							>
								<div className="w-full min-w-0">
									<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
										{query}
									</h3>
									<p className="flex items-center gap-1 text-neutral-500 text-xs">
										<span className="text-sm">
											{SearchModeMeta[search_mode].icon}
										</span>
										{(included_upload_ids ?? []).length > 0 ? (
											<>
												{(included_upload_ids ?? []).length} upload
												{(included_upload_ids ?? []).length === 1 ? "" : "s"}
											</>
										) : (
											"All uploads"
										)}
									</p>
								</div>
								<button
									type="button"
									className="rounded-md p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
									onClick={(e) => {
										e.stopPropagation();
										toast.error("Not implemented");
									}}
								>
									<X size={16} />
								</button>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		);
	},
);
