import { MultipleDocumentsSelector } from "@/components/document-selectors/multiple-documents-selector";
import { Favicon } from "@/components/favicon";
import { FeedSelector } from "@/components/feed-selector";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { UploadCoverImage } from "@/components/upload-cover-image";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { SearchModeMeta } from "@/contexts/tabs-context/tab-states/search-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { formatAuthors, formatUploadTitle } from "@/lib/utils";
import { PageResolution, TextSearchMode } from "@api/schemas";
import {
	ArrowRight,
	Books,
	Rss,
	SlidersHorizontal,
	X,
} from "@phosphor-icons/react";
import { Close } from "@radix-ui/react-popover";
import clsx from "clsx";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";

export const SearchSettings = observer(() => {
	const tab = useTab();
	const searchState = tab.state as TabTypeStateMap["search"];

	const { searchFormData: searchConfig } = searchState;
	const {
		config: {
			include_library: includeLibrary,
			search_mode: searchMode,
			included_upload_ids,
			included_feed_channel_ids,
			include_feeds: includeFeeds,
		},
	} = searchConfig;
	const includedUploadIds = new Set(included_upload_ids);
	const includedFeedChannelIds = new Set(included_feed_channel_ids);
	const appContext = useAppContext();

	return (
		<Popover>
			<PopoverTrigger className="mt-2 mb-2 flex flex-wrap items-center gap-1 text-neutral-500 text-sm hover:text-black hover:underline">
				{includedUploadIds.size > 0 ? (
					<span className="flex flex-wrap items-center gap-1">
						<SlidersHorizontal className="text-base" />{" "}
						<span>
							Searching
							{includedUploadIds.size} selected uploads
						</span>
						{includedUploadIds.size > 1 ? "s" : ""} <span>with</span>
						<span className="ml-1 flex items-center gap-1 rounded-md border px-1 py-0.5 text-sm">
							{SearchModeMeta[searchMode].icon}
							<span className="text-xs">
								{SearchModeMeta[searchMode].title}
							</span>
						</span>
					</span>
				) : (
					<div className="flex w-full flex-wrap items-center gap-1 text-left">
						<SlidersHorizontal className="text-base" />
						<span>
							{appContext.sortedIndexedUploads === null ? (
								<>Searching all uploads with</>
							) : (
								<>
									Searching all {appContext.sortedIndexedUploads.length} uploads
									with
								</>
							)}
						</span>

						<span className="ml-1 flex max-w-max items-center gap-1 rounded-md border px-1 py-0.5 text-sm">
							{SearchModeMeta[searchMode].icon}
							<span className="text-xs">
								{SearchModeMeta[searchMode].title}
							</span>
						</span>
					</div>
				)}
			</PopoverTrigger>
			<PopoverContent align="start" className="mt-0 w-[28rem] rounded-lg p-4">
				<div className="flex items-center justify-between gap-8">
					<Label className="text-neutral-800 text-sm">Search mode</Label>
					<Select
						value={searchMode}
						onValueChange={(v) => {
							runInAction(() => {
								searchState.searchFormData.config.search_mode =
									v as TextSearchMode;
							});
						}}
					>
						<SelectTrigger className="w-48 bg-white">
							<SelectValue placeholder="Search type">
								<div className="flex items-center gap-3">
									<span className="text-xl">
										{SearchModeMeta[searchMode].icon}
									</span>{" "}
									<span className="text-sm">
										{SearchModeMeta[searchMode].title}
									</span>
								</div>
							</SelectValue>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={TextSearchMode.keyword}>
								<div className="flex items-center gap-4 px-2">
									<span className="text-2xl">
										{SearchModeMeta[TextSearchMode.keyword].icon}
									</span>
									<div>
										<h2 className="font-semibold">
											{SearchModeMeta[TextSearchMode.keyword].title}
										</h2>
										<p className="text-neutral-500">
											{SearchModeMeta[TextSearchMode.keyword].description}
										</p>
									</div>
								</div>
							</SelectItem>
							<SelectItem value={TextSearchMode.hybrid}>
								<div className="flex items-center gap-4 px-2">
									<span className="text-2xl">
										{SearchModeMeta[TextSearchMode.hybrid].icon}
									</span>
									<div>
										<h2 className="font-semibold">
											{SearchModeMeta[TextSearchMode.hybrid].title}
										</h2>
										<p className="text-neutral-500">
											{SearchModeMeta[TextSearchMode.hybrid].description}
										</p>
									</div>
								</div>
							</SelectItem>
						</SelectContent>
					</Select>
				</div>

				<div className="mt-4 flex flex-col rounded-lg border">
					<div
						className={clsx(
							"flex w-full items-center justify-between gap-2 bg-neutral-50 p-2 text-sm",
							includeLibrary ? "rounded-t-lg" : "rounded-lg",
						)}
					>
						<Label>Include uploads</Label>
						<Switch
							checked={includeLibrary}
							onCheckedChange={(checked) => {
								runInAction(() => {
									searchState.searchFormData.config.include_library = checked;
								});
							}}
						/>
					</div>
					{includeLibrary && (
						<div className="flex flex-col border-t p-2">
							<Label className="text-neutral-500 text-sm">
								Only search these uploads
							</Label>
							<MultipleDocumentsSelector
								popoverTrigger={
									<div className="flex h-8 w-full items-center gap-1 whitespace-nowrap rounded-md border border-neutral-200 bg-white px-2 py-1 text-neutral-700 text-sm shadow-sm ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:ring-offset-neutral-950 dark:focus:ring-neutral-300 dark:placeholder:text-neutral-400 [&>span]:line-clamp-1">
										<Books className="shrink-0 text-lg" weight="duotone" />
										<span>Select uploads...</span>
									</div>
								}
								selectedUploadIds={toJS(includedUploadIds)}
								selectedFeedItemIds={new Set()}
								onChange={(uploadIds) => {
									runInAction(() => {
										searchState.searchFormData.config.included_upload_ids =
											uploadIds;
									});
								}}
							/>

							<div className="relative mt-1 rounded-lg border border-neutral-200 shadow-sm">
								<div className="flex w-full flex-col gap-0.5">
									{includedUploadIds.size > 0 ? (
										<div className="py-1">
											{[...includedUploadIds].map((upload_id) => {
												const upload = appContext.getUploadById(upload_id);

												if (!upload) {
													return null;
												}

												return (
													<div
														key={upload_id}
														className="flex w-full min-w-0 items-center gap-2 rounded-md px-2 py-0.5"
													>
														<div>
															<UploadCoverImage
																resolution={PageResolution.thumbnail}
																className="h-6 max-w-6 rounded-xs shadow"
																upload_id={upload_id}
															/>
														</div>
														<div className="grow truncate">
															<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
																{formatUploadTitle({
																	title: upload.upload_title,
																	subtitle: upload.upload_subtitle,
																	filename: upload.file_name,
																})}
															</h2>
															<h3 className="min-w-0 truncate text-neutral-500 text-sm leading-4">
																{formatAuthors(upload.upload_authors ?? [])}
																{upload.upload_year_published &&
																	`, ${upload.upload_year_published}`}
															</h3>
														</div>
														<button
															type="button"
															className="shrink-0 rounded-md p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-black"
															onClick={() => {
																runInAction(() => {
																	searchState.searchFormData.config.included_upload_ids =
																		[...includedUploadIds].filter(
																			(s) => s !== upload_id,
																		);
																});
															}}
														>
															<X />
														</button>
													</div>
												);
											})}
										</div>
									) : (
										<div className="flex w-full items-center justify-center rounded-lg bg-neutral-50 p-4 text-neutral-500 text-sm">
											Searching all uploads
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="mt-4 flex flex-col rounded-lg border">
					<div
						className={clsx(
							"flex w-full items-center justify-between gap-2 bg-neutral-50 p-2 text-sm",
							includeFeeds ? "rounded-t-lg" : "rounded-lg",
						)}
					>
						<Label>Include feeds</Label>
						<Switch
							checked={includeFeeds}
							onCheckedChange={(checked) => {
								runInAction(() => {
									searchState.searchFormData.config.include_feeds = checked;
								});
							}}
						/>
					</div>
					{includeFeeds && (
						<div className="flex flex-col border-t p-2">
							<Label className="text-neutral-500 text-sm">
								Only search these feeds
							</Label>
							<FeedSelector
								popoverTrigger={
									<div className="flex h-8 w-full items-center gap-1.5 whitespace-nowrap rounded-md border border-neutral-200 bg-white px-2 py-1 text-neutral-700 text-sm shadow-sm ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:ring-offset-neutral-950 dark:focus:ring-neutral-300 dark:placeholder:text-neutral-400 [&>span]:line-clamp-1">
										<Rss className="shrink-0 text-lg" weight="duotone" />
										<span>Select feeds...</span>
									</div>
								}
								selectedFeedChannelIds={includedFeedChannelIds}
								onChange={(channelIds) => {
									runInAction(() => {
										searchState.searchFormData.config.included_feed_channel_ids =
											channelIds;
									});
								}}
							/>

							<div className="relative mt-1 rounded-lg border border-neutral-200 shadow-sm">
								<div className="flex w-full flex-col gap-0.5">
									{includedFeedChannelIds.size > 0 ? (
										<div className="py-1">
											{[...includedFeedChannelIds].map((channelId) => {
												const channel =
													appContext.feedChannelsById?.get(channelId);

												const numItems =
													appContext.sortedFeedItemsByChannel?.get(channelId)
														?.length ?? 0;

												if (!channel) {
													return null;
												}

												return (
													<div
														key={channelId}
														className="flex w-full min-w-0 items-center gap-2 rounded-md px-2 py-1"
													>
														{channel.feed_channel_link ? (
															<Favicon
																url={channel.feed_channel_link}
																alt={channel.file_name}
																className="h-8 w-8 shrink-0 rounded-md bg-neutral-100"
															/>
														) : (
															<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
														)}
														<div className="grow truncate">
															<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
																{channel.file_name}
															</h2>
															<h3 className="text-neutral-500 text-sm">
																{numItems}
																{numItems !== 0 ? " articles" : " article"}
															</h3>
														</div>
														<button
															type="button"
															className="shrink-0 rounded-md p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-black"
															onClick={() => {
																runInAction(() => {
																	searchState.searchFormData.config.included_feed_channel_ids =
																		[...includedFeedChannelIds].filter(
																			(s) => s !== channelId,
																		);
																});
															}}
														>
															<X />
														</button>
													</div>
												);
											})}
										</div>
									) : (
										<div className="flex w-full items-center justify-center rounded-lg bg-neutral-50 p-4 text-neutral-500 text-sm">
											Searching all feeds
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="mt-4 flex justify-end gap-4">
					<Close type="button" asChild>
						<Button variant="ghost">Cancel</Button>
					</Close>
					<Close asChild>
						<Button
							className="flex items-center gap-2"
							onClick={() => searchState.handleSearch()}
						>
							Rerun search <ArrowRight weight="bold" />
						</Button>
					</Close>
				</div>
			</PopoverContent>
		</Popover>
	);
});
