import type { Tab } from "@/contexts/tabs-context/tabs-context";
import { useContext } from "react";
import { createContext } from "react";

export const TabContext = createContext<Tab | null>(null);

export const useTab = () => {
	const context = useContext(TabContext);
	if (!context) {
		throw new Error("useTab must be used within an TabProvider");
	}
	return context;
};
