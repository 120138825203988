import { IS_DEV } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { createNewWebSearchId } from "@/lib/id-generators";
import { webSearchRoute } from "@api/fastAPI";
import type {
	WebSearch,
	WebSearchContents,
	WebSearchId,
	WebSearchResponse,
	WebSearchResult,
	WebSearchSynthesis,
} from "@api/schemas";
import * as Sentry from "@sentry/react";
import { makeAutoObservable } from "mobx";
import { toast } from "sonner";

// TODO: Unify with WebSearchPathObject
export interface OrderedWebSearchResult extends WebSearchResult {
	order: number;
}

export class SavedWebSearchConfig {
	web_search_id: WebSearchId;
	config: WebSearchConfig;

	constructor(web_search_id: WebSearchId, config: WebSearchConfig) {
		this.web_search_id = web_search_id;
		this.config = config;
		makeAutoObservable(this);
	}
}

class WebSearchConfig {
	query = "";

	constructor() {
		makeAutoObservable(this);
	}
}

export class WebSearchFormData {
	config: WebSearchConfig = new WebSearchConfig();

	constructor() {
		makeAutoObservable(this);
	}
}

export class WebSearchResultLocal {
	webSearchConfig: SavedWebSearchConfig;
	createdAt: string;
	synthesis: string | null;
	searchResultsMap: Map<string, OrderedWebSearchResult> = new Map();
	searchComplete = false;

	constructor(
		webSearch: WebSearch,
		savedWebSearchConfig: SavedWebSearchConfig,
	) {
		this.webSearchConfig = savedWebSearchConfig;
		this.createdAt = webSearch.created_at;
		this.synthesis = webSearch.synthesis;

		// Convert search results to OrderedWebSearchResult and save as map
		this.searchResultsMap = new Map(
			webSearch.search_results.map((result, index) => [
				result.url,
				{ ...result, order: index },
			]),
		);
		makeAutoObservable(this);
	}

	get loadingState() {
		if (!this.searchComplete) {
			return "searching";
		}
		// This is not technically correct
		// Having a synthesis does not mean the synthesis is complete
		if (this.synthesis === null) {
			return "synthesizing";
		}
		return "completed";
	}

	get searchResults() {
		return Array.from(this.searchResultsMap.values()).sort(
			(a, b) => a.order - b.order,
		);
	}

	fromResponse(response: WebSearchResponse) {
		const websites = response.web_search.search_results.map(
			(website, index) => ({
				...website,
				order: index,
			}),
		);
		this.searchResultsMap = new Map(
			websites.map((website) => [website.url, website]),
		);
		this.synthesis = response.web_search.synthesis;
		this.createdAt = response.web_search.created_at;
		this.searchComplete = true;
	}

	handleWebsiteUpdate(update: WebSearchContents) {
		const website = this.searchResultsMap.get(update.url);
		if (!website) {
			return;
		}
		website.content = update.extracted;
	}

	handleSynthesisUpdate(update: WebSearchSynthesis) {
		this.synthesis = update.synthesis;
	}
}

export class WebSearchStore {
	appState: AppState;
	webSearchHistory: Map<string, WebSearchResultLocal> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getOrInitiateWebSearch(
		savedWebSearchConfig: SavedWebSearchConfig | null,
	): WebSearchResultLocal | null {
		if (!savedWebSearchConfig) {
			return null;
		}
		const webSearchId = savedWebSearchConfig.web_search_id;
		if (!this.webSearchHistory.has(webSearchId)) {
			// Initialize search result w/ loading
			const searchState = new WebSearchResultLocal(
				{
					created_at: "",
					query: savedWebSearchConfig.config.query,
					search_results: [],
					synthesis: null,
					web_search_id: webSearchId,
				},
				savedWebSearchConfig,
			);
			this.webSearchHistory.set(webSearchId, searchState);

			// Fetch and initialize the table
			this.initiateWebSearch(savedWebSearchConfig);
			return null;
		}
		return this.webSearchHistory.get(webSearchId) as WebSearchResultLocal;
	}

	async initiateWebSearch(savedWebSearchConfig: SavedWebSearchConfig) {
		webSearchRoute({
			query: savedWebSearchConfig.config.query,
			web_search_id: savedWebSearchConfig.web_search_id,
		})
			.then((res) => {
				const webSearchResult = this.webSearchHistory.get(
					savedWebSearchConfig.web_search_id,
				);
				if (!webSearchResult) {
					return;
				}
				webSearchResult.fromResponse(res.data);
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to fetch web search results");
				}
			});
	}

	handleWebSearchExtractContentUpdate(update: WebSearchContents) {
		const webSearchId = update.web_search_id;
		const webSearchResult = this.webSearchHistory.get(webSearchId);
		if (!webSearchResult) {
			return;
		}
		webSearchResult.handleWebsiteUpdate(update);
	}

	handleSynthesisUpdate(update: WebSearchSynthesis) {
		const webSearchId = update.web_search_id;
		const webSearchResult = this.webSearchHistory.get(webSearchId);
		if (!webSearchResult) {
			return;
		}
		webSearchResult.handleSynthesisUpdate(update);
	}

	get uniqueSearchHistory(): WebSearchResultLocal[] {
		return Array.from(this.webSearchHistory.values()); // Reverse to show most recent searches first
	}
}

export class WebSearchState {
	appState: AppState;
	type = "web-search" as const;

	// When you load a web search result
	savedWebSearchConfig: SavedWebSearchConfig | null = null;

	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;
	webSearchFormData: WebSearchFormData = new WebSearchFormData();

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	constructor(
		appState: AppState,
		savedWebSearchConfig: SavedWebSearchConfig | null,
	) {
		this.appState = appState;
		this.savedWebSearchConfig = savedWebSearchConfig;
		makeAutoObservable(this);
	}

	handleSearch() {
		const webSearchId = createNewWebSearchId();
		this.appState.tabStore.navigate(
			{
				path: "web-search",
				web_search_id: webSearchId,
			},
			{
				state: this.webSearchFormData,
			},
		);
	}
}
