import { FolderTree } from "@/components/tree/tree";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { FileUploadDialogContent } from "@/pages/folder/file-upload-dialog-content";
import {
	FolderPlus,
	FolderSimple,
	House,
	UploadSimple,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

export const FolderPage = observer(() => {
	const tab = useTab();
	const tabStore = tab.tabStore;
	const appContext = tabStore.appState;

	const folderState = tab.state as TabTypeStateMap["folder"];

	return (
		<div className="relative mx-auto flex h-full w-full flex-col">
			<nav
				className={clsx(
					"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-2 backdrop-blur",
				)}
			>
				<div className="flex items-center">
					{/* Current library path */}
					<Breadcrumb>
						<BreadcrumbList className="p-4">
							<BreadcrumbItem key="root">
								<BreadcrumbLink asChild>
									<button
										type="button"
										onClick={() => {
											tabStore.navigate({ path: "folder", file_id: null });
										}}
										className="flex items-center gap-1"
									>
										<House weight="duotone" />
										Home
									</button>
								</BreadcrumbLink>
							</BreadcrumbItem>
							{folderState.node.ancestors.map((node) => (
								<Fragment key={node.id}>
									<BreadcrumbSeparator />
									<BreadcrumbItem>
										<BreadcrumbLink asChild>
											<button
												className="flex items-center gap-1 text-neutral-600"
												type="button"
												onClick={() => {
													tabStore.navigate(node.pathObject);
												}}
											>
												<FolderSimple weight="duotone" />
												{node.file.file_name}
											</button>
										</BreadcrumbLink>
									</BreadcrumbItem>
								</Fragment>
							))}
						</BreadcrumbList>
					</Breadcrumb>

					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								type="button"
								variant="ghost"
								className="p-2"
								onClick={() => {
									appContext.createFolder({
										parentFolderId: folderState.node.id,
										folderName: "New folder",
									});
								}}
								disabled={appContext.workspace === null}
							>
								<FolderPlus className="text-lg" weight="duotone" />
							</Button>
						</TooltipTrigger>
						<TooltipContent align="start">New folder</TooltipContent>
					</Tooltip>
					<Tooltip>
						<Dialog modal>
							<TooltipTrigger asChild>
								<DialogTrigger disabled={appContext.workspace === null} asChild>
									<Button
										type="button"
										variant="ghost"
										className="p-2"
										disabled={appContext.workspace === null}
									>
										<UploadSimple className="text-lg" />
									</Button>
								</DialogTrigger>
							</TooltipTrigger>
							<FileUploadDialogContent
								parent={
									folderState.folderNode ? folderState.folderNode.file : null
								}
							/>
						</Dialog>
						<TooltipContent>Upload files</TooltipContent>
					</Tooltip>
					{/* <Tooltip>
						<TooltipTrigger asChild>
							<Button
								type="button"
								variant="ghost"
								className="p-2"
								disabled={appContext.workspace === null}
								onClick={() => {
									appContext.createPage({
										parentFolderId: folderState.node.id,
										pageName: "New page",
									});
								}}
							>
								<File className="text-lg" />
							</Button>
						</TooltipTrigger>
						<TooltipContent align="start">New page</TooltipContent>
					</Tooltip> */}
				</div>
			</nav>

			<div className="max-w-96 px-2 pt-2">
				<Input
					placeholder="Search uploads..."
					value={folderState.treeState.searchQuery}
					onChange={(e) => folderState.treeState.setSearchQuery(e.target.value)}
				/>
			</div>

			<div className="mt-2 flex w-full items-center border-b px-4 pb-2 text-neutral-700 text-sm">
				<div className="w-2/3">Name</div>
				<div className="w-1/3">Date added</div>
			</div>

			<div className="max-h-full min-h-0 grow overflow-y-auto">
				<FolderTree treeState={folderState.treeState} />
			</div>
		</div>
	);
});
