import type { CellProps } from "@/components/table/cell/cell-props";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const NumberCell = observer(
	({ recordId, field, value }: CellProps<"number">) => {
		const [open, setOpen] = useState(false);
		const tableViewState = useTableViewContext();

		const [newValue, setNewValue] = useState<string>(value?.toString() ?? "");
		useEffect(() => {
			setNewValue(value?.toString() ?? "");
		}, [value]);

		const onSubmit = () => {
			// if the new value is empty, clear the cell
			if (newValue.trim() === "") {
				tableViewState.tableState.updateCellValues({
					recordId,
					fieldId: field.field_id,
					cellValue: null,
				});
				return;
			}
			const newNumber = Number.parseFloat(newValue);
			if (newNumber !== value && !Number.isNaN(newNumber)) {
				tableViewState.tableState.updateCellValues({
					recordId,
					fieldId: field.field_id,
					cellValue: newNumber,
				});
			}
		};

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen) {
						onSubmit();
					}
				}}
			>
				<PopoverTrigger
					className={clsx(
						"flex h-full w-full min-w-0 flex-col items-end justify-start truncate p-1 text-right text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
					disabled={!tableViewState.editable}
				>
					{/* TODO(John): fix cast */}
					{newValue || (value as number)}
				</PopoverTrigger>
				<PopoverContent align="start" className="overflow-hidden p-0">
					<input
						value={newValue}
						onChange={(e) => {
							setNewValue(e.target.value);
						}}
						className="w-full p-2 text-sm outline-none focus:outline-none"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								setOpen(false);
								onSubmit();
							}
						}}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
