import { Favicon } from "@/components/favicon";
import { getTabIcon } from "@/components/layout/tab-icons";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandInputWithBadge,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { CommandKPage } from "@/contexts/app-context/cmd-k";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { useTabStore } from "@/contexts/tabs-context/use-tab-store";
import { pathObjectForFile } from "@/lib/paths";
import { AddFeedDialog } from "@/pages/feed/add-feed-dialog";
import { useAuth } from "@clerk/clerk-react";
import {
	Books,
	Gear,
	Info,
	MagnifyingGlass,
	Plus,
	Rss,
} from "@phosphor-icons/react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";

export const CommandKButton = observer(() => {
	const appContext = useAppContext();
	const setCmdKOpen = (open: boolean) => {
		runInAction(() => {
			appContext.cmdKOpen = open;
		});
	};

	return (
		<Button
			variant={"outline"}
			className="gap-1 text-neutral-600"
			onClick={() => setCmdKOpen(true)}
		>
			<span className="font-medium text-sm">Command bar</span>{" "}
			<kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-medium font-mono text-[10px] text-muted-foreground opacity-100">
				<span className="text-xs">⌘</span>K
			</kbd>
		</Button>
	);
});

export const CommandKDialog = observer(() => {
	const appContext = useAppContext();
	const feedChannels = appContext.sortedFeedChannels;
	const toggleOpen = () => {
		runInAction(() => {
			appContext.cmdKOpen = !appContext.cmdKOpen;
		});
	};
	const [value, setValue] = React.useState("");

	const tabStore = useTabStore();
	const { signOut } = useAuth();

	const fileSearchResults = appContext.searchFiles(value);
	const mergedSearchResults = React.useMemo(() => {
		return [...fileSearchResults.uploads, ...fileSearchResults.feedItems];
	}, [fileSearchResults]);

	// const onSelectSearch = () => {
	// 	const newUrlSearchParams = new URLSearchParams();
	// 	newUrlSearchParams.set("q", value);
	// 	newUrlSearchParams.set("mode", "hybrid");
	// 	newUrlSearchParams.set("upload_ids", "");
	// 	newUrlSearchParams.set("group_results_by_upload", "false");
	// 	// TODO(John): make URL params for search work
	// 	// navigate(`/search?${newUrlSearchParams.toString().replace(/%20/g, "+")}`);

	// 	// TODO(Tae): This is wrong, we need to make sure a search_id is created
	// 	tabStore.navigate({
	// 		path: "search",
	// 		query: newUrlSearchParams.toString(),
	// 		search_id: null,
	// 	});
	// };

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				toggleOpen();
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	return (
		<>
			<AddFeedDialog />
			<Dialog
				open={appContext.cmdKOpen}
				onOpenChange={(open) => appContext.setCmdKOpen(open)}
			>
				<DialogContent className="overflow-hidden p-0">
					<VisuallyHidden.Root>
						<DialogTitle>Command K</DialogTitle>
						<DialogDescription>Press Esc to close Command K</DialogDescription>
					</VisuallyHidden.Root>
					<Command
						className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-neutral-500 dark:[&_[cmdk-group-heading]]:text-neutral-400 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
						onKeyDown={(e) => {
							// Escape goes to previous page
							// Backspace goes to previous page when search is empty
							if (e.key === "Escape" || (e.key === "Backspace" && !value)) {
								e.preventDefault();
								appContext.popCmdKPage();
							}
						}}
					>
						{appContext.lastCmdKPage === "RSS" ? (
							<>
								<CommandInputWithBadge
									placeholder="Type the name or URL of an RSS feed..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandGroup heading="">
										{value && (
											<CommandItem
												onSelect={() => {
													runInAction(() => {
														appContext.showAddFeedDialog = true;
													});
												}}
												value={value}
											>
												<Plus className="mr-2 h-4 w-4" />
												<span>Add a feed</span>
											</CommandItem>
										)}
									</CommandGroup>
									<CommandGroup heading="Feeds">
										{feedChannels?.map((channel) => (
											<CommandItem
												key={channel.feed_channel_id}
												onSelect={() => {
													tabStore.navigate({
														path: "feed-channel",
														file_id: channel.feed_channel_id,
													});
													appContext.setCmdKOpen(false);
												}}
											>
												{channel.feed_channel_link ? (
													<Favicon
														url={channel.feed_channel_link}
														alt={channel.file_name}
														className="h-6 w-6 shrink-0 rounded-md bg-neutral-200"
													/>
												) : (
													<div className="h-6 w-6 shrink-0 rounded-md bg-neutral-300" />
												)}
												<span className="ml-2">{channel.file_name}</span>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}

						{!appContext.lastCmdKPage ? (
							<>
								<CommandInput
									placeholder="Type a command or search..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandEmpty>No results found.</CommandEmpty>
									<CommandGroup heading="Files">
										{mergedSearchResults.map((file) => {
											const Icon = getTabIcon(file);
											const getSelectionHandler = () => {
												return () => tabStore.navigate(pathObjectForFile(file));
											};

											return (
												<CommandItem
													key={file.file_id}
													onSelect={() => {
														getSelectionHandler()();
														appContext.setCmdKOpen(false);
													}}
												>
													<Icon className="mr-2 h-4 w-4" />
													{file.file_name}
												</CommandItem>
											);
										})}
										{mergedSearchResults.length === 0 && (
											<div className="py-1 text-center text-neutral-500 text-sm">
												No results found.
											</div>
										)}
									</CommandGroup>
									<CommandSeparator />
									<CommandGroup heading="Actions">
										<CommandItem
											onSelect={() => {
												appContext.pushCmdKPage(CommandKPage.RSS);
											}}
										>
											<Rss className="mr-2 h-4 w-4" />
											<span>Manage RSS Feeds</span>
										</CommandItem>
										{/* <CommandItem
											onSelect={() => {
												if (value) {
													onSelectSearch();
												} else {
													tabStore.navigate({
														path: "search",
														query: null,
														search_id: null,
													});
												}
												appContext.setCmdKOpen(false);
											}}
										>
											<MagnifyingGlass className="mr-2 h-4 w-4" />
											<span>{value ? `Search \"${value}\"` : "Search"}</span>
										</CommandItem> */}
									</CommandGroup>
									<CommandSeparator />

									<CommandGroup heading="Navigation">
										<CommandItem
											onSelect={() => {
												tabStore.navigate({
													path: "search",
													search_id: null,
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<MagnifyingGlass className="mr-2 h-4 w-4" />
											<span>Navigate to Search</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												tabStore.navigate({
													path: "folder",
													file_id: null,
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<Books className="mr-2 h-4 w-4" />
											<span>Navigate to Library</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-research-assistant-work-77ae2b1dc43a48a69ce3260e68d3b4e8",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the research assistant work?</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-search-page-work-f24c59a827474449ab320860314d6019?pvs=25",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the search page work?</span>
										</CommandItem>
									</CommandGroup>
									<CommandSeparator />
									<CommandGroup heading="Settings">
										<CommandItem
											onSelect={() => {
												signOut();
											}}
										>
											<Gear className="mr-2 h-4 w-4 text-red-700" />

											<span className="text-red-700">Sign out</span>
										</CommandItem>
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}
					</Command>
				</DialogContent>
			</Dialog>
		</>
	);
});
