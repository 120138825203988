import { useAppContext } from "@/contexts/app-context/use-app-context";
import { markdownToTiptap } from "@/lib/serializers";
import { cn } from "@/lib/utils";
import { ImageResize } from "@/plugins/image-resize";
import {
	CustomLink,
	ObjectLink,
	getObjectLinkExtension,
} from "@/plugins/object-link";
import { Outline } from "@/plugins/outline-plugin";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Code from "@tiptap/extension-code";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { DOMParser, type Node } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import {
	type Content,
	type Editor,
	EditorContent,
	createDocument,
	elementFromString,
	getSchema,
	useEditor,
} from "@tiptap/react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

export const COMMON_EXTENSIONS = [
	Document,
	Paragraph,
	Text,
	Bold.configure({
		HTMLAttributes: {
			class: "font-semibold",
		},
	}),
	Underline,
	Italic,
	Strike,
	Code,
	CodeBlock,
	Heading,
	Blockquote,
	TextAlign.configure({
		types: ["heading", "paragraph"],
		alignments: ["left", "center", "right", "justify"],
		defaultAlignment: "left",
	}),
	BulletList,
	ListItem,
	OrderedList,
	TaskList,
	TaskItem.configure({
		nested: true,
	}),
	ImageResize,
	Dropcursor,
	Outline,
	Table.configure({
		resizable: true,
	}),
	TableRow,
	TableHeader,
	TableCell,
	HorizontalRule,
	HardBreak,
	// Our extensions
	CustomLink,
];
const VIEWONLY_EXTENSIONS = [...COMMON_EXTENSIONS, ObjectLink];
const schema = getSchema(VIEWONLY_EXTENSIONS);
const parser = DOMParser.fromSchema(schema);

export function getEditorClassName(className?: string) {
	return cn(
		"prose prose-sm prose-neutral prose-p:text-neutral-900 first:prose-p:mt-0 last:prose-p:mb-0",
		className,
	);
}

export const CustomEditor = observer(
	(props: {
		className?: string;
		options: Omit<NonNullable<Parameters<typeof useEditor>[0]>, "extensions">;
		onEditorReady?: (editor: Editor) => void;
	}) => {
		const appContext = useAppContext();
		const editor = useEditor(
			{
				extensions: [
					...COMMON_EXTENSIONS,
					getObjectLinkExtension(appContext.searchUploadsAndTables),
				],
				...props.options,
			},
			[],
		);

		useEffect(() => {
			if (props.onEditorReady && editor) {
				props.onEditorReady(editor);
			}
		}, [editor, props.onEditorReady]);

		return (
			<EditorContent
				editor={editor}
				className={getEditorClassName(props.className)}
			/>
		);
	},
);

/**
 * Given a Tiptap HTML string, return a string of HTML that can be rendered
 * directly in the DOM.
 *
 * If we want to be more aggressive with optimization, we can technically create
 * the view globally, and replace the node within the function with:
 *
 * 	view.dispatch(
 * 		view.state.tr.replaceWith(0, view.state.doc.content.size, node.content),
 * 	);
 *
 * But the approach below is safer and seems to be about as fast.
 */
function generateHtmlString(content: string | Content) {
	let node: Node;
	if (typeof content === "string") {
		const htmlElement = elementFromString(content);
		node = parser.parse(htmlElement);
	} else {
		node = createDocument(content, schema);
	}

	const bufferDiv = document.createElement("div");
	const view = new EditorView(bufferDiv, {
		state: EditorState.create({ doc: node }),
		editable: () => false,
	});
	const html = bufferDiv.innerHTML;
	view.destroy();

	return html;
}

/**
 * For when you want to display HTML content but don't need an editor instance.
 */
export const ViewOnlyEditor = observer(
	(props: {
		className?: string;
		content: string | Content;
	}) => {
		const html = generateHtmlString(props.content);
		return (
			<div
				className={getEditorClassName(props.className)}
				// biome-ignore lint/security/noDangerouslySetInnerHtml: content is sanitized
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		);
	},
);

/**
 * A streaming editor that updates the content as it is being synthesized.
 */
export const StreamingEditor = observer(
	(props: {
		className?: string;
		options: Omit<
			NonNullable<Parameters<typeof useEditor>[0]>,
			"extensions" | "content"
		> & {
			content: string | null;
		};
		onEditorReady?: (editor: Editor) => void;
	}) => {
		const appContext = useAppContext();
		const editor = useEditor(
			{
				extensions: [
					...COMMON_EXTENSIONS,
					getObjectLinkExtension(appContext.searchUploadsAndTables),
				],
				...props.options,
			},
			[],
		);

		useEffect(() => {
			if (props.onEditorReady && editor) {
				props.onEditorReady(editor);
			}
		}, [editor, props.onEditorReady]);

		useEffect(() => {
			if (!editor) return;

			const tiptapContent = markdownToTiptap(props.options.content || "");
			const emitUpdate = true;
			editor.commands.setContent(tiptapContent, emitUpdate);
		}, [editor, props.options.content]);

		return (
			<EditorContent
				editor={editor}
				className={getEditorClassName(props.className)}
			/>
		);
	},
);
