import { Favicon } from "@/components/favicon";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { DialogClose } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { cleanXmlText } from "@/lib/formatting";
import { guessRssUrl, previewRssFeed } from "@api/fastAPI";
import type { PreviewRssFeedResponse } from "@api/schemas";
import { ArrowUpRight, Spinner, Warning } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "sonner";

export const AddFeedDialog = observer(() => {
	const appContext = useAppContext();
	const [url, setUrl] = useState("");
	const [rssUrl, setRssUrl] = useState("");

	const [guessFailed, setGuessFailed] = useState(false);

	const [previewChannelResponse, setPreviewChannelResponse] =
		useState<PreviewRssFeedResponse | null>(null);

	const [loadingPreview, setLoadingPreview] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	return (
		<Dialog
			open={appContext.showAddFeedDialog}
			onOpenChange={(open) => {
				runInAction(() => {
					appContext.showAddFeedDialog = open;
				});
			}}
		>
			<DialogContent>
				<DialogTitle>Add Feed</DialogTitle>
				<DialogDescription className="text-neutral-500">
					Enter the URL of the publication you want to add. We'll try to find
					the associated RSS feed.
				</DialogDescription>

				<Input
					type="text"
					placeholder="Publication URL"
					value={url}
					onChange={(e) => {
						setUrl(e.target.value);
					}}
				/>
				<div>
					<Label
						className={clsx(
							"font-normal",
							guessFailed ? "text-red-500" : "text-neutral-500",
						)}
					>
						{guessFailed
							? "We couldn't find an RSS endpoint. Please enter it directly."
							: "Alternatively, enter the RSS URL directly."}
					</Label>
					<Input
						type="text"
						placeholder="RSS URL"
						value={rssUrl}
						onChange={(e) => {
							setRssUrl(e.target.value);
						}}
					/>
				</div>

				<Button
					disabled={loadingPreview}
					variant="default"
					onClick={() => {
						setLoadingPreview(true);

						if (rssUrl) {
							previewRssFeed({ rss_url: rssUrl, url })
								.then((res) => {
									setPreviewChannelResponse(res.data);
									setLoadingPreview(false);
								})
								.catch(() => {
									toast.error("Could not retrieve RSS feed");
									setLoadingPreview(false);
								});
						} else {
							if (!url) {
								toast.error("Please enter at least one URL");
								setLoadingPreview(false);
								return;
							}

							guessRssUrl({ url: url })
								.then((urlRes) => {
									previewRssFeed({ rss_url: urlRes.data, url })
										.then((res) => {
											setPreviewChannelResponse(res.data);
											setLoadingPreview(false);
										})
										.catch(() => {
											toast.error("Could not retrieve RSS feed");
											setLoadingPreview(false);
										});
								})
								.catch(() => {
									toast.error("Failed to guess RSS URL");
									setLoadingPreview(false);
									setGuessFailed(true);
								});
						}
					}}
					className="flex items-center gap-1"
				>
					{loadingPreview ? (
						<>
							<Spinner className="animate-spin" />
							Loading...
						</>
					) : (
						"Preview feed"
					)}
				</Button>

				{previewChannelResponse && (
					<div
						className={clsx(
							"mt-4 min-w-0 truncate",
							loadingPreview && "animate-pulse",
						)}
					>
						{previewChannelResponse.existing_feeds.length > 0 && (
							<Alert variant="warning">
								<Warning weight="bold" className="shrink-0 text-xl" />
								<AlertTitle>Feed already added</AlertTitle>
								<AlertDescription>
									This channel is already indexed in your feeds.
								</AlertDescription>
							</Alert>
						)}
						<div className="mt-4 flex min-w-0 flex-col overflow-hidden truncate border text-sm">
							<div className="flex items-center gap-2 border-b p-2">
								{previewChannelResponse.feed_preview.feed_channel_link ? (
									<Favicon
										url={previewChannelResponse.feed_preview.feed_channel_link}
										alt={cleanXmlText(
											previewChannelResponse.feed_preview.feed_channel_title,
										)}
										className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div className="w-full min-w-0 truncate">
									<h2 className="font-semibold">
										{cleanXmlText(
											previewChannelResponse.feed_preview.feed_channel_title,
										)}
									</h2>
									<h3 className="w-full min-w-0 truncate text-neutral-500">
										{cleanXmlText(
											previewChannelResponse.feed_preview
												.feed_channel_subtitle ?? "No description",
										)}
									</h3>
								</div>
							</div>
							{previewChannelResponse.feed_preview.items ? (
								<div className="flex max-h-64 w-full flex-col gap-0.5 overflow-y-scroll p-2">
									{previewChannelResponse.feed_preview.items.map((item) => (
										<div key={item.feed_item_guid} className="p-1">
											<h4 className="flex min-w-0 items-center gap-1 truncate font-semibold">
												<span className="min-w-0 truncate">
													{cleanXmlText(item.feed_item_title ?? "")}
												</span>
												<a
													href={item.feed_item_link}
													target="_blank"
													rel="noreferrer"
													className="shrink-0 text-neutral-600 hover:text-neutral-900"
												>
													<ArrowUpRight weight="bold" />
												</a>
											</h4>
											<p className="min-w-0 truncate text-neutral-500">
												{cleanXmlText(item.feed_item_description ?? "")}
											</p>
										</div>
									))}
								</div>
							) : (
								<div>No items</div>
							)}
						</div>
					</div>
				)}
				{previewChannelResponse && (
					<div className="flex justify-end gap-2">
						<Button
							variant="ghost"
							onClick={() => {
								setUrl("");
								setRssUrl("");
								setPreviewChannelResponse(null);
							}}
						>
							Reset
						</Button>

						<DialogClose asChild>
							<Button
								className="w-full"
								disabled={loadingPreview || submitLoading}
								onClick={() => {
									setSubmitLoading(true);
									appContext
										.addFeedChannel({
											feedChannelPreview: previewChannelResponse.feed_preview,
										})
										.then(({ remoteResult }) => {
											if (remoteResult) {
												toast.success(
													`${previewChannelResponse.feed_preview.feed_channel_title} added to feeds`,
												);
												// Reset the form
												setUrl("");
												setRssUrl("");
												setPreviewChannelResponse(null);
											}
										})
										.finally(() => {
											setSubmitLoading(false);
										});
								}}
							>
								Add feed
							</Button>
						</DialogClose>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
});
