import { TreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import type {
	AnyDirectoryNode,
	DirectoryNode,
} from "@/contexts/app-context/tree-handlers";
import { makeAutoObservable } from "mobx";

/**
 * Represents the state of a folder tab in the application.
 * Manages the tree structure and navigation for folder contents.
 */
export class FolderState {
	// Discriminator for identifying the tab type
	type = "folder" as const;
	// Reference to the global application state
	appState: AppState;

	// Manages the hierarchical tree structure of the folder contents
	treeState: TreeState;
	// Current folder node being displayed. Null represents the root folder
	folderNode: DirectoryNode<"folder"> | null = null;

	/**
	 * @param appState - Global application state
	 * @param handleNodeNavigation - Function to handle navigation to a node
	 * @param folderNode - The folder node to display. Null for root folder
	 */
	constructor(props: {
		appState: AppState;
		handleNodeNavigation: (this: FolderState, node: AnyDirectoryNode) => void;
		folderNode: DirectoryNode<"folder"> | null;
		isReadonly: boolean;
		isMultiSelect: boolean;
	}) {
		this.appState = props.appState;
		this.folderNode = props.folderNode;
		// Initialize tree state with a data accessor and disable drag-and-drop
		this.treeState = new TreeState({
			appState: props.appState,
			getData: () => this.visibleNodes,
			handleNodeNavigation: props.handleNodeNavigation,
			isDndEnabled: props.isReadonly,
			isMultiSelect: props.isMultiSelect,
		});
		makeAutoObservable(this);
	}

	/**
	 * Returns the children nodes of the current folder.
	 * If at root level, returns all root nodes from the file tree.
	 */
	get visibleNodes(): AnyDirectoryNode[] {
		if (!this.folderNode) {
			return this.appState.fileNodeTree.rootNodes;
		}
		return this.folderNode.children;
	}

	/**
	 * Returns either a synthetic root node object or the current folder node.
	 * The synthetic root node has a special structure for representing the root folder.
	 */
	get node():
		| {
				id: null;
				ancestors: [];
				children: AnyDirectoryNode[];
				path: "/folder";
		  }
		| DirectoryNode<"folder"> {
		if (this.folderNode === null) {
			return {
				id: null,
				ancestors: [],
				children: this.appState.fileNodeTree.rootNodes,
				path: "/folder",
			};
		}
		return this.folderNode;
	}
}
