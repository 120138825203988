import { useAppContext } from "@/contexts/app-context/use-app-context";
import { MagnifyingGlass } from "@phosphor-icons/react";

export const TopBarSearch = () => {
	const appContext = useAppContext();

	return (
		<button
			type="button"
			onClick={() => {
				appContext.setCmdKOpen(true);
			}}
			className="flex w-full"
		>
			<div className="flex h-8 w-full items-center gap-2 border border-neutral-200 bg-white px-2 py-1">
				<MagnifyingGlass className="h-4 w-4 text-neutral-500" />
				<span className="text-neutral-500 text-sm">Search</span>
			</div>
		</button>
	);
};
