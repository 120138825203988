import type { CellProps } from "@/components/table/cell/cell-props";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const DatetimeCell = observer(
	({ recordId, field, value }: CellProps<"datetime">) => {
		const tableViewState = useTableViewContext();
		const [open, setOpen] = useState(false);

		// TODO(John): fix casts!
		const [newValue, setNewValue] = useState(
			value ? dayjs.tz(value as string, "UTC") : null,
		);
		useEffect(() => {
			setNewValue(value ? dayjs.tz(value as string, "UTC") : null);
		}, [value]);

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen && newValue) {
						if (dayjs(value as string).isSame(newValue)) return;
						tableViewState.tableState.updateCellValues({
							recordId,
							fieldId: field.field_id,
							cellValue: newValue ? newValue.utc().toISOString() : null,
						});
					}
				}}
			>
				<PopoverTrigger
					disabled={!tableViewState.editable}
					className={clsx(
						"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{newValue ? newValue.local().format("YYYY-MM-DD HH:mm:ss") : null}
				</PopoverTrigger>
				<PopoverContent className="w-72 p-2">
					<Calendar
						selectedDate={newValue}
						setSelectedDate={setNewValue}
						showTime
					/>
					<Button
						onClick={() => {
							tableViewState.tableState.updateCellValues({
								recordId,
								fieldId: field.field_id,
								cellValue: null,
							});
						}}
						variant="ghost"
						className="mt-1 flex w-full items-center justify-start gap-1"
					>
						<X className="" />
						Clear date
					</Button>
				</PopoverContent>
			</Popover>
		);
	},
);
