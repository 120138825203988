import {
	ThreadViewer,
	Threads,
} from "@/components/layout/right-sidebar/messages";
import { pathObjectToString } from "@/lib/paths";
import type { MessageId } from "@api/schemas";
import { createMemoryRouter } from "react-router-dom";

export const createMessageRouter = (initialMessageId: MessageId | null) => {
	return createMemoryRouter(
		[
			{ path: "/message", element: <Threads /> },
			{ path: "/message/:messageId", element: <ThreadViewer /> },
		],
		{
			initialEntries: [
				pathObjectToString({
					path: "message",
					message_id: initialMessageId,
				}),
			],
		},
	);
};
