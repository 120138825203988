import type { CellProps } from "@/components/table/cell/cell-props";
import { Checkbox } from "@/components/ui/checkbox";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import { observer } from "mobx-react-lite";

export const BooleanCell = observer(
	({ recordId, field, value }: CellProps<"boolean">) => {
		const tableViewState = useTableViewContext();

		return (
			<Checkbox
				className="ml-2 flex items-center"
				checked={!!value}
				disabled={!tableViewState.editable}
				onCheckedChange={(checked) => {
					tableViewState.tableState.updateCellValues({
						recordId,
						fieldId: field.field_id,
						cellValue: checked === true,
					});
				}}
			/>
		);
	},
);
