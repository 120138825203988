import { FolderTree } from "@/components/tree/tree";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { FolderSimple, House } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { RouterProvider } from "react-router-dom";

export const FileSelector = observer(() => {
	const appState = useAppContext();
	return (
		<Dialog
			open={appState.fileSelectorState.showFileSelector}
			onOpenChange={(open) => {
				runInAction(() => {
					if (!open) {
						appState.fileSelectorState.executeCancel();
					}
				});
			}}
		>
			<DialogContent className="flex h-full max-h-[32rem] flex-col">
				<DialogHeader>
					<DialogTitle>Select a file</DialogTitle>
				</DialogHeader>
				<RouterProvider router={appState.fileSelectorState.router} />
			</DialogContent>
		</Dialog>
	);
});

export const FileSelectorView = observer(() => {
	const appState = useAppContext();

	return (
		<div className="flex h-full min-h-0 grow flex-col justify-between">
			<div>
				<Breadcrumb>
					<BreadcrumbList className="p-4">
						<BreadcrumbItem key="root">
							<BreadcrumbLink asChild>
								<button
									type="button"
									onClick={() => {
										appState.fileSelectorState.navigate({
											path: "folder",
											file_id: null,
										});
									}}
									className="flex items-center gap-1"
								>
									<House weight="duotone" />
									Home
								</button>
							</BreadcrumbLink>
						</BreadcrumbItem>
						{appState.fileSelectorState.folderState.node.ancestors.map(
							(node) => (
								<Fragment key={node.id}>
									<BreadcrumbSeparator />
									<BreadcrumbItem>
										<BreadcrumbLink asChild>
											<button
												className="flex items-center gap-1 text-neutral-600"
												type="button"
												onClick={() => {
													appState.fileSelectorState.navigate(node.pathObject);
												}}
											>
												<FolderSimple weight="duotone" />
												{node.file.file_name}
											</button>
										</BreadcrumbLink>
									</BreadcrumbItem>
								</Fragment>
							),
						)}
					</BreadcrumbList>
				</Breadcrumb>
			</div>
			<div className="max-w-96 px-2 pt-2">
				<Input
					placeholder="Search uploads..."
					value={appState.fileSelectorState.folderState.treeState.searchQuery}
					onChange={(e) =>
						appState.fileSelectorState.folderState.treeState.setSearchQuery(
							e.target.value,
						)
					}
				/>
			</div>
			<div className="min-h-0 grow overflow-y-auto">
				<FolderTree
					treeState={appState.fileSelectorState.folderState.treeState}
				/>
			</div>
			<div className="flex justify-end p-4">
				<Button
					onClick={() => {
						appState.fileSelectorState.executeSelect();
					}}
				>
					Select
				</Button>
			</div>
		</div>
	);
});
