import { SelectOptionColor } from "@api/schemas";

export const chooseRandomSelectOptionColor = () => {
	const colors = Object.values(SelectOptionColor);
	return colors[Math.floor(Math.random() * colors.length)];
};

export const SelectOptionBackgroundColors: {
	[key in SelectOptionColor]: string;
} = {
	[SelectOptionColor.gray]: "bg-gray-100",
	[SelectOptionColor.red]: "bg-red-100",
	[SelectOptionColor.orange]: "bg-orange-100",
	[SelectOptionColor.amber]: "bg-amber-100",
	[SelectOptionColor.yellow]: "bg-yellow-100",
	[SelectOptionColor.lime]: "bg-lime-100",
	[SelectOptionColor.green]: "bg-green-100",
	[SelectOptionColor.emerald]: "bg-emerald-100",
	[SelectOptionColor.teal]: "bg-teal-100",
	[SelectOptionColor.cyan]: "bg-cyan-100",
	[SelectOptionColor.sky]: "bg-sky-100",
	[SelectOptionColor.blue]: "bg-blue-100",
	[SelectOptionColor.indigo]: "bg-indigo-100",
	[SelectOptionColor.violet]: "bg-violet-100",
	[SelectOptionColor.purple]: "bg-purple-100",
	[SelectOptionColor.fuchsia]: "bg-fuchsia-100",
	[SelectOptionColor.pink]: "bg-pink-100",
	[SelectOptionColor.rose]: "bg-rose-100",
};

export const SelectOptionBorderColors: {
	[key in SelectOptionColor]: string;
} = {
	[SelectOptionColor.gray]: "border-gray-200",
	[SelectOptionColor.red]: "border-red-200",
	[SelectOptionColor.orange]: "border-orange-200",
	[SelectOptionColor.amber]: "border-amber-200",
	[SelectOptionColor.yellow]: "border-yellow-200",
	[SelectOptionColor.lime]: "border-lime-200",
	[SelectOptionColor.green]: "border-green-200",
	[SelectOptionColor.emerald]: "border-emerald-200",
	[SelectOptionColor.teal]: "border-teal-200",
	[SelectOptionColor.cyan]: "border-cyan-200",
	[SelectOptionColor.sky]: "border-sky-200",
	[SelectOptionColor.blue]: "border-blue-200",
	[SelectOptionColor.indigo]: "border-indigo-200",
	[SelectOptionColor.violet]: "border-violet-200",
	[SelectOptionColor.purple]: "border-purple-200",
	[SelectOptionColor.fuchsia]: "border-fuchsia-200",
	[SelectOptionColor.pink]: "border-pink-200",
	[SelectOptionColor.rose]: "border-rose-200",
};
