/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddLookupFieldRequest,
  AddPrimitiveFieldRequest,
  AddRelationshipFieldRequest,
  AddSelectOptionRequest,
  BaseSearchResponse,
  BodyCreateUploadRoute,
  BootstrapSessionResponse,
  CreateFolderRequest,
  CreateTableRequest,
  CrossrefResponse,
  DeleteFieldRequest,
  DeleteFilesRequest,
  DeleteRecordsRequest,
  DeleteSelectOptionRequest,
  FeedItem,
  File,
  GetFeedItemParams,
  GetTableRecordsResponse,
  GetTableRecordsRouteParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  InsertEmptyRecordRequest,
  MessageWithMetadata,
  MoveDirectoryNodesRequest,
  PageResolution,
  PathObject,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  RenameFileRequest,
  RunViewQueryRequest,
  RunViewQueryResponse,
  SearchChunksResponse,
  SearchRequest,
  SyncUserEventRequest,
  SyncUserFillTableRequest,
  TableUpdate,
  UpdateCellsRequest,
  UpdateFieldRequest,
  UpdateRecordOrdersRequest,
  UpdateSelectOptionColorRequest,
  UpdateSelectOptionLabelRequest,
  UpdateUploadMetadataRequest,
  Upload,
  UploadId,
  WebSearchRequest,
  WebSearchResponse,
  WorkspaceUpdate
} from './schemas'




/**
 * @summary Search Library Route
 */
export const searchLibraryRoute = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchChunksResponse>> => {
    
    return axios.default.post(
      `/search/search_uploads`,
      searchRequest,options
    );
  }



export const getSearchLibraryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchLibraryRoute>>, TError,{data: SearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchLibraryRoute>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchLibraryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchLibraryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof searchLibraryRoute>>>
    export type SearchLibraryRouteMutationBody = SearchRequest
    export type SearchLibraryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Library Route
 */
export const useSearchLibraryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchLibraryRoute>>,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchLibraryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Route
 */
export const searchRoute = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BaseSearchResponse>> => {
    
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchRoute>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof searchRoute>>>
    export type SearchRouteMutationBody = SearchRequest
    export type SearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Route
 */
export const useSearchRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchRoute>>,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Download Pdf
 */
export const downloadPdf = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }


export const getDownloadPdfQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/pdf/${uploadId}`] as const;
    }

    
export const getDownloadPdfQueryOptions = <TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadPdfQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadPdf>>> = ({ signal }) => downloadPdf(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type DownloadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof downloadPdf>>>
export type DownloadPdfQueryError = AxiosError<HTTPValidationError>


export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Download Pdf
 */

export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getDownloadPdfQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }


export const getDownloadOriginalQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/original/${uploadId}`] as const;
    }

    
export const getDownloadOriginalQueryOptions = <TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadOriginalQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadOriginal>>> = ({ signal }) => downloadOriginal(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>


export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Download Original
 */

export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getDownloadOriginalQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Page Image Route
 */
export const getPageImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetPageImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPageImageRouteQueryKey(uploadId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPageImageRoute>>> = ({ signal }) => getPageImageRoute(uploadId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getPageImageRoute>>>
export type GetPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Page Image Route
 */

export function useGetPageImageRoute<TData = Awaited<ReturnType<typeof getPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetPageImageRouteQueryOptions(uploadId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Upload Metadata Route
 */
export const updateUploadMetadataRoute = (
    updateUploadMetadataRequest: UpdateUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadataRequest,options
    );
  }



export const getUpdateUploadMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, {data: UpdateUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateUploadMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUploadMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadataRoute>>>
    export type UpdateUploadMetadataRouteMutationBody = UpdateUploadMetadataRequest
    export type UpdateUploadMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Upload Metadata Route
 */
export const useUpdateUploadMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUploadMetadataRoute>>,
        TError,
        {data: UpdateUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUploadMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Cover Image Route
 */
export const getCoverImageRoute = (
    uploadId: UploadId,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${resolution}.jpg`,options
    );
  }


export const getGetCoverImageRouteQueryKey = (uploadId: UploadId,
    resolution: PageResolution,) => {
    return [`/uploads/cover_image/${uploadId}/${resolution}.jpg`] as const;
    }

    
export const getGetCoverImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoverImageRouteQueryKey(uploadId,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoverImageRoute>>> = ({ signal }) => getCoverImageRoute(uploadId,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetCoverImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getCoverImageRoute>>>
export type GetCoverImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Cover Image Route
 */

export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCoverImageRouteQueryOptions(uploadId,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Upload Route
 */
export const createUploadRoute = (
    bodyCreateUploadRoute: BodyCreateUploadRoute, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UploadId>> => {const formData = new FormData();
formData.append('file', bodyCreateUploadRoute.file)
formData.append('upload_id', bodyCreateUploadRoute.upload_id)
formData.append('infer_metadata', bodyCreateUploadRoute.infer_metadata.toString())
if(bodyCreateUploadRoute.file_parent_id !== undefined && bodyCreateUploadRoute.file_parent_id !== null) {
 formData.append('file_parent_id', bodyCreateUploadRoute.file_parent_id)
 }

    
    return axios.default.post(
      `/uploads/create`,
      formData,options
    );
  }



export const getCreateUploadRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadRoute>>, {data: BodyCreateUploadRoute}> = (props) => {
          const {data} = props ?? {};

          return  createUploadRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUploadRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadRoute>>>
    export type CreateUploadRouteMutationBody = BodyCreateUploadRoute
    export type CreateUploadRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Upload Route
 */
export const useCreateUploadRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createUploadRoute>>,
        TError,
        {data: BodyCreateUploadRoute},
        TContext
      > => {

      const mutationOptions = getCreateUploadRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryCrossref>>, {data: QueryCrossrefRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryCrossref(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
    export type QueryCrossrefMutationBody = QueryCrossrefRequest
    export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryCrossref>>,
        TError,
        {data: QueryCrossrefRequest},
        TContext
      > => {

      const mutationOptions = getQueryCrossrefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryGoogleBooks>>, {data: QueryGoogleBooksRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryGoogleBooks(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
    export type QueryGoogleBooksMutationBody = QueryGoogleBooksRequest
    export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryGoogleBooks>>,
        TError,
        {data: QueryGoogleBooksRequest},
        TContext
      > => {

      const mutationOptions = getQueryGoogleBooksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferUploadMetadata>>, {data: InferUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
    export type InferUploadMetadataMutationBody = InferUploadMetadataRequest
    export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof inferUploadMetadata>>,
        TError,
        {data: InferUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getInferUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Load a full upload, including the table of contents.
 * @summary Get Upload
 */
export const getUpload = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Upload>> => {
    
    return axios.default.get(
      `/uploads/${uploadId}`,options
    );
  }


export const getGetUploadQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/${uploadId}`] as const;
    }

    
export const getGetUploadQueryOptions = <TData = Awaited<ReturnType<typeof getUpload>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUpload>>> = ({ signal }) => getUpload(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetUploadQueryResult = NonNullable<Awaited<ReturnType<typeof getUpload>>>
export type GetUploadQueryError = AxiosError<HTTPValidationError>


export function useGetUpload<TData = Awaited<ReturnType<typeof getUpload>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUpload>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetUpload<TData = Awaited<ReturnType<typeof getUpload>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUpload>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetUpload<TData = Awaited<ReturnType<typeof getUpload>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Upload
 */

export function useGetUpload<TData = Awaited<ReturnType<typeof getUpload>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUpload>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetUploadQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BootstrapSessionResponse>> => {
    
    return axios.default.get(
      `/user/bootstrap_session`,options
    );
  }


export const getBootstrapSessionQueryKey = () => {
    return [`/user/bootstrap_session`] as const;
    }

    
export const getBootstrapSessionQueryOptions = <TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBootstrapSessionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bootstrapSession>>> = ({ signal }) => bootstrapSession({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<unknown>


export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Bootstrap Session
 */

export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getBootstrapSessionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Attempts to locate an RSS feed URL from a given webpage URL by:
- Fetching the html
- Checking for <link> tags with type="application/rss+xml" or "application/atom+xml"

Returns first valid feed URL found or 404s if none found

raises:
    HTTPException(404): if no feed url could be found
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof guessRssUrl>>, {params: GuessRssUrlParams}> = (props) => {
          const {params} = props ?? {};

          return  guessRssUrl(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
    
    export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof guessRssUrl>>,
        TError,
        {params: GuessRssUrlParams},
        TContext
      > => {

      const mutationOptions = getGuessRssUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Fetches and parses an RSS feed to preview its content and check for duplicates.

args:
    rss_url: direct url to rss feed
    url: original webpage url
    auth: auth context for user permissions

returns:
    PreviewRssFeedResponse containing:
    - parsed feed preview with metadata and recent items
    - list of any existing feeds with same rss_url
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewRssFeed>>, {params: PreviewRssFeedParams}> = (props) => {
          const {params} = props ?? {};

          return  previewRssFeed(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
    
    export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewRssFeed>>,
        TError,
        {params: PreviewRssFeedParams},
        TContext
      > => {

      const mutationOptions = getPreviewRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Initiates async indexing of a new RSS feed.

Two-phase process:
1. synchronously:
    - creates feed channel record in postgres
    - broadcasts feed channel creation to clients

2. asynchronously in background:
    - extracts and processes feed items
    - generates embeddings
    - indexes into vespa
    - broadcasts item status updates

args:
    req: feed channel and items to index
    auth: auth context for creator_id
    background_tasks: FastAPI background task manager

returns:
    bool: True if feed channel was created successfully

side effects:
    - creates Postgres feed channel record
    - broadcasts channel creation
    - starts background indexing task

Note: Returns before indexing is complete. Clients should watch
for websocket updates to track indexing progress.
 * @summary Index Rss Feed
 */
export const indexRssFeed = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.default.post(
      `/feeds/index_rss_feed`,
      indexFeedRequest,options
    );
  }



export const getIndexRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexRssFeed>>, {data: IndexFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexRssFeed(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IndexRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof indexRssFeed>>>
    export type IndexRssFeedMutationBody = IndexFeedRequest
    export type IndexRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Rss Feed
 */
export const useIndexRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof indexRssFeed>>,
        TError,
        {data: IndexFeedRequest},
        TContext
      > => {

      const mutationOptions = getIndexRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refetchFeedChannel>>, {params: RefetchFeedChannelParams}> = (props) => {
          const {params} = props ?? {};

          return  refetchFeedChannel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
    
    export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof refetchFeedChannel>>,
        TError,
        {params: RefetchFeedChannelParams},
        TContext
      > => {

      const mutationOptions = getRefetchFeedChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retrieve a full feed item, including its content, by ID
 * @summary Get Feed Item
 */
export const getFeedItem = (
    params: GetFeedItemParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedItem>> => {
    
    return axios.default.get(
      `/feeds/feed_item`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetFeedItemQueryKey = (params: GetFeedItemParams,) => {
    return [`/feeds/feed_item`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFeedItemQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItem>>> = ({ signal }) => getFeedItem(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetFeedItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItem>>>
export type GetFeedItemQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Feed Item
 */

export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFeedItemQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Syncs a client execution of a message action.
 * @summary Sync User Event Route
 */
export const syncUserEventRoute = (
    syncUserEventRequest: SyncUserEventRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    return axios.default.post(
      `/sessions/sync_user_event`,
      syncUserEventRequest,options
    );
  }



export const getSyncUserEventRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncUserEventRoute>>, {data: SyncUserEventRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncUserEventRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncUserEventRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncUserEventRoute>>>
    export type SyncUserEventRouteMutationBody = SyncUserEventRequest
    export type SyncUserEventRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync User Event Route
 */
export const useSyncUserEventRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUserEventRoute>>, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncUserEventRoute>>,
        TError,
        {data: SyncUserEventRequest},
        TContext
      > => {

      const mutationOptions = getSyncUserEventRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This route has a special case for message events that indicate a table fill.
Perhaps we could detect it somehow though.
 * @summary Sync Fill Table Action Route
 */
export const syncFillTableActionRoute = (
    syncUserFillTableRequest: SyncUserFillTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    return axios.default.post(
      `/sessions/sync_fill_table_request`,
      syncUserFillTableRequest,options
    );
  }



export const getSyncFillTableActionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncFillTableActionRoute>>, {data: SyncUserFillTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncFillTableActionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncFillTableActionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncFillTableActionRoute>>>
    export type SyncFillTableActionRouteMutationBody = SyncUserFillTableRequest
    export type SyncFillTableActionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Fill Table Action Route
 */
export const useSyncFillTableActionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncFillTableActionRoute>>,
        TError,
        {data: SyncUserFillTableRequest},
        TContext
      > => {

      const mutationOptions = getSyncFillTableActionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Table Update
 */
export const dummyTableUpdate = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableUpdate>> => {
    
    return axios.default.post(
      `/dummy/table_update`,undefined,options
    );
  }



export const getDummyTableUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTableUpdate>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyTableUpdate>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyTableUpdate>>, void> = () => {
          

          return  dummyTableUpdate(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyTableUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof dummyTableUpdate>>>
    
    export type DummyTableUpdateMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Table Update
 */
export const useDummyTableUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTableUpdate>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyTableUpdate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyTableUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Workspace Update
 */
export const dummyWorkspaceUpdate = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUpdate>> => {
    
    return axios.default.post(
      `/dummy/workspace_update`,undefined,options
    );
  }



export const getDummyWorkspaceUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, void> = () => {
          

          return  dummyWorkspaceUpdate(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyWorkspaceUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>>
    
    export type DummyWorkspaceUpdateMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Workspace Update
 */
export const useDummyWorkspaceUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyWorkspaceUpdate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyWorkspaceUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Message With Agent Type
 */
export const dummyMessageWithAgentType = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MessageWithMetadata>> => {
    
    return axios.default.post(
      `/dummy/message-with-agent-type`,undefined,options
    );
  }



export const getDummyMessageWithAgentTypeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, void> = () => {
          

          return  dummyMessageWithAgentType(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyMessageWithAgentTypeMutationResult = NonNullable<Awaited<ReturnType<typeof dummyMessageWithAgentType>>>
    
    export type DummyMessageWithAgentTypeMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Message With Agent Type
 */
export const useDummyMessageWithAgentType = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyMessageWithAgentType>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyMessageWithAgentTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy File
 */
export const dummyFile = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<File>> => {
    
    return axios.default.post(
      `/dummy/file`,undefined,options
    );
  }



export const getDummyFileMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyFile>>, void> = () => {
          

          return  dummyFile(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyFileMutationResult = NonNullable<Awaited<ReturnType<typeof dummyFile>>>
    
    export type DummyFileMutationError = AxiosError<unknown>

    /**
 * @summary Dummy File
 */
export const useDummyFile = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyFile>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyFile>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Path Object
 */
export const dummyPathObject = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PathObject>> => {
    
    return axios.default.post(
      `/dummy/path-object`,undefined,options
    );
  }



export const getDummyPathObjectMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyPathObject>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyPathObject>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyPathObject>>, void> = () => {
          

          return  dummyPathObject(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyPathObjectMutationResult = NonNullable<Awaited<ReturnType<typeof dummyPathObject>>>
    
    export type DummyPathObjectMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Path Object
 */
export const useDummyPathObject = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyPathObject>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyPathObject>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyPathObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Folder
 */
export const createFolder = (
    createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/create_folder`,
      createFolderRequest,options
    );
  }



export const getCreateFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFolder>>, {data: CreateFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  createFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<typeof createFolder>>>
    export type CreateFolderMutationBody = CreateFolderRequest
    export type CreateFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createFolder>>,
        TError,
        {data: CreateFolderRequest},
        TContext
      > => {

      const mutationOptions = getCreateFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Files Route
 */
export const deleteFilesRoute = (
    deleteFilesRequest: DeleteFilesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/delete_files`,
      deleteFilesRequest,options
    );
  }



export const getDeleteFilesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFilesRoute>>, {data: DeleteFilesRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFilesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFilesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFilesRoute>>>
    export type DeleteFilesRouteMutationBody = DeleteFilesRequest
    export type DeleteFilesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Files Route
 */
export const useDeleteFilesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesRoute>>, TError,{data: DeleteFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFilesRoute>>,
        TError,
        {data: DeleteFilesRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFilesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename File Route
 */
export const renameFileRoute = (
    renameFileRequest: RenameFileRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/rename_file`,
      renameFileRequest,options
    );
  }



export const getRenameFileRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameFileRoute>>, {data: RenameFileRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameFileRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameFileRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameFileRoute>>>
    export type RenameFileRouteMutationBody = RenameFileRequest
    export type RenameFileRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename File Route
 */
export const useRenameFileRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFileRoute>>, TError,{data: RenameFileRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameFileRoute>>,
        TError,
        {data: RenameFileRequest},
        TContext
      > => {

      const mutationOptions = getRenameFileRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Move Directory Nodes
 */
export const moveDirectoryNodes = (
    moveDirectoryNodesRequest: MoveDirectoryNodesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/files/move_directory_nodes`,
      moveDirectoryNodesRequest,options
    );
  }



export const getMoveDirectoryNodesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveDirectoryNodes>>, {data: MoveDirectoryNodesRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveDirectoryNodes(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveDirectoryNodesMutationResult = NonNullable<Awaited<ReturnType<typeof moveDirectoryNodes>>>
    export type MoveDirectoryNodesMutationBody = MoveDirectoryNodesRequest
    export type MoveDirectoryNodesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Directory Nodes
 */
export const useMoveDirectoryNodes = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveDirectoryNodes>>,
        TError,
        {data: MoveDirectoryNodesRequest},
        TContext
      > => {

      const mutationOptions = getMoveDirectoryNodesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Web Search Route
 */
export const webSearchRoute = (
    webSearchRequest: WebSearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WebSearchResponse>> => {
    
    return axios.default.post(
      `/web_search`,
      webSearchRequest,options
    );
  }



export const getWebSearchRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof webSearchRoute>>, {data: WebSearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  webSearchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WebSearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof webSearchRoute>>>
    export type WebSearchRouteMutationBody = WebSearchRequest
    export type WebSearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Web Search Route
 */
export const useWebSearchRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof webSearchRoute>>,
        TError,
        {data: WebSearchRequest},
        TContext
      > => {

      const mutationOptions = getWebSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Table Route
 */
export const createTableRoute = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/create`,
      createTableRequest,options
    );
  }



export const getCreateTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTableRoute>>, {data: CreateTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createTableRoute>>>
    export type CreateTableRouteMutationBody = CreateTableRequest
    export type CreateTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Table Route
 */
export const useCreateTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createTableRoute>>,
        TError,
        {data: CreateTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Table Records Route
 */
export const getTableRecordsRoute = (
    params: GetTableRecordsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableRecordsResponse>> => {
    
    return axios.default.get(
      `/tables/get_records`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTableRecordsRouteQueryKey = (params: GetTableRecordsRouteParams,) => {
    return [`/tables/get_records`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTableRecordsRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableRecordsRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableRecordsRoute>>> = ({ signal }) => getTableRecordsRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetTableRecordsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTableRecordsRoute>>>
export type GetTableRecordsRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Table Records Route
 */

export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTableRecordsRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add Primitive Field Route
 */
export const addPrimitiveFieldRoute = (
    addPrimitiveFieldRequest: AddPrimitiveFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/add_primitive`,
      addPrimitiveFieldRequest,options
    );
  }



export const getAddPrimitiveFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPrimitiveFieldRoute>>, TError,{data: AddPrimitiveFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addPrimitiveFieldRoute>>, TError,{data: AddPrimitiveFieldRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPrimitiveFieldRoute>>, {data: AddPrimitiveFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  addPrimitiveFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddPrimitiveFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addPrimitiveFieldRoute>>>
    export type AddPrimitiveFieldRouteMutationBody = AddPrimitiveFieldRequest
    export type AddPrimitiveFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Primitive Field Route
 */
export const useAddPrimitiveFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPrimitiveFieldRoute>>, TError,{data: AddPrimitiveFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addPrimitiveFieldRoute>>,
        TError,
        {data: AddPrimitiveFieldRequest},
        TContext
      > => {

      const mutationOptions = getAddPrimitiveFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Relationship Field Route
 */
export const addRelationshipFieldRoute = (
    addRelationshipFieldRequest: AddRelationshipFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/add_relationship`,
      addRelationshipFieldRequest,options
    );
  }



export const getAddRelationshipFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRelationshipFieldRoute>>, TError,{data: AddRelationshipFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addRelationshipFieldRoute>>, TError,{data: AddRelationshipFieldRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRelationshipFieldRoute>>, {data: AddRelationshipFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  addRelationshipFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddRelationshipFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addRelationshipFieldRoute>>>
    export type AddRelationshipFieldRouteMutationBody = AddRelationshipFieldRequest
    export type AddRelationshipFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Relationship Field Route
 */
export const useAddRelationshipFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRelationshipFieldRoute>>, TError,{data: AddRelationshipFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addRelationshipFieldRoute>>,
        TError,
        {data: AddRelationshipFieldRequest},
        TContext
      > => {

      const mutationOptions = getAddRelationshipFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Lookup Field Route
 */
export const addLookupFieldRoute = (
    addLookupFieldRequest: AddLookupFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/add_lookup`,
      addLookupFieldRequest,options
    );
  }



export const getAddLookupFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLookupFieldRoute>>, TError,{data: AddLookupFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addLookupFieldRoute>>, TError,{data: AddLookupFieldRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLookupFieldRoute>>, {data: AddLookupFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  addLookupFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLookupFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addLookupFieldRoute>>>
    export type AddLookupFieldRouteMutationBody = AddLookupFieldRequest
    export type AddLookupFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Lookup Field Route
 */
export const useAddLookupFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLookupFieldRoute>>, TError,{data: AddLookupFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addLookupFieldRoute>>,
        TError,
        {data: AddLookupFieldRequest},
        TContext
      > => {

      const mutationOptions = getAddLookupFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Field Route
 */
export const updateFieldRoute = (
    updateFieldRequest: UpdateFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/update`,
      updateFieldRequest,options
    );
  }



export const getUpdateFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFieldRoute>>, {data: UpdateFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateFieldRoute>>>
    export type UpdateFieldRouteMutationBody = UpdateFieldRequest
    export type UpdateFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Field Route
 */
export const useUpdateFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateFieldRoute>>,
        TError,
        {data: UpdateFieldRequest},
        TContext
      > => {

      const mutationOptions = getUpdateFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Field Route
 */
export const deleteFieldRoute = (
    deleteFieldRequest: DeleteFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/delete`,
      deleteFieldRequest,options
    );
  }



export const getDeleteFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFieldRoute>>, {data: DeleteFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFieldRoute>>>
    export type DeleteFieldRouteMutationBody = DeleteFieldRequest
    export type DeleteFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Field Route
 */
export const useDeleteFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFieldRoute>>,
        TError,
        {data: DeleteFieldRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Select Option Route
 */
export const addSelectOptionRoute = (
    addSelectOptionRequest: AddSelectOptionRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/add_select_option`,
      addSelectOptionRequest,options
    );
  }



export const getAddSelectOptionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSelectOptionRoute>>, TError,{data: AddSelectOptionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addSelectOptionRoute>>, TError,{data: AddSelectOptionRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSelectOptionRoute>>, {data: AddSelectOptionRequest}> = (props) => {
          const {data} = props ?? {};

          return  addSelectOptionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddSelectOptionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addSelectOptionRoute>>>
    export type AddSelectOptionRouteMutationBody = AddSelectOptionRequest
    export type AddSelectOptionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Select Option Route
 */
export const useAddSelectOptionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSelectOptionRoute>>, TError,{data: AddSelectOptionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addSelectOptionRoute>>,
        TError,
        {data: AddSelectOptionRequest},
        TContext
      > => {

      const mutationOptions = getAddSelectOptionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Select Option Route
 */
export const deleteSelectOptionRoute = (
    deleteSelectOptionRequest: DeleteSelectOptionRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/delete_select_option`,
      deleteSelectOptionRequest,options
    );
  }



export const getDeleteSelectOptionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSelectOptionRoute>>, TError,{data: DeleteSelectOptionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteSelectOptionRoute>>, TError,{data: DeleteSelectOptionRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSelectOptionRoute>>, {data: DeleteSelectOptionRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteSelectOptionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSelectOptionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSelectOptionRoute>>>
    export type DeleteSelectOptionRouteMutationBody = DeleteSelectOptionRequest
    export type DeleteSelectOptionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Select Option Route
 */
export const useDeleteSelectOptionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSelectOptionRoute>>, TError,{data: DeleteSelectOptionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteSelectOptionRoute>>,
        TError,
        {data: DeleteSelectOptionRequest},
        TContext
      > => {

      const mutationOptions = getDeleteSelectOptionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Select Option Label Route
 */
export const updateSelectOptionLabelRoute = (
    updateSelectOptionLabelRequest: UpdateSelectOptionLabelRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/update_select_option_label`,
      updateSelectOptionLabelRequest,options
    );
  }



export const getUpdateSelectOptionLabelRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>, TError,{data: UpdateSelectOptionLabelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>, TError,{data: UpdateSelectOptionLabelRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>, {data: UpdateSelectOptionLabelRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateSelectOptionLabelRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSelectOptionLabelRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>>
    export type UpdateSelectOptionLabelRouteMutationBody = UpdateSelectOptionLabelRequest
    export type UpdateSelectOptionLabelRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Select Option Label Route
 */
export const useUpdateSelectOptionLabelRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>, TError,{data: UpdateSelectOptionLabelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateSelectOptionLabelRoute>>,
        TError,
        {data: UpdateSelectOptionLabelRequest},
        TContext
      > => {

      const mutationOptions = getUpdateSelectOptionLabelRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Select Option Color Route
 */
export const updateSelectOptionColorRoute = (
    updateSelectOptionColorRequest: UpdateSelectOptionColorRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/fields/update-select-option-color`,
      updateSelectOptionColorRequest,options
    );
  }



export const getUpdateSelectOptionColorRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionColorRoute>>, TError,{data: UpdateSelectOptionColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionColorRoute>>, TError,{data: UpdateSelectOptionColorRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSelectOptionColorRoute>>, {data: UpdateSelectOptionColorRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateSelectOptionColorRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSelectOptionColorRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateSelectOptionColorRoute>>>
    export type UpdateSelectOptionColorRouteMutationBody = UpdateSelectOptionColorRequest
    export type UpdateSelectOptionColorRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Select Option Color Route
 */
export const useUpdateSelectOptionColorRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectOptionColorRoute>>, TError,{data: UpdateSelectOptionColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateSelectOptionColorRoute>>,
        TError,
        {data: UpdateSelectOptionColorRequest},
        TContext
      > => {

      const mutationOptions = getUpdateSelectOptionColorRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Insert Empty Record Route
 */
export const insertEmptyRecordRoute = (
    insertEmptyRecordRequest: InsertEmptyRecordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/records/insert_empty`,
      insertEmptyRecordRequest,options
    );
  }



export const getInsertEmptyRecordRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordRoute>>, TError,{data: InsertEmptyRecordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordRoute>>, TError,{data: InsertEmptyRecordRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof insertEmptyRecordRoute>>, {data: InsertEmptyRecordRequest}> = (props) => {
          const {data} = props ?? {};

          return  insertEmptyRecordRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InsertEmptyRecordRouteMutationResult = NonNullable<Awaited<ReturnType<typeof insertEmptyRecordRoute>>>
    export type InsertEmptyRecordRouteMutationBody = InsertEmptyRecordRequest
    export type InsertEmptyRecordRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Insert Empty Record Route
 */
export const useInsertEmptyRecordRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordRoute>>, TError,{data: InsertEmptyRecordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof insertEmptyRecordRoute>>,
        TError,
        {data: InsertEmptyRecordRequest},
        TContext
      > => {

      const mutationOptions = getInsertEmptyRecordRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Record Orders Route
 */
export const updateRecordOrdersRoute = (
    updateRecordOrdersRequest: UpdateRecordOrdersRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/records/update_orders`,
      updateRecordOrdersRequest,options
    );
  }



export const getUpdateRecordOrdersRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecordOrdersRoute>>, TError,{data: UpdateRecordOrdersRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateRecordOrdersRoute>>, TError,{data: UpdateRecordOrdersRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRecordOrdersRoute>>, {data: UpdateRecordOrdersRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateRecordOrdersRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateRecordOrdersRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecordOrdersRoute>>>
    export type UpdateRecordOrdersRouteMutationBody = UpdateRecordOrdersRequest
    export type UpdateRecordOrdersRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Record Orders Route
 */
export const useUpdateRecordOrdersRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecordOrdersRoute>>, TError,{data: UpdateRecordOrdersRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateRecordOrdersRoute>>,
        TError,
        {data: UpdateRecordOrdersRequest},
        TContext
      > => {

      const mutationOptions = getUpdateRecordOrdersRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Records Route
 */
export const deleteRecordsRoute = (
    deleteRecordsRequest: DeleteRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/records/delete`,
      deleteRecordsRequest,options
    );
  }



export const getDeleteRecordsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRecordsRoute>>, {data: DeleteRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRecordsRoute>>>
    export type DeleteRecordsRouteMutationBody = DeleteRecordsRequest
    export type DeleteRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Records Route
 */
export const useDeleteRecordsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRecordsRoute>>,
        TError,
        {data: DeleteRecordsRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Cells Route
 */
export const updateCellsRoute = (
    updateCellsRequest: UpdateCellsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/records/update_cells`,
      updateCellsRequest,options
    );
  }



export const getUpdateCellsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellsRoute>>, TError,{data: UpdateCellsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCellsRoute>>, TError,{data: UpdateCellsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCellsRoute>>, {data: UpdateCellsRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateCellsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCellsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateCellsRoute>>>
    export type UpdateCellsRouteMutationBody = UpdateCellsRequest
    export type UpdateCellsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Cells Route
 */
export const useUpdateCellsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellsRoute>>, TError,{data: UpdateCellsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCellsRoute>>,
        TError,
        {data: UpdateCellsRequest},
        TContext
      > => {

      const mutationOptions = getUpdateCellsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Run View Query
 */
export const runViewQuery = (
    runViewQueryRequest: RunViewQueryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RunViewQueryResponse>> => {
    
    return axios.default.post(
      `/tables/computed/run_view_query`,
      runViewQueryRequest,options
    );
  }



export const getRunViewQueryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runViewQuery>>, TError,{data: RunViewQueryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof runViewQuery>>, TError,{data: RunViewQueryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runViewQuery>>, {data: RunViewQueryRequest}> = (props) => {
          const {data} = props ?? {};

          return  runViewQuery(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunViewQueryMutationResult = NonNullable<Awaited<ReturnType<typeof runViewQuery>>>
    export type RunViewQueryMutationBody = RunViewQueryRequest
    export type RunViewQueryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Run View Query
 */
export const useRunViewQuery = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runViewQuery>>, TError,{data: RunViewQueryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof runViewQuery>>,
        TError,
        {data: RunViewQueryRequest},
        TContext
      > => {

      const mutationOptions = getRunViewQueryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.get(
      `/ping`,options
    );
  }


export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>


export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Ping
 */

export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Test Error
 */
export const testError = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.get(
      `/test_error`,options
    );
  }


export const getTestErrorQueryKey = () => {
    return [`/test_error`] as const;
    }

    
export const getTestErrorQueryOptions = <TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTestErrorQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof testError>>> = ({ signal }) => testError({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type TestErrorQueryResult = NonNullable<Awaited<ReturnType<typeof testError>>>
export type TestErrorQueryError = AxiosError<unknown>


export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Test Error
 */

export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getTestErrorQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




