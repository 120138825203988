import { BooleanCell } from "@/components/table/cell/boolean-cell";
import type { CellProps } from "@/components/table/cell/cell-props";
import { DatetimeCell } from "@/components/table/cell/datetime-cell";
import { LookupCell } from "@/components/table/cell/lookup-cell";
import { NumberCell } from "@/components/table/cell/number-cell";
import { RelationshipCell } from "@/components/table/cell/relationship-cell";
import { SelectCell } from "@/components/table/cell/select-cell";
import { TextCellComponent } from "@/components/table/cell/text-cell";
import type { FieldType } from "@api/schemas";

// TODO(John): why are casts required here?
export const Cell = (props: CellProps<FieldType>) => {
	const fieldType = props.field.type;
	switch (fieldType) {
		case "boolean":
			return <BooleanCell {...(props as CellProps<"boolean">)} />;
		case "select":
			return <SelectCell {...(props as CellProps<"select">)} />;
		case "datetime":
			return <DatetimeCell {...(props as CellProps<"datetime">)} />;
		case "number":
			return <NumberCell {...(props as CellProps<"number">)} />;
		case "text":
			return <TextCellComponent {...(props as CellProps<"text">)} />;
		case "relationship":
			return <RelationshipCell {...(props as CellProps<"relationship">)} />;
		case "lookup":
			return <LookupCell {...(props as CellProps<"lookup">)} />;
		default: {
			const exhaustiveCheck: never = fieldType;
			return exhaustiveCheck;
		}
	}
};
