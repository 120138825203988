import { StreamingEditor, ViewOnlyEditor } from "@/components/editor";
import { Skeleton } from "@/components/ui/skeleton";
import type { SearchResultLocal } from "@/contexts/tabs-context/tab-states/search-state";
import { useTab } from "@/contexts/tabs-context/use-tab";
import type {
	UploadId,
	UploadPathObjectHighlightedText,
	UploadPathObjectPageRange,
} from "@api/schemas";
import type { FileResult } from "@api/schemas/fileResult";
import { ArrowDown, ArrowUp, Plus } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useId, useMemo, useState } from "react";

interface SearchAnswerProps {
	synthesis: string | null;
	loadingState: SearchResultLocal["loadingState"];
}

const SearchAnswer = observer(
	({ synthesis, loadingState }: SearchAnswerProps) => {
		const [showMore, setShowMore] = useState(false);

		const editorOptions = useMemo(() => {
			return {
				content: synthesis,
				editable: false,
				editorProps: {},
			};
		}, [synthesis]);

		return (
			<div className="relative flex flex-col">
				<div
					className={clsx(
						"flex min-h-48 w-full shrink-0 flex-col gap-3 overflow-y-hidden bg-neutral-50 p-4",
						showMore ? "h-auto" : "h-48",
					)}
				>
					<div className="flex flex-col gap-1">
						{loadingState === "searching" && (
							<h3 className="text-neutral-500 text-sm">Conducting search...</h3>
						)}
						{loadingState === "synthesizing" && (
							<h3 className="text-neutral-500 text-sm">
								Forming a response...
							</h3>
						)}
						{loadingState === "synthesizing" || loadingState === "completed" ? (
							<StreamingEditor
								className="w-full max-w-full"
								options={editorOptions}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
				<button
					className="h-5 w-full border-neutral-100 border-t bg-neutral-50 text-center text-neutral-500 text-xs"
					type="button"
					onClick={() => setShowMore(!showMore)}
				>
					{showMore ? "Show less" : "Show more"}
				</button>
			</div>
		);
	},
);

const SearchResult = observer(({ result }: { result: FileResult }) => {
	const tab = useTab();
	const tabStore = tab.tabStore;
	const appContext = tabStore.appState;
	const { file_id, file_type } = result;
	const [snippetSortingStrategy, setSnippetSortingStrategy] = useState<
		"order" | "order-desc" | "relevance" | "relevance-desc"
	>("relevance");

	// This assumes
	// - the file_id is an UploadId
	// - the file is an upload
	// - all files are in our workspace

	// TODO(Tae): Some method that goes from file_id + file_type to the file
	const file = appContext.getUploadById(file_id as UploadId);

	// TODO(Tae): Move to mobx
	const sortedSnippets = [...result.snippets].sort((a, b) => {
		if (snippetSortingStrategy === "order") {
			return (
				a.extract_range.start.chunk_index - b.extract_range.start.chunk_index
			);
		}
		if (snippetSortingStrategy === "order-desc") {
			return (
				b.extract_range.start.chunk_index - a.extract_range.start.chunk_index
			);
		}
		if (snippetSortingStrategy === "relevance") {
			return b.score - a.score;
		}
		if (snippetSortingStrategy === "relevance-desc") {
			return a.score - b.score;
		}
		return 0;
	});

	return (
		<div className="flex flex-col gap-2 p-2">
			{/* Header */}
			<div className="flex flex-col gap-2">
				{/* File path */}
				<div className="text-blue-600 text-xs">Path / {file?.file_name}</div>
				<div className="flex gap-2">
					<div className="flex h-12 w-12 shrink-0 items-center justify-center bg-neutral-100 text-neutral-400 text-xs">
						IMG
					</div>
					<div className="flex flex-col gap-1">
						<div className="w-fit rounded-full border border-neutral-200 bg-neutral-50 px-2 py-0.5 text-neutral-700 text-xs">
							{file_type}
						</div>
						{/* This should be a link */}
						<button
							type="button"
							className="text-left text-base text-neutral-950 hover:underline"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								// TODO(Tae): Revisit when type handling is better
								tabStore.createTabInActiveTabSet({
									path: "upload",
									file_id: file_id as UploadId,
									highlighted_text: null,
									page_range: null,
								});
							}}
						>
							<div className="flex items-center gap-1">
								<div className="w-fit rounded-full border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
									{/* Truncate to 2 decimal places */}
									{Math.round(result.score * 100) / 100}
								</div>{" "}
								<h1 className="text-blue-600">{file?.file_name}</h1>
							</div>
						</button>
						<div className="text-neutral-600 text-xs">
							CONSTRUCTURED FILE SUBTITLE, DEPENDS ON FTYPE - Author, Date, etc
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-2">
				{/* Snippets */}
				<div className="flex justify-between border-neutral-200 border-b py-1">
					<h2 className="font-bold text-neutral-500 text-xs">Snippets</h2>
					<div className="flex gap-1">
						<div className="text-neutral-500 text-xs">Sort by:</div>
						<button
							className={clsx(
								"flex items-center text-neutral-500 text-xs",
								snippetSortingStrategy === "order" ||
									snippetSortingStrategy === "order-desc"
									? "text-neutral-950"
									: "text-neutral-500",
							)}
							type="button"
							onClick={() => {
								if (snippetSortingStrategy === "order") {
									setSnippetSortingStrategy("order-desc");
								} else {
									setSnippetSortingStrategy("order");
								}
							}}
						>
							{snippetSortingStrategy === "order" && (
								<ArrowDown className="h-3 w-3" />
							)}
							{snippetSortingStrategy === "order-desc" && (
								<ArrowUp className="h-3 w-3" />
							)}
							Order
						</button>
						<button
							className={clsx(
								"flex items-center text-neutral-500 text-xs",
								snippetSortingStrategy === "relevance" ||
									snippetSortingStrategy === "relevance-desc"
									? "text-neutral-950"
									: "text-neutral-500",
							)}
							type="button"
							onClick={() => {
								if (snippetSortingStrategy === "relevance") {
									setSnippetSortingStrategy("relevance-desc");
								} else {
									setSnippetSortingStrategy("relevance");
								}
							}}
						>
							{snippetSortingStrategy === "relevance" && (
								<ArrowDown className="h-3 w-3" />
							)}
							{snippetSortingStrategy === "relevance-desc" && (
								<ArrowUp className="h-3 w-3" />
							)}
							Relevance
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-4 py-2">
					{sortedSnippets.map((snippet) => {
						const chunk = result.chunks.find(
							(chunk) =>
								chunk.chunk_index === snippet.text_range.start.chunk_index,
						);

						if (!chunk) {
							// TODO(Tae): This should never happen
							return null;
						}

						const highlightedText: UploadPathObjectHighlightedText = [
							snippet.text_range.text,
							snippet.text_range.text,
						];

						const pageRange: UploadPathObjectPageRange = [
							chunk.page_start ?? 0,
							chunk.page_end ?? 0,
						];

						return (
							<div
								// Snippets don't have IDs so we construct one using the file_id and the snippet's start_index
								key={`${result.file_id}-${snippet.extract_range.start.chunk_index}-${snippet.extract_range.start.offset}-${snippet.extract_range.end.chunk_index}-${snippet.extract_range.end.offset}`}
								className="flex flex-col gap-2"
							>
								<div className="flex items-center gap-1 ">
									<div className="w-fit rounded-full border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
										{/* Truncate to 2 decimal places */}
										{Math.round(snippet.score * 100) / 100}
									</div>{" "}
									<button
										type="button"
										className="text-blue-600 text-xs hover:underline"
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											// TODO(Tae): This doesn't actually work. We should be passing in search_ids if anything
											tabStore.createTabInActiveTabSet({
												path: "upload",
												file_id: file_id as UploadId,
												highlighted_text: highlightedText,
												page_range: pageRange,
											});
										}}
									>
										{`${file?.file_name} / Chapter ${"N"} / Page ${
											pageRange[0] ?? 0
										}`}
									</button>
								</div>
								<div className="border-neutral-100 border-l-2 bg-neutral-50 pl-2">
									<ViewOnlyEditor
										content={snippet.text_range.text}
										className="prose-headings:font-medium prose-h1:text-base prose-h2:text-sm prose-h3:text-sm prose-h4:text-sm prose-headings:text-neutral-500 prose-p:text-neutral-500 prose-p:text-xs"
									/>
								</div>
							</div>
						);
					})}
				</div>
				<div className="flex justify-end">
					<button
						className="flex items-center gap-1 text-neutral-500 text-xs underline"
						type="button"
						onClick={() => {}}
					>
						<Plus className="h-4 w-4" />
						Find more snippets
					</button>
				</div>
			</div>
		</div>
	);
});

interface SearchResultsProps {
	searchResult: SearchResultLocal;
}

const SearchResults = observer(({ searchResult }: SearchResultsProps) => {
	const containerId = useId();

	const { searchResults, synthesis } = searchResult;

	let SearchBody = null;
	if (searchResult.loadingState === "searching") {
		SearchBody = (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	} else if (!searchResults || searchResults.length === 0) {
		SearchBody = (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	} else {
		SearchBody = (
			<div className="flex flex-col gap-4 p-4">
				{searchResults.map((result) => {
					return <SearchResult key={result.file_id} result={result} />;
				})}
				<div className="flex justify-end">
					<button
						className="flex items-center gap-2 rounded-full px-3 py-1.5 font-medium text-neutral-600 text-sm transition-colors hover:bg-neutral-50 hover:text-neutral-700"
						type="button"
						onClick={() => {}}
					>
						<Plus className="h-4 w-4" />
						Find more files
					</button>
				</div>
			</div>
		);
		// SearchBody = [...groupedResults].map(([resultId, results]) => {
		// 	if (results[0].type === "feed_items") {
		// 		const feedItem = appContext.workspace?.feedItems.get(
		// 			resultId as FeedItemId,
		// 		);

		// 		if (!feedItem) {
		// 			Sentry.captureMessage(
		// 				"Feed item from search result not found in appContext",
		// 				"error",
		// 			);
		// 			return null;
		// 		}

		// 		return (
		// 			<SearchFeedItemResultGroup
		// 				key={resultId}
		// 				feedItem={feedItem}
		// 				results={results as SearchFeedItemsResult[]}
		// 				containerId={containerId}
		// 			/>
		// 		);
		// 	}
		// 	if (results[0].type === "library") {
		// 		const upload = appContext.getUploadById(resultId as UploadId);
		// 		if (!upload) {
		// 			console.error("Upload from search result not found in appContext");
		// 			Sentry.captureMessage(
		// 				"Upload from search result not found in appContext",
		// 				"error",
		// 			);
		// 			return null;
		// 		}
		// 		return (
		// 			<SearchLibraryResultGroup
		// 				key={resultId}
		// 				upload={upload}
		// 				results={results as SearchLibraryResult[]}
		// 				containerId={containerId}
		// 			/>
		// 		);
		// 	}
		// 	return null;
		// });
	}

	return (
		<div
			// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
			// or when the results change as indicated by result_id
			key={"search-results"}
			className="relative flex grow flex-col gap-2 overflow-y-auto bg-white"
			id={containerId}
		>
			<SearchAnswer
				synthesis={synthesis}
				loadingState={searchResult.loadingState}
			/>
			{SearchBody}
		</div>
	);
});

interface SearchBodyProps {
	searchResult: SearchResultLocal | null;
}

// TODO(John): take a look at how the search result is happening
export const SearchBody = observer(({ searchResult }: SearchBodyProps) => {
	if (searchResult) {
		return <SearchResults searchResult={searchResult} />;
	}

	return null;
});
