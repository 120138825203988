import { TabIconMap } from "@/components/layout/tab-icons";
import type { AppState } from "@/contexts/app-context/app-context";
import type {
	DirectoryNode,
	FileType,
} from "@/contexts/app-context/tree-handlers";
import { createTabRouter } from "@/contexts/tabs-context/tab-routes";
import { FolderState } from "@/contexts/tabs-context/tab-states/folder-state";
import {
	SavedSearchConfig,
	type SearchFormData,
	SearchState,
} from "@/contexts/tabs-context/tab-states/search-state";
import { TableTabState } from "@/contexts/tabs-context/tab-states/table-tab-state";
import {
	type SearchResult,
	UploadState,
} from "@/contexts/tabs-context/tab-states/upload-state";
import {
	SavedWebSearchConfig,
	type WebSearchFormData,
	WebSearchState,
} from "@/contexts/tabs-context/tab-states/web-search-state";
import { TabContext } from "@/contexts/tabs-context/use-tab";
import { pathObjectToString } from "@/lib/paths";
import type {
	FileId,
	SearchId,
	TabPathObject,
	UploadId,
	WebSearchId,
} from "@api/schemas";
import type { Icon } from "@phosphor-icons/react";
import type { RouterNavigateOptions, RouterState } from "@remix-run/router";
import {
	Actions,
	DockLocation,
	type IJsonModel,
	Model,
	TabNode,
	TabSetNode,
} from "flexlayout-react";
import { makeAutoObservable, runInAction } from "mobx";
import type { createMemoryRouter } from "react-router-dom";
import { ulid } from "ulidx";

// Tabs are either a file type, search, manage-tables, or manage-feeds
export type TabType =
	| FileType
	| "search"
	| "manage-tables"
	| "manage-feeds"
	| "web-search";

export type TabTypeStateMap = {
	folder: FolderState;
	upload: UploadState;
	search: SearchState;
	// TODO(John): differentiate between table and table tab state
	// Table State should have only the data
	table: TableTabState;
	// TODO(John): add states for feeds
	"feed-channel": {
		type: "feed-channel";
		feedChannelNode: DirectoryNode<"feed-channel">;
	};
	"feed-item": { type: "feed-item"; feedItemNode: DirectoryNode<"feed-item"> };
	"manage-tables": { type: "manage-tables" };
	"manage-feeds": { type: "manage-feeds" };
	"web-search": WebSearchState;
};

export class Tab {
	tabStore: TabStore;

	id: string;

	router: ReturnType<typeof createMemoryRouter>;
	provider: React.ReactNode;
	routerState: RouterState;

	constructor(props: {
		tabsState: TabStore;
		id: string;
		initialPathObject: TabPathObject;
		initialPathState?: SearchResult;
	}) {
		this.id = props.id;
		this.router = createTabRouter(
			props.initialPathObject,
			props.initialPathState,
		);
		this.tabStore = props.tabsState;
		this.routerState = this.router.state;

		// Initialize a listener for the router that updates the pathname
		this.router.subscribe((state) => {
			runInAction(() => {
				this.routerState = state;
			});
		});

		makeAutoObservable(this);
	}

	get state(): TabTypeStateMap[TabType] | null {
		const match = this.routerState.matches.at(-1);
		if (!match) {
			return null;
		}

		// First, we capture all non-file tabs
		switch (match.pathname) {
			case "/manage-tables":
				return {
					type: "manage-tables",
				};
			case "/manage-feeds":
				return {
					type: "manage-feeds",
				};
			default:
				break;
		}

		if (match.pathname.startsWith("/web-search")) {
			// this.routerState.lcoation.state should be the web search form data
			// we get the config from the web search form data and the id from the path
			const webSearchFormData = this.routerState.location
				.state as WebSearchFormData;
			const webSearchId = match.params.webSearchId as WebSearchId;

			if (!webSearchId || !webSearchFormData) {
				return new WebSearchState(this.tabStore.appState, null);
			}

			const savedWebSearchConfig = new SavedWebSearchConfig(
				webSearchId,
				webSearchFormData.config,
			);
			return new WebSearchState(this.tabStore.appState, savedWebSearchConfig);
		}

		if (match.pathname.startsWith("/search")) {
			// this.routerState.location.state should be the search form data
			// we get the config from the search form data and the id from the path
			const searchFormData = this.routerState.location.state as SearchFormData;
			const searchId = match.params.searchId as SearchId;

			if (!searchId || !searchFormData) {
				return new SearchState(this.tabStore.appState, null);
			}

			const savedSearchConfig = new SavedSearchConfig(
				searchId,
				searchFormData.config,
			);
			return new SearchState(this.tabStore.appState, savedSearchConfig);
		}

		// Now, we handle file tabs
		const fileId = match.params.fileId;

		if (!fileId) {
			return new FolderState({
				appState: this.tabStore.appState,
				handleNodeNavigation: (node) => this.tabStore.navigate(node.pathObject),
				folderNode: null,
				isReadonly: false,
				isMultiSelect: true,
			});
		}

		const fileNode = this.tabStore.appState.fileNodeTree.nodeMap.get(
			fileId as FileId,
		);

		if (!fileNode) {
			console.error("File node not found", fileId);
			return null;
		}

		switch (fileNode.file.file_type) {
			case "folder": {
				return new FolderState({
					appState: this.tabStore.appState,
					handleNodeNavigation: (node) =>
						this.tabStore.navigate(node.pathObject),
					folderNode: fileNode as DirectoryNode<"folder">,
					isReadonly: false,
					isMultiSelect: true,
				});
			}

			case "upload": {
				const searchResult = this.routerState.location.state as SearchResult;
				return new UploadState(
					this.tabStore.appState,
					fileId as UploadId,
					searchResult,
				);
			}

			case "feed-channel": {
				return {
					type: "feed-channel",
					feedChannelNode: fileNode as DirectoryNode<"feed-channel">,
				};
			}

			case "feed-item": {
				return {
					type: "feed-item",
					feedItemNode: fileNode as DirectoryNode<"feed-item">,
				};
			}

			// Pages also return a table node
			case "table": {
				return new TableTabState({
					appState: this.tabStore.appState,
					tableNode: fileNode as DirectoryNode<"table">,
				});
			}

			default: {
				const _exhaustiveCheck: never = fileNode.file;
				throw new Error(`Unhandled file type: ${_exhaustiveCheck}`);
			}
		}
	}

	get display(): {
		icon: Icon;
		label: string;
	} {
		if (!this.state) {
			console.error("No state found for tab", this.id);
			return {
				icon: TabIconMap.folder,
				label: "Files",
			};
		}
		const type = this.state.type;
		let label: string;
		switch (type) {
			case "search": {
				label = "Search";
				break;
			}

			case "folder": {
				const folderName = this.state.folderNode?.file.file_name;
				label = folderName ?? "Files";
				break;
			}

			case "upload": {
				const uploadName = this.state.upload.file_name;
				label = uploadName ?? "Upload";
				break;
			}

			case "feed-channel": {
				const feedChannelName = this.state.feedChannelNode.file.file_name;
				label = feedChannelName ?? "Feed Channel";
				break;
			}

			case "feed-item": {
				const feedItemName = this.state.feedItemNode.file.file_name;
				label = feedItemName ?? "Feed Item";
				break;
			}

			case "table": {
				const tableName = this.state.tableMetadata.file_name;
				label = tableName ?? "Tables";
				break;
			}

			case "manage-tables": {
				label = "Manage Tables";
				break;
			}

			case "manage-feeds": {
				label = "Manage Feeds";
				break;
			}

			case "web-search": {
				label = "Web Search";
				break;
			}

			default: {
				const _exhaustiveCheck: never = type;
				label = _exhaustiveCheck;
			}
		}

		// We handle the special case for pages which are tables under the hood
		// if (type === "table") {
		// 	return {
		// 		icon: this.state.tableNode.file.table_is_page ? FileIcon : Table,
		// 		label,
		// 	};
		// }

		return {
			icon: TabIconMap[type],
			label,
		};
	}

	navigate(pathObject: TabPathObject, opts?: RouterNavigateOptions) {
		const path = pathObjectToString(pathObject);

		this.router.navigate(path, opts);
	}
}

export const TabProvider = ({
	children,
	tab,
}: {
	children: React.ReactNode;
	tab: Tab;
}) => {
	return <TabContext.Provider value={tab}>{children}</TabContext.Provider>;
};

export class TabStore {
	appState: AppState;

	/**
	 * Flex-layout model responsible for managing the hierarchy of tab sets and tabs
	 */
	model: Model;

	/**
	 * Flat map of all tags in the store and their contained state
	 */
	tabs: Map<string, Tab> = new Map();

	constructor(appState: AppState) {
		makeAutoObservable(
			this,
			{},
			{
				autoBind: true,
			},
		);
		this.appState = appState;

		this.tabs = new Map<string, Tab>();
		const initialTabId = this.getNewTabId();

		// Default to the search tab
		this.tabs.set(
			initialTabId,
			new Tab({
				tabsState: this,
				id: initialTabId,
				initialPathObject: {
					path: "search",
					search_id: null,
				},
			}),
		);

		const initialTabSetId = this.getNextTabSetId();

		const initalModelState: IJsonModel = {
			global: {
				tabSetEnableMaximize: false,
				tabSetEnableDeleteWhenEmpty: true,
				tabEnableRename: false,
				tabDragSpeed: 0,
			},
			borders: [],
			layout: {
				type: "row",
				weight: 100,
				children: [
					{
						type: "tabset",
						name: initialTabSetId,
						weight: 50,
						enableDrag: true,
						enableDrop: true,
						active: true,
						children: [
							{
								type: "tab",
								name: initialTabId,
								component: "button",
							},
						],
					},
				],
			},
		};
		this.model = Model.fromJson(initalModelState);
	}

	get activeTab(): Tab | null {
		const activeTabSet = this.model.getActiveTabset();
		if (!activeTabSet) return null;

		const activeTab = activeTabSet.getSelectedNode() as TabNode | undefined;
		if (!activeTab) return null;

		const activeTabId = activeTab.getName();
		const tab = this.tabs.get(activeTabId);
		if (!tab) {
			console.error("Tab not found", activeTabId);
			return null;
		}
		return tab;
	}

	/**
	 * Navigates the current active tab. Creates a new tab if there's no current
	 * active tab.
	 */
	navigate(pathObject: TabPathObject, opts?: RouterNavigateOptions) {
		let activeTab = this.activeTab;
		if (!activeTab) {
			activeTab = this.createTabInActiveTabSet(pathObject);
		}
		activeTab.navigate(pathObject, opts);
	}

	/**
	 * Creates a new tab in the active tab set.
	 */
	createTabInActiveTabSet = (
		initialPathObject: TabPathObject,
		initialPathState?: SearchResult,
	): Tab => {
		const activeTabSet = this.model.getActiveTabset();
		if (!activeTabSet) throw new Error("No active tab set found");

		const activeTabSetId = activeTabSet.getId();

		const newTabId = this.getNewTabId();
		const newTab = new Tab({
			tabsState: this,
			id: newTabId,
			initialPathObject,
			initialPathState,
		});

		this.tabs.set(newTabId, newTab);
		const location = DockLocation.CENTER;
		const index = 0;

		this.model.doAction(
			Actions.addNode(
				{
					type: "tab",
					name: newTabId,
					component: "button",
				},
				activeTabSetId,
				location,
				index,
			),
		);
		return newTab;
	};

	//Actions.ADD_NODE
	addTabToPane = (
		// paneId: string,
		tabId: string,
	) => {
		const tab = new Tab({
			tabsState: this,
			id: tabId,
			initialPathObject: {
				path: "search",
				search_id: null,
			},
		});
		this.tabs.set(tabId, tab);
	};

	// Actions.MOVE_NODE

	// Actions.DELETE_TAB
	removeTab = (tabId: string) => {
		this.tabs.delete(tabId);
	};

	// Actions.DELETE_TABSET
	removeTabsOfTabSetNode = (tabSetId: string) => {
		const tabSet = this.model.getNodeById(tabSetId);
		if (!tabSet || !(tabSet instanceof TabSetNode)) return;
		const tabs = tabSet.getChildren();
		for (const tab of tabs) {
			if (tab instanceof TabNode) {
				this.removeTab(tab.getName());
			}
		}
	};
	// Actions.SELECT_TAB
	// Actions.SET_ACTIVE_TABSET
	// Actions.CLOSE_WINDOW
	// Actions.CREATE_WINDOW
	// Utility Methods
	getNextTabSetId() {
		return `container_${ulid()}`;
	}

	getNewTabId() {
		return `tab_${ulid()}`;
	}
}
