import { Button } from "@/components/ui/button";
import { IS_DEV } from "@/config";
import { ArrowCounterClockwise, WarningDiamond } from "@phosphor-icons/react";
import type { FallbackRender } from "@sentry/react";

export const PageErrorBoundaryFallback: FallbackRender = ({
	error,
	componentStack,
}) => {
	return (
		<div className="flex h-full grow flex-col items-center justify-center overflow-y-scroll px-2 py-8">
			<div className="flex max-h-full min-h-0 min-w-0 max-w-full flex-col items-center text-center">
				<WarningDiamond
					className="mb-8 shrink-0 text-6xl text-amber-500"
					weight="duotone"
				/>
				<h2 className="mb-4 shrink-0 font-semibold text-3xl">
					Oops! Something went wrong
				</h2>
				<div className="mb-6 shrink-0 text-gray-600">
					This error has automatically been reported. Please try refreshing the
					page.
				</div>

				<Button
					onClick={() => window.location.reload()}
					variant="default"
					className="flex shrink-0 items-center gap-2"
				>
					<ArrowCounterClockwise weight="bold" />
					Reload Page
				</Button>

				{IS_DEV && (
					<>
						<h3 className="mt-8 mb-2 font-semibold text-neutral-700">
							Error details (hidden in production):
						</h3>
						<pre className="min-w-0 max-w-full rounded-md bg-gray-100 p-4 text-sm">
							<code className="block overflow-x-auto whitespace-pre">
								{/* @ts-ignore */}
								{error.toString()}
							</code>
						</pre>
						<div className="mt-4 max-h-full min-h-0 min-w-0 max-w-full overflow-y-scroll rounded-md">
							<pre className="rounded-md bg-gray-100 p-2 text-left text-xs">
								<code className="block overflow-x-auto whitespace-pre">
									{componentStack}
								</code>
							</pre>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
