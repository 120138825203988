import { TabProvider } from "@/contexts/tabs-context/tabs-context";
import { useTabStore } from "@/contexts/tabs-context/use-tab-store";
import "@/pages/tabs.css";
import { Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import {
	Actions,
	type BorderNode,
	type ITabRenderValues,
	type ITabSetRenderValues,
	Layout,
	TabNode,
	TabSetNode,
	type TabSetPlaceHolderCallback,
} from "flexlayout-react";
import "flexlayout-react/style/light.css";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { RouterProvider } from "react-router-dom";

interface TabHandleProps {
	tabId: string;
}

const TabLeading = observer(({ tabId }: TabHandleProps) => {
	const tabStore = useTabStore();
	const { tabs } = tabStore;
	const tab = tabs.get(tabId);

	if (!tab) return null;
	const display = tab.display;
	const Icon = display.icon;

	return (
		<div className="flex max-w-48 items-center gap-2 text-neutral-700 text-sm">
			<Icon className="h-4 w-4 shrink-0 text-neutral-700" />
			<span className="truncate">{display.label}</span>
		</div>
	);
});

export const Tabs = observer(() => {
	const layoutRef = useRef<Layout>(null);
	const tabStore = useTabStore();

	const onAddFromTabSetButton = (node: TabSetNode) => {
		const tabId = tabStore.getNewTabId();

		tabStore.addTabToPane(tabId);

		(layoutRef.current as Layout).addTabToTabSet(node.getId(), {
			component: "button",
			name: tabId,
			type: "tab",
		});
		return;
	};

	const onCloseTabSetButton = (node: TabSetNode) => {
		tabStore.model.doAction(Actions.deleteTabset(node.getId()));
		tabStore.removeTabsOfTabSetNode(node.getId());
	};

	const onCloseTabButton = (node: TabNode) => {
		tabStore.model.doAction(Actions.deleteTab(node.getId()));
		tabStore.removeTab(node.getName());
	};

	const onRenderTabSet = (
		node: TabSetNode | BorderNode,
		renderValues: ITabSetRenderValues,
	) => {
		if (node instanceof TabSetNode && node.getChildren().length > 0) {
			renderValues.buttons.push(
				<button
					key={`add-tab-${node.getId()}`}
					type="button"
					onClick={() => {
						// appContext.setCmdKOpen(true);
						onAddFromTabSetButton(node);
					}}
					className="shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<Plus className="h-4 w-4" />
				</button>,
			);

			renderValues.buttons.push(
				<button
					key={`close-tabset-${node.getId()}`}
					type="button"
					onClick={() => onCloseTabSetButton(node)}
					className="mr-1 shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<X className="h-4 w-4" />
				</button>,
			);
		}

		if (node instanceof TabSetNode && node.getChildren().length === 0) {
			renderValues.stickyButtons = [];
			renderValues.buttons.push(
				<button
					key={`add-tab-${node.getId()}`}
					type="button"
					onClick={() => {
						// appContext.setCmdKOpen(true);
						onAddFromTabSetButton(node);
					}}
					className="shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<Plus className="h-4 w-4" />
				</button>,
			);
		}
	};

	const onTabSetPlaceHolder: TabSetPlaceHolderCallback = () => {
		return (
			<div className="flex h-full w-full items-center justify-center bg-neutral-100 text-neutral-400 text-sm" />
		);
	};

	const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
		if (node instanceof TabNode) {
			// This should not be rendering if the tabSet has been removed
			const tabId = node.getName();
			renderValues.content = <TabLeading tabId={tabId} />;
		}
	};

	const tabFactory = (node: TabNode) => {
		const component = node.getComponent();
		const name = node.getName();
		if (component === "button") {
			const tab = tabStore.tabs.get(name);
			if (!tab) return null;
			return (
				<div key={name} className="bg-white">
					<TabProvider tab={tab}>
						<RouterProvider router={tab.router} />
					</TabProvider>
				</div>
			);
		}
		return null;
	};

	return (
		<div className="flex h-full w-full flex-col bg-neutral-50">
			<div className="relative min-h-0 grow ">
				<Layout
					model={tabStore.model}
					factory={tabFactory}
					onRenderTabSet={onRenderTabSet}
					onRenderTab={onRenderTab}
					onTabSetPlaceHolder={onTabSetPlaceHolder}
					ref={layoutRef}
					icons={{
						close: (node) => (
							<button
								key={`close-tab-${node.getId()}`}
								type="button"
								onClick={() => onCloseTabButton(node)}
								className={clsx(
									"shrink-0 text-nowrap p-1 ",
									node.isSelected()
										? "hover:bg-neutral-100"
										: "opacity-0 hover:opacity-100",
								)}
								// disabled={!node.isSelected()}
							>
								<X className="h-4 w-4" />
							</button>
						),
					}}
					onAction={(action) => {
						if (action.type === Actions.ADD_NODE) {
						} else if (action.type === Actions.MOVE_NODE) {
						} else if (action.type === Actions.DELETE_TAB) {
						} else if (action.type === Actions.DELETE_TABSET) {
						} else if (action.type === Actions.RENAME_TAB) {
						} else if (action.type === Actions.SELECT_TAB) {
						} else if (action.type === Actions.SET_ACTIVE_TABSET) {
						} else if (action.type === Actions.ADJUST_WEIGHTS) {
						} else if (action.type === Actions.ADJUST_BORDER_SPLIT) {
						} else if (action.type === Actions.MAXIMIZE_TOGGLE) {
						} else if (action.type === Actions.UPDATE_MODEL_ATTRIBUTES) {
						} else if (action.type === Actions.UPDATE_NODE_ATTRIBUTES) {
						} else if (action.type === Actions.POPOUT_TAB) {
						} else if (action.type === Actions.POPOUT_TABSET) {
						} else if (action.type === Actions.CLOSE_WINDOW) {
						} else if (action.type === Actions.CREATE_WINDOW) {
						}
						return action;
					}}
				/>
			</div>
		</div>
	);
});
