import { FolderTree } from "@/components/tree/tree";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTabStore } from "@/contexts/tabs-context/use-tab-store";
import { FileUploadDialogContent } from "@/pages/folder/file-upload-dialog-content";
import { Books, FolderPlus, UploadSimple } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const SidebarLibrary = observer(() => {
	const tabsContext = useTabStore();
	const appContext = tabsContext.appState;
	const sidebarState = appContext.sidebarState;
	return (
		<>
			{/* Files header */}
			<div className="flex min-h-0 w-full shrink-0 justify-between px-2 hover:bg-neutral-50">
				{/* Navigation Button */}
				<Button
					type="button"
					variant="ghost"
					onClick={() => {
						tabsContext.navigate({ path: "folder", file_id: null });
					}}
					className="flex shrink-0 grow items-center justify-start gap-2 rounded-none bg-transparent px-0 py-1.5 hover:bg-transparent"
				>
					<Books className="text-base text-neutral-500" weight="duotone" />
					<span className="text-neutral-950">My Files</span>
				</Button>

				{/* Actions */}
				<div className="flex items-center">
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								type="button"
								variant="ghost"
								className="h-6 rounded-none px-2 hover:bg-neutral-200"
								onClick={() => {
									appContext.createFolder({
										parentFolderId: null,
										folderName: "New folder",
									});
								}}
								disabled={appContext.workspace === null}
							>
								<FolderPlus className="h-4 w-4" weight="duotone" />
							</Button>
						</TooltipTrigger>
						<TooltipContent align="start">New folder</TooltipContent>
					</Tooltip>
					<Tooltip>
						<Dialog modal>
							<TooltipTrigger asChild>
								<DialogTrigger disabled={appContext.workspace === null} asChild>
									<Button
										type="button"
										variant="ghost"
										className="h-6 rounded-none px-2 hover:bg-neutral-200"
										disabled={appContext.workspace === null}
									>
										<UploadSimple className="h-4 w-4" />
									</Button>
								</DialogTrigger>
							</TooltipTrigger>
							<FileUploadDialogContent parent={null} />
						</Dialog>
						<TooltipContent>Upload files</TooltipContent>
					</Tooltip>
				</div>
			</div>
			<div className="grow overflow-y-auto">
				<FolderTree treeState={sidebarState.treeState} />
			</div>
		</>
	);
});
