import {
	FieldTypeIcons,
	FieldTypeLabels,
} from "@/components/table/field-type-indicators";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import {
	type FieldId,
	FieldType,
	type TableId,
	type TableMetadata,
} from "@api/schemas";
import { CaretUpDown, Check } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const FieldTypeOption = ({
	fieldType,
}: {
	fieldType: FieldType;
}) => {
	return (
		<div className="flex items-center gap-3">
			{FieldTypeIcons[fieldType]({
				size: 16,
				className: "shrink-0",
			})}
			<h2 className="text-sm">{FieldTypeLabels[fieldType]}</h2>
		</div>
	);
};

interface ColumnCreationHeaderProps {
	columnCreationPopoverTrigger: React.ReactNode;
}

export const ColumnCreationHeader = observer(
	({ columnCreationPopoverTrigger }: ColumnCreationHeaderProps) => {
		const tableViewState = useTableViewContext();
		const [fieldName, setFieldName] = useState("");
		const [fieldType, setFieldType] = useState<FieldType>(FieldType.text);
		const [selectedTableId, setSelectedTableId] = useState<TableId | null>(
			null,
		);
		const [selectedRelationshipFieldId, setSelectedRelationshipFieldId] =
			useState<FieldId | null>(null);
		const [selectedLookupTargetFieldId, setSelectedLookupTargetFieldId] =
			useState<FieldId | null>(null);

		const [open, setOpen] = useState(false);

		const otherTables = Array.from(
			tableViewState.tableState.tablesStore.appState.workspace?.tables.items
				.values ?? [],
		).filter(
			(table): table is TableMetadata =>
				table.table_id !== tableViewState.tableState.tableId,
		);

		const selectedRelationshipField = selectedRelationshipFieldId
			? tableViewState.tableState.getFieldById(selectedRelationshipFieldId)
			: null;
		if (
			selectedRelationshipField !== null &&
			selectedRelationshipField.type !== FieldType.relationship
		) {
			throw new Error("Selected field is not a relationship field");
		}
		const targetTableId =
			selectedRelationshipField?.properties.foreign_table_id;
		const targetTable = targetTableId
			? tableViewState.tableState.tablesStore.getTableStateById(targetTableId)
			: null;
		const targetTableFields = targetTable?.sortedFields;

		return (
			<div className="flex items-center gap-2 p-0.5">
				<Popover open={open} onOpenChange={setOpen}>
					{columnCreationPopoverTrigger}
					<PopoverContent align="start" className="w-64 p-0">
						<section className="px-2 py-1">
							<Label>Add a column</Label>
						</section>

						<Separator />

						<section className="flex flex-col px-2 pt-2">
							<Label>Name</Label>
							<Input
								className="mt-2 h-8"
								value={fieldName}
								onChange={(e) => setFieldName(e.target.value)}
							/>
						</section>

						<section className="flex flex-col px-2 pt-2">
							<Label>Type</Label>
							<DropdownMenu>
								<DropdownMenuTrigger className="mt-2 flex w-full items-center border px-2 py-1">
									<div className="flex grow items-center">
										{fieldType ? (
											<FieldTypeOption fieldType={fieldType} />
										) : (
											<SelectValue placeholder="Select..." />
										)}
									</div>
									<CaretUpDown />
								</DropdownMenuTrigger>
								<DropdownMenuContent className="w-[238px]" align="start">
									{Object.values(FieldType).map((fieldType) => (
										<DropdownMenuItem
											key={fieldType}
											onSelect={() => setFieldType(fieldType)}
										>
											<FieldTypeOption fieldType={fieldType} />
										</DropdownMenuItem>
									))}
								</DropdownMenuContent>
							</DropdownMenu>
						</section>

						{fieldType === FieldType.relationship && (
							<section className="flex flex-col px-2 pt-2">
								<Label>Related Table</Label>
								<DropdownMenu>
									<DropdownMenuTrigger className="mt-2 flex w-full items-center border px-2 py-1 hover:bg-accent">
										<div className="flex grow items-center">
											<span className="text-sm">
												{selectedTableId
													? selectedTableId ===
														tableViewState.tableState.metadata.table_id
														? `${tableViewState.tableState.metadata.file_name} (self)`
														: otherTables.find(
																(t) => t.table_id === selectedTableId,
															)?.file_name
													: "Select a table..."}
											</span>
										</div>
										<CaretUpDown className="ml-2 h-4 w-4 opacity-50" />
									</DropdownMenuTrigger>
									<DropdownMenuContent className="max-h-[300px] w-[238px] overflow-y-auto">
										{/* TODO(John): allow self-referential relationship */}
										{/* <DropdownMenuItem
											key={tableViewState.tableState.metadata.table_id}
											onSelect={() =>
												setSelectedTableId(
													tableViewState.tableState.metadata.table_id,
												)
											}
											className="flex items-center justify-between"
										>
											<span>
												{tableViewState.tableState.metadata.file_name} (self)
											</span>
											{selectedTableId ===
												tableViewState.tableState.metadata.table_id && (
												<Check className="h-4 w-4" />
											)}
										</DropdownMenuItem> */}
										{otherTables.map((table) => (
											<DropdownMenuItem
												key={table.table_id}
												onSelect={() => setSelectedTableId(table.table_id)}
												className="flex items-center justify-between"
											>
												<span>{table.file_name}</span>
												{selectedTableId === table.table_id && (
													<Check className="h-4 w-4" />
												)}
											</DropdownMenuItem>
										))}
									</DropdownMenuContent>
								</DropdownMenu>
							</section>
						)}

						{fieldType === FieldType.lookup && (
							<>
								<section className="flex flex-col px-2 pt-2">
									<Label>Relationship Field</Label>
									<DropdownMenu>
										<DropdownMenuTrigger className="mt-2 flex w-full items-center border px-2 py-1 hover:bg-accent">
											<div className="flex grow items-center">
												<span className="text-sm">
													{selectedRelationshipFieldId
														? (tableViewState.tableState.getFieldById(
																selectedRelationshipFieldId,
															)?.name ?? "Select a field...")
														: "Select a field..."}
												</span>
											</div>
											<CaretUpDown className="ml-2 h-4 w-4 opacity-50" />
										</DropdownMenuTrigger>
										<DropdownMenuContent className="max-h-[300px] w-[238px] overflow-y-auto">
											{Array.from(tableViewState.tableState.sortedFields)
												.filter(
													(field) => field.type === FieldType.relationship,
												)
												.map((field) => (
													<DropdownMenuItem
														key={field.field_id}
														onSelect={() =>
															setSelectedRelationshipFieldId(field.field_id)
														}
														className="flex items-center justify-between"
													>
														<span>{field.name}</span>
														{selectedRelationshipFieldId === field.field_id && (
															<Check className="h-4 w-4" />
														)}
													</DropdownMenuItem>
												))}
										</DropdownMenuContent>
									</DropdownMenu>
								</section>

								<section className="flex flex-col px-2 pt-2">
									<Label>Lookup Field</Label>
									<DropdownMenu>
										<DropdownMenuTrigger
											className="mt-2 flex w-full items-center border px-2 py-1 hover:bg-accent"
											disabled={!selectedRelationshipFieldId}
										>
											<div className="flex grow items-center">
												<span className="text-sm">
													{selectedLookupTargetFieldId
														? (targetTable?.getFieldById(
																selectedLookupTargetFieldId,
															).name ?? "Select a field...")
														: "Select a field..."}
												</span>
											</div>
											<CaretUpDown className="ml-2 h-4 w-4 opacity-50" />
										</DropdownMenuTrigger>
										<DropdownMenuContent className="max-h-[300px] w-[238px] overflow-y-auto">
											{targetTableFields ? (
												targetTableFields.map((field) => (
													<DropdownMenuItem
														key={field.field_id}
														onSelect={() =>
															setSelectedLookupTargetFieldId(field.field_id)
														}
														className="flex items-center justify-between"
													>
														<span>{field.name}</span>
													</DropdownMenuItem>
												))
											) : (
												<span>No fields found</span>
											)}
										</DropdownMenuContent>
									</DropdownMenu>
								</section>
							</>
						)}

						<section className="px-2 pt-3 pb-2">
							<Button
								className="w-full"
								disabled={
									fieldType === FieldType.relationship && !selectedTableId
								}
								onClick={() => {
									if (fieldType === FieldType.relationship) {
										if (selectedTableId) {
											tableViewState.tableState.addRelationshipField({
												name: fieldName,
												foreignTableId: selectedTableId,
											});
										}
									} else if (fieldType === FieldType.lookup) {
										if (
											selectedRelationshipFieldId &&
											selectedLookupTargetFieldId
										) {
											tableViewState.tableState.addLookupField({
												name: fieldName,
												relationshipFieldId: selectedRelationshipFieldId,
												lookupTargetFieldId: selectedLookupTargetFieldId,
											});
										}
									} else {
										tableViewState.tableState.addPrimitiveField({
											name: fieldName,
											type: fieldType,
										});
									}
									setFieldName("");
									setFieldType(FieldType.text);
									setSelectedTableId(null);
									setSelectedRelationshipFieldId(null);
									setSelectedLookupTargetFieldId(null);
									setOpen(false);
								}}
							>
								Add column
							</Button>
						</section>
					</PopoverContent>
				</Popover>
			</div>
		);
	},
);
