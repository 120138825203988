import { PDFViewer } from "@/components/pdf/pdfviewer";
import type { SearchResult } from "@/contexts/tabs-context/tab-states/upload-state";
import type { TabTypeStateMap } from "@/contexts/tabs-context/tabs-context";
import { useTab } from "@/contexts/tabs-context/use-tab";
import { observer } from "mobx-react-lite";

export const UploadPage = observer(() => {
	const tab = useTab();
	const tabState = tab.state as TabTypeStateMap["upload"];
	const searchResult: SearchResult | null = tabState.searchResult;

	const highlights =
		searchResult?.activeGroupedSearchResults
			.map((chunk) => {
				if (!chunk.chunk_start_page_index || !chunk.chunk_end_page_index) {
					return null;
				}
				return {
					textToHighlight: {
						textStart: chunk.chunk_text,
					},
					startPageIndex: chunk.chunk_start_page_index,
					endPageIndex: chunk.chunk_end_page_index,
				};
			})
			.filter((highlight) => highlight !== null) || [];

	const activeHighlight =
		searchResult?.activeSearchResult?.chunk_start_page_index &&
		searchResult.activeSearchResult.chunk_end_page_index
			? {
					textToHighlight: {
						textStart: searchResult.activeSearchResult.chunk_text,
					},
					startPageIndex:
						searchResult.activeSearchResult.chunk_start_page_index,
					endPageIndex: searchResult.activeSearchResult.chunk_end_page_index,
				}
			: undefined;

	return (
		<PDFViewer
			upload={tabState.upload}
			highlights={highlights}
			activeHighlight={activeHighlight}
		/>
	);
});
