import { createMessageRouter } from "@/components/layout/right-sidebar/create-message-router";
import type { AppState } from "@/contexts/app-context/app-context";
import { pathObjectToString, pathStringToObject } from "@/lib/paths";
import type { MessageId, SessionAssistantId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import type { createMemoryRouter } from "react-router-dom";

export class RightSidebarState {
	appState: AppState;

	showRightSidebar = true;
	rightSidebarTab: "messages" | "assistant_activity" = "messages";

	activeSessionsDialogOpen = false;

	// The session assistant ID that is currently active in the assistant activity viewer
	activityViewerActiveSessionAssistantId: SessionAssistantId | null = null;

	// MessageInput state
	messageInputContent = "";

	#messagesRouter: ReturnType<typeof createMemoryRouter>;
	currentActiveMessageId: MessageId | null = null;

	constructor(appState: AppState) {
		this.appState = appState;
		this.#messagesRouter = createMessageRouter(null);
		this.#messagesRouter.subscribe((state) => {
			const pathObject = pathStringToObject(state.location.pathname);
			if (pathObject instanceof Error || pathObject.path !== "message") {
				return;
			}
			this.currentActiveMessageId = pathObject.message_id;
		});
		makeAutoObservable(this);
		makePersistable(this, {
			name: "RightSidebarState",
			properties: [
				"showRightSidebar",
				"rightSidebarTab",
				"activeSessionsDialogOpen",
				"activityViewerActiveSessionAssistantId",
				"messageInputContent",
			],
			storage: window.localStorage,
		});
	}

	setActiveSessionsDialogOpen = (open: boolean) => {
		this.activeSessionsDialogOpen = open;
	};

	toggleActiveSessionsDialog = () => {
		this.activeSessionsDialogOpen = !this.activeSessionsDialogOpen;
	};

	toggleRightSidebar = () => {
		this.showRightSidebar = !this.showRightSidebar;
	};

	setMessageInputContent = (content: string) => {
		this.messageInputContent = content;
	};

	/**
	 * Should only be used to create the RouterProvider for the messages router.
	 */
	get messagesRouterForProvider() {
		return this.#messagesRouter;
	}

	/**
	 * Navigates the messages router to the given path or history index.  Also
	 * updates the open thread event.
	 *
	 * @param args - A MessagePathObject or a number representing a history
	 * index (e.g. `-1` for the previous message, `-2` for the message before
	 * that, etc.)
	 */
	async navigateMessages(args: MessageId | null | number) {
		if (!this.#messagesRouter) {
			console.warn("Tried to navigate messages without a messages router");
			return;
		}

		let messageId: MessageId | null = null;
		if (typeof args === "number") {
			await this.#messagesRouter.navigate(args);
			const newPath = this.#messagesRouter.state.location.pathname;
			const pathObject = pathStringToObject(newPath);
			if (pathObject instanceof Error || pathObject.path !== "message") {
				console.error("Expected a message path, got", pathObject);
				return;
			}
			messageId = pathObject.message_id;
		} else {
			messageId = args;
			const pathString = pathObjectToString({
				path: "message",
				message_id: messageId,
			});
			await this.#messagesRouter.navigate(pathString, {
				preventScrollReset: true,
			});
		}
		this.appState.eventStore.handleEvent({
			eventType: "opened_thread",
			data: {
				thread_id: messageId,
				session_id: this.appState.sessionUserId,
			},
		});
	}
}
