import type {
	EventId,
	FeedChannelId,
	FeedItemId,
	FieldId,
	FileId,
	FolderId,
	MessageId,
	RecordId,
	SearchId,
	SessionUserId,
	StepId,
	TableId,
	UploadId,
	UserId,
	WebSearchId,
	// WebSearchId,
} from "@api/schemas";
import { ulid } from "ulidx";

type IdType =
	| "step"
	| "feed-channel"
	| "feed-item"
	| "folder"
	| "table"
	| "record"
	| "field"
	| "upload"
	| "file"
	| "web-search"
	| "search"
	// Session types
	| "session-user"
	| "event"
	| "message";
// | "filter";

type IdMap = {
	step: StepId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	folder: FolderId;
	table: TableId;
	record: RecordId;
	field: FieldId;
	upload: UploadId;
	user: UserId;
	file: FileId;
	"web-search": WebSearchId;
	search: SearchId;
	// Session types
	"session-user": SessionUserId;
	message: MessageId;
	event: EventId;
	// filter: FilterId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}`.toLowerCase() as IdMap[T];
};

export const createStepId = () => generateId("step");
export const createFeedChannelId = () => generateId("feed-channel");
export const createFeedItemId = () => generateId("feed-item");
export const createFolderId = () => generateId("folder");
export const createTableId = () => generateId("table");
export const createRecordId = () => generateId("record");
export const createFieldId = () => generateId("field");
export const createUploadId = () => generateId("upload");
// export const createFilterId = () => generateId("filter");
// Session types
export const createNewSessionUserId = () => generateId("session-user");
export const createNewMessageId = () => generateId("message");
export const createNewEventId = () => generateId("event");
export const createNewWebSearchId = () => generateId("web-search");
export const createNewSearchId = () => generateId("search");
