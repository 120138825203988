import { Cell } from "@/components/table/cell/cell";
import { ColumnCreationHeader } from "@/components/table/column-creation-header";
import {
	DeleteColumnDialogContent,
	RenderColumnIcon,
} from "@/components/table/columns";
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import { CaretLeft, CaretRight, X } from "@phosphor-icons/react";
import { PopoverTrigger } from "@radix-ui/react-popover";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const ColumnCreationPopoverTrigger = () => {
	return (
		<PopoverTrigger asChild>
			<button type="button" className="text-neutral-500 text-xs">
				+ Add field
			</button>
		</PopoverTrigger>
	);
};

// TODO(John): rename stuff in here
const TablePageViewHeader = observer(() => {
	const tableViewState = useTableViewContext();
	const { visibleRecordRowIndex: pageViewRowIndex } = tableViewState;

	// this is always offset by 1 from the row index
	const [rowNumberInput, setRowNumberInput] = useState(
		pageViewRowIndex ? (pageViewRowIndex + 1).toString() : "1",
	);

	const resolveRowNumberUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const raw = e.target.value;
		setRowNumberInput(raw); // always update the input value

		// only update number state if it's valid
		const parsed = Number.parseInt(raw);
		if (Number.isNaN(parsed)) {
			return;
		}
		// if the index is valid, update the page view row id
		// do nothing if the index is out of bounds
		if (
			parsed > 0 &&
			parsed <= tableViewState.tableState.sortedRecords.length
		) {
			tableViewState.setVisibleRecordId(
				tableViewState.tableState.sortedRecords[parsed - 1].record_id,
			);
		}
	};

	return (
		<div className="flex items-center gap-1 border-neutral-100 border-b bg-neutral-50 p-1">
			<button
				type="button"
				// decrement the page view row index
				onClick={() => {
					if (pageViewRowIndex !== null && pageViewRowIndex > 0) {
						const previousRow = tableViewState.tableState.sortedRecords.at(
							pageViewRowIndex - 1,
						);
						if (previousRow) {
							tableViewState.setVisibleRecordId(previousRow.record_id);
							setRowNumberInput(pageViewRowIndex.toString());
						}
					}
				}}
				// disable if first page or no page view row id
				disabled={pageViewRowIndex === 0 || pageViewRowIndex === null}
				className="text-neutral-600 disabled:text-neutral-300"
			>
				<CaretLeft className="h-4 w-4 " />
			</button>
			<span className="text-neutral-600 text-sm">
				<input
					type="number"
					value={rowNumberInput}
					className={clsx("w-8 rounded-md border px-1 py-0.5 text-center")}
					onChange={resolveRowNumberUpdate}
				/>{" "}
				of {tableViewState.tableState.sortedRecords.length} rows
			</span>
			<button
				type="button"
				onClick={() => {
					if (
						pageViewRowIndex !== null &&
						pageViewRowIndex <
							tableViewState.tableState.sortedRecords.length - 1
					) {
						const nextRow = tableViewState.tableState.sortedRecords.at(
							pageViewRowIndex + 1,
						);
						if (nextRow) {
							tableViewState.setVisibleRecordId(nextRow.record_id);
							setRowNumberInput((pageViewRowIndex + 1 + 1).toString());
						}
					}
				}}
				// disable if last page or no page view row id
				disabled={
					pageViewRowIndex ===
						tableViewState.tableState.sortedRecords.length - 1 ||
					pageViewRowIndex === null
				}
				className="text-neutral-600 disabled:text-neutral-300"
			>
				<CaretRight className="h-4 w-4 " />
			</button>
		</div>
	);
});

export const PageBody = observer(() => {
	// TODO(John): better way to remove this type annotating boilerplate?
	const tableViewState = useTableViewContext();
	const { visibleRecordId, visibleRecordRowIndex } = tableViewState;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// default to the first row
		if (visibleRecordRowIndex === null) {
			if (tableViewState.tableState.sortedRecords.length > 0) {
				tableViewState.setVisibleRecordId(
					tableViewState.tableState.sortedRecords[0].record_id,
				);
			}
		}
	}, [visibleRecordRowIndex, tableViewState.tableState.sortedRecords.length]);

	if (visibleRecordId === null) {
		return null;
	}

	return (
		<>
			{tableViewState.tableState.sortedFields.map((field) => (
				<div key={field.field_id} className="flex flex-col gap-4 p-4">
					<span className="flex items-center gap-2">
						<span className="flex grow items-center gap-2">
							{RenderColumnIcon(field.type)}
							<span className="text-neutral-500 text-sm">{field.name}</span>
						</span>

						<AlertDialog>
							<AlertDialogTrigger
								onClick={(e) => {
									e.stopPropagation();
								}}
								asChild
							>
								<button type="button">
									<X className="h-4 w-4 text-neutral-500" />
								</button>
							</AlertDialogTrigger>
							<AlertDialogContent>
								<DeleteColumnDialogContent fieldId={field.field_id} />
							</AlertDialogContent>
						</AlertDialog>
					</span>

					<div className="flex flex-col gap-2">
						{/* <DescriptionEditor block={block} /> */}
						<div className="flex flex-row gap-1">
							<Cell
								recordId={visibleRecordId}
								field={field}
								value={tableViewState.tableState.getCellValue(
									visibleRecordId,
									field,
								)}
							/>
						</div>
					</div>
				</div>
			))}
			<div className="w-full p-4">
				<ColumnCreationHeader
					columnCreationPopoverTrigger={<ColumnCreationPopoverTrigger />}
				/>
			</div>
		</>
	);
});

export const TablePageView = observer(() => {
	return (
		<div className="flex h-full flex-col">
			<TablePageViewHeader />
			<PageBody />
		</div>
	);
});
