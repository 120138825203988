/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type PageResolution = typeof PageResolution[keyof typeof PageResolution];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageResolution = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  thumbnail: 'thumbnail',
} as const;
