/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * The type (article, book) of the uploaded file. Inferred from the file content.
 */
export type UploadType = typeof UploadType[keyof typeof UploadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadType = {
  article: 'article',
  book: 'book',
} as const;
