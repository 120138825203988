import type {
	TableState,
	TablesStore,
} from "@/contexts/app-context/db-store/table-stores";
import { createSyncedAction } from "@/contexts/synced-actions";
import {
	createFieldId,
	createRecordId,
	createTableId,
} from "@/lib/id-generators";
import {
	addLookupFieldRoute,
	addPrimitiveFieldRoute,
	addRelationshipFieldRoute,
	addSelectOptionRoute,
	createTableRoute,
	deleteFieldRoute,
	deleteRecordsRoute,
	deleteSelectOptionRoute,
	insertEmptyRecordRoute,
	updateCellsRoute,
	updateFieldRoute,
	updateRecordOrdersRoute,
	updateSelectOptionColorRoute,
	updateSelectOptionLabelRoute,
} from "@api/fastAPI";
import {
	type CellValue,
	type Field,
	type FieldId,
	type FieldOrderStr,
	FieldType,
	type FolderId,
	type PrimitiveField,
	type Record,
	type RecordId,
	type RecordOrderStr,
	type SelectOption,
	type SelectOptionColor,
	type SelectOptionLabel,
	type TableId,
	type TableMetadata,
} from "@api/schemas";
import {
	generateJitteredKeyBetween,
	generateNJitteredKeysBetween,
} from "fractional-indexing-jittered";

const DEFAULT_FIELD_WIDTH = 320;

export const createTableAction = createSyncedAction<
	TablesStore,
	{
		name: string;
		parentId: FolderId | null;
	},
	{
		newTableMetadata: TableMetadata;
	},
	void
>({
	async local({ name, parentId }) {
		// TODO(John): make constructor functions
		const newTableId = createTableId();
		const tableMetadata: TableMetadata = {
			table_id: newTableId,
			file_id: newTableId,
			file_type: "table",
			file_name: name,
			file_parent_id: parentId,
			file_created_at: new Date().toISOString(),
			file_creator_id: this.appState.userId,
			file_deleted_at: null,
			file_updated_at: new Date().toISOString(),
			state_switch: 1,
		};

		// TODO(John): can I have this not require the type literal?
		this.upsertTableMetadataLocally({
			type: "upsert_table_metadata",
			table_metadata: tableMetadata,
		});
		return { newTableMetadata: tableMetadata };
	},
	async remote(_, { newTableMetadata }) {
		await createTableRoute({
			table_metadata: newTableMetadata,
		});
	},
	rollback(_, { newTableMetadata }) {
		this.appState.workspace?.tables.items.delete(newTableMetadata.table_id);
	},
});

/**
 * Inserts an empty record after the given record.
 *
 * The row will be placed after the given record, or, if null, at the start of
 * the table.
 */
export const addRecordAction = createSyncedAction<
	TableState,
	{
		precedingRecordId: RecordId | null;
	},
	{
		newRecord: Record;
	},
	void
>({
	async local({ precedingRecordId }) {
		let lowerOrder: RecordOrderStr | null;
		let upperOrder: RecordOrderStr | null;

		if (precedingRecordId === null) {
			lowerOrder = null;
			upperOrder = this.sortedRecords.at(0)?.order ?? null;
		} else {
			lowerOrder = this.getRecordById(precedingRecordId).order;
			upperOrder = this.getRecordAfter(precedingRecordId)?.order ?? null;
		}

		const newRecord: Record = {
			record_id: createRecordId(),
			order: generateJitteredKeyBetween(
				lowerOrder,
				upperOrder,
			) as RecordOrderStr,
			cell_values: {},
		};
		// TODO(John): table_id should not be needed
		this.upsertRecordLocally({
			type: "upsert_record",
			record: newRecord,
			table_id: this.tableId,
		});
		return { newRecord };
	},
	async remote(_, { newRecord }) {
		await insertEmptyRecordRoute({
			table_id: this.tableId,
			record: newRecord,
		});
	},
	rollback(_, { newRecord }) {
		this.deleteRecordsLocally({
			type: "delete_records",
			table_id: this.tableId,
			record_ids: [newRecord.record_id],
		});
	},
});

/**
 * Moves the given records after the given record.
 *
 * TODO(John): check what moves should be illegal, if any.
 */
export const moveRecordsAction = createSyncedAction<
	TableState,
	{
		precedingRecordId: RecordId | null;
		recordIds: Set<RecordId>;
	},
	{
		oldOrders: Map<RecordId, RecordOrderStr>;
		newRecordOrders: Map<RecordId, RecordOrderStr>;
	},
	void
>({
	async local({ precedingRecordId, recordIds }) {
		const recordsToMove = Array.from(recordIds)
			.map((recordId) => this.getRecordById(recordId))
			.sort((a, b) => a.order.localeCompare(b.order));

		const oldOrders = new Map<RecordId, RecordOrderStr>();
		for (const record of recordsToMove) {
			oldOrders.set(record.record_id, record.order);
		}

		let lowerOrder: RecordOrderStr | null;
		let upperOrder: RecordOrderStr | null;

		if (precedingRecordId === null) {
			lowerOrder = null;
			upperOrder = this.sortedRecords.at(0)?.order ?? null;
		} else {
			lowerOrder = this.getRecordById(precedingRecordId).order;
			upperOrder = this.getRecordAfter(precedingRecordId)?.order ?? null;
		}

		const newOrders = generateNJitteredKeysBetween(
			lowerOrder,
			upperOrder,
			recordIds.size,
		);

		const newRecordOrders = new Map<RecordId, RecordOrderStr>();
		for (const [index, record] of recordsToMove.entries()) {
			newRecordOrders.set(record.record_id, newOrders[index] as RecordOrderStr);
		}

		return { oldOrders, newRecordOrders };
	},
	async remote(_, { newRecordOrders }) {
		await updateRecordOrdersRoute({
			table_id: this.tableId,
			new_record_orders: Object.fromEntries(newRecordOrders),
		});
	},
	rollback(_, { oldOrders }) {
		this.updateRecordOrdersLocally({
			type: "update_record_orders",
			table_id: this.tableId,
			new_record_orders: Object.fromEntries(oldOrders),
		});
	},
});

/**
 * Updates cells of a field for a set of records.
 */
export const updateCellValuesAction = createSyncedAction<
	TableState,
	{
		recordId: RecordId;
		fieldId: FieldId;
		cellValue: CellValue;
	},
	{
		oldValue: CellValue;
	},
	void
>({
	async local({ recordId, fieldId, cellValue }) {
		const oldValue = this.getRecordById(recordId).cell_values[fieldId];
		// TODO(John): should we check if the value is already the same?

		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: [recordId],
			field_id: fieldId,
			cell_value: cellValue,
		});

		return { oldValue };
	},
	async remote({ recordId, fieldId, cellValue }) {
		await updateCellsRoute({
			table_id: this.tableId,
			record_ids: [recordId],
			field_id: fieldId,
			cell_value: cellValue,
		});
	},
	rollback({ recordId, fieldId }, { oldValue }) {
		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: [recordId],
			field_id: fieldId,
			cell_value: oldValue,
		});
	},
});

export const deleteRecordsAction = createSyncedAction<
	TableState,
	{
		recordIds: Set<RecordId>;
	},
	{
		deletedRecords: Array<Record>;
	},
	void
>({
	async local({ recordIds }) {
		// Store records for rollback
		const recordIdsArray = Array.from(recordIds);
		const deletedRecords = recordIdsArray.map((recordId) =>
			this.getRecordById(recordId),
		);
		this.deleteRecordsLocally({
			type: "delete_records",
			table_id: this.tableId,
			record_ids: recordIdsArray,
		});

		return { deletedRecords };
	},
	async remote({ recordIds }) {
		await deleteRecordsRoute({
			table_id: this.tableId,
			record_ids: Array.from(recordIds),
		});
	},
	rollback(_, { deletedRecords }) {
		for (const record of deletedRecords) {
			this.upsertRecordLocally({
				type: "upsert_record",
				record,
				table_id: this.tableId,
			});
		}
	},
});

/**
 * TODO(John): handle both field types with this
 */
export const addPrimitiveFieldAction = createSyncedAction<
	TableState,
	{
		name: string;
		type: PrimitiveField["type"];
	},
	{
		newField: PrimitiveField;
	},
	void
>({
	async local({ name, type }) {
		// TODO(John): fix this
		const baseField = {
			field_id: createFieldId(),
			table_id: this.tableId,
			name,
			order: generateJitteredKeyBetween(
				this.sortedFields.at(-1)?.order ?? null,
				null,
			) as FieldOrderStr,
			width: DEFAULT_FIELD_WIDTH,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
			deleted_at: null,
			default_value: null,
		};
		let newField: PrimitiveField;
		switch (type) {
			case FieldType.text:
				newField = {
					...baseField,
					type: FieldType.text,
					properties: {
						default_value: null,
					},
				};
				break;
			case FieldType.boolean:
				newField = {
					...baseField,
					type: FieldType.boolean,
					properties: {
						default_value: null,
					},
				};
				break;
			case FieldType.select:
				newField = {
					...baseField,
					type: FieldType.select,
					properties: {
						options: {},
						default_value: null,
					},
				};
				break;
			case FieldType.datetime:
				newField = {
					...baseField,
					type: FieldType.datetime,
					properties: {
						show_time: false,
						default_value: null,
					},
				};
				break;
			case FieldType.number:
				newField = {
					...baseField,
					type: FieldType.number,
					properties: {
						default_value: null,
					},
				};
				break;
			default: {
				const exhaustiveCheck: never = type;
				newField = exhaustiveCheck;
			}
		}

		this.addFieldLocally({
			type: "add_field",
			field: newField,
		});
		return { newField };
	},
	async remote(_, { newField }) {
		await addPrimitiveFieldRoute({
			field: newField,
		});
	},
	rollback(_, { newField }) {
		this.tablesStore.appState.workspace?.fields.items.delete(newField.field_id);
	},
});

/**
 * Adds a relationship field to the table.
 */
export const addRelationshipFieldAction = createSyncedAction<
	TableState,
	{
		name: string;
		foreignTableId: TableId;
	},
	void,
	void
>({
	async local() {
		// No local updates - field creation is handled server-side
		return;
	},
	async remote({ name, foreignTableId }) {
		// Generate order after the last field
		const order = generateJitteredKeyBetween(
			this.sortedFields.at(-1)?.order ?? null,
			null,
		) as FieldOrderStr;

		await addRelationshipFieldRoute({
			source_table_id: this.tableId,
			source_table_new_field_name: name,
			source_table_new_field_order: order,
			foreign_table_id: foreignTableId,
		});
	},
	rollback() {
		// No rollback needed since there were no local updates
	},
});

export const addLookupFieldAction = createSyncedAction<
	TableState,
	{
		name: string;
		relationshipFieldId: FieldId;
		lookupTargetFieldId: FieldId;
	},
	void,
	void
>({
	async local() {
		// No local updates - field creation is handled server-side
		return;
	},
	async remote({ name, relationshipFieldId, lookupTargetFieldId }) {
		// Generate order after the last field
		const order = generateJitteredKeyBetween(
			this.sortedFields.at(-1)?.order ?? null,
			null,
		) as FieldOrderStr;

		await addLookupFieldRoute({
			table_id: this.tableId,
			field_name: name,
			field_order: order,
			relationship_field_id: relationshipFieldId,
			lookup_target_field_id: lookupTargetFieldId,
		});
	},
	rollback() {
		// No rollback needed since there were no local updates
	},
});

/**
 * Updates a single field's name or width.
 *
 * TODO(John): create separate actions for those?
 *
 * Only define the fields that should be updated.
 */
export const updateFieldAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		newName?: string;
		newWidth?: number;
	},
	{
		updatedAt: string;
		oldName: string;
		oldWidth: number;
	},
	void
>({
	async local({ fieldId, newName, newWidth }) {
		const field = this.getFieldById(fieldId);
		const oldName = field.name;
		const oldWidth = field.width;

		const updatedAt = new Date().toISOString();
		this.updateFieldLocally({
			type: "update_field",
			table_id: this.tableId,
			field_id: fieldId,
			name: newName ?? null,
			width: newWidth ?? null,
			updated_at: updatedAt,
		});

		return {
			updatedAt,
			oldName,
			oldWidth,
		};
	},
	async remote({ fieldId, newName, newWidth }, { updatedAt }) {
		await updateFieldRoute({
			table_id: this.tableId,
			field_id: fieldId,
			name: newName ?? null,
			width: newWidth ?? null,
			updated_at: updatedAt,
		});
	},
	rollback({ fieldId }, { oldName, oldWidth, updatedAt }) {
		const field = this.getFieldById(fieldId);
		field.name = oldName;
		field.width = oldWidth;
		field.updated_at = updatedAt;
	},
});

export const moveFieldAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		precedingFieldId: FieldId | null;
	},
	{
		updatedAt: string;
		oldOrder: FieldOrderStr;
		newOrder: FieldOrderStr;
	},
	void
>({
	async local({ fieldId, precedingFieldId }) {
		const field = this.getFieldById(fieldId);
		const oldOrder = field.order;

		let lowerOrder: FieldOrderStr | null;
		let upperOrder: FieldOrderStr | null;

		if (precedingFieldId === null) {
			lowerOrder = null;
			upperOrder = this.sortedFields.at(0)?.order ?? null;
		} else {
			lowerOrder = this.getFieldById(precedingFieldId).order;
			upperOrder = this.getFieldAfter(precedingFieldId)?.order ?? null;
		}

		const newOrder = generateJitteredKeyBetween(
			lowerOrder,
			upperOrder,
		) as FieldOrderStr;

		const updatedAt = new Date().toISOString();
		this.updateFieldLocally({
			type: "update_field",
			table_id: this.tableId,
			field_id: fieldId,
			order: newOrder,
			name: null,
			width: null,
			updated_at: updatedAt,
		});

		return { oldOrder, newOrder, updatedAt };
	},
	async remote({ fieldId }, { newOrder, updatedAt }) {
		await updateFieldRoute({
			table_id: this.tableId,
			field_id: fieldId,
			order: newOrder,
			name: null,
			width: null,
			updated_at: updatedAt,
		});
	},
	rollback({ fieldId }, { oldOrder, updatedAt }) {
		const field = this.getFieldById(fieldId);
		field.order = oldOrder;
		field.updated_at = updatedAt;
	},
});

export const deleteFieldAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
	},
	{
		deletedAt: string;
		oldField: Field;
		oldValues: Map<RecordId, CellValue>;
	},
	void
>({
	async local({ fieldId }) {
		const field = this.getFieldById(fieldId);
		const oldField = { ...field };
		const oldValues = new Map<RecordId, CellValue>();

		for (const record of this.sortedRecords) {
			if (record.cell_values[fieldId] !== undefined) {
				oldValues.set(record.record_id, record.cell_values[fieldId]);
			}
		}

		const deletedAt = new Date().toISOString();

		this.deleteFieldLocally({
			type: "delete_field",
			table_id: this.tableId,
			field_id: fieldId,
			deleted_at: deletedAt,
		});

		return { oldField, oldValues, deletedAt };
	},
	async remote({ fieldId }, { deletedAt }) {
		await deleteFieldRoute({
			table_id: this.tableId,
			field_id: fieldId,
			deleted_at: deletedAt,
		});
	},
	rollback({ fieldId }, { oldField, oldValues }) {
		this.addFieldLocally({
			type: "add_field",
			field: oldField,
		});

		for (const [recordId, value] of oldValues) {
			this.updateCellValuesLocally({
				type: "update_cells",
				table_id: this.tableId,
				record_ids: [recordId],
				field_id: fieldId,
				cell_value: value,
			});
		}
	},
});

export const addSelectOptionAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		newOption: SelectOption;
		recordIdToSet?: RecordId;
	},
	{
		oldValue?: string | null;
	},
	void
>({
	async local({ fieldId, newOption, recordIdToSet }) {
		let oldValue: string | null | undefined;

		if (recordIdToSet) {
			oldValue = this.getRecordById(recordIdToSet).cell_values[fieldId] as
				| string
				| null;
		}

		this.addSelectOptionLocally({
			type: "add_select_option",
			table_id: this.tableId,
			field_id: fieldId,
			new_option: newOption,
			record_id_to_set: recordIdToSet ?? null,
		});

		return { oldValue };
	},
	async remote({ fieldId, newOption, recordIdToSet }) {
		await addSelectOptionRoute({
			table_id: this.tableId,
			field_id: fieldId,
			new_option: newOption,
			record_id_to_set: recordIdToSet ?? null,
		});
	},
	rollback({ fieldId, newOption, recordIdToSet }, { oldValue }) {
		// TODO(John): I wonder if this is safe. Since
		// `deleteSelectOptionLocally` updates field values for any records
		// that had the deleted option, this rollback might delete too much if
		// we were added an option that already existed. The check in the
		// function makes this impossible, but...
		this.deleteSelectOptionLocally({
			type: "delete_select_option",
			table_id: this.tableId,
			field_id: fieldId,
			label: newOption.label,
		});

		if (recordIdToSet && oldValue !== undefined) {
			this.updateCellValuesLocally({
				type: "update_cells",
				table_id: this.tableId,
				record_ids: [recordIdToSet],
				field_id: fieldId,
				cell_value: oldValue,
			});
		}
	},
});

export const deleteSelectOptionAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		label: SelectOptionLabel;
	},
	{
		oldOption: SelectOption;
		affectedRecordIds: RecordId[];
	},
	void
>({
	async local({ fieldId, label }) {
		const field = this.getFieldById(fieldId);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		const oldOption = field.properties.options[label];
		const affectedRecordIds = this.deleteSelectOptionLocally({
			type: "delete_select_option",
			table_id: this.tableId,
			field_id: fieldId,
			label,
		});

		return { oldOption, affectedRecordIds };
	},
	async remote({ fieldId, label }) {
		await deleteSelectOptionRoute({
			table_id: this.tableId,
			field_id: fieldId,
			label,
		});
	},
	rollback({ fieldId }, { oldOption, affectedRecordIds }) {
		this.addSelectOptionLocally({
			type: "add_select_option",
			table_id: this.tableId,
			field_id: fieldId,
			new_option: oldOption,
			record_id_to_set: null,
		});
		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: affectedRecordIds,
			field_id: fieldId,
			cell_value: oldOption.label,
		});
	},
});

export const updateSelectOptionLabelAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		oldLabel: SelectOptionLabel;
		newLabel: SelectOptionLabel;
	},
	{
		affectedRecordIds: RecordId[];
	},
	void
>({
	async local({ fieldId, oldLabel, newLabel }) {
		const affectedRecordIds = this.updateSelectOptionLabelLocally({
			type: "update_select_option_label",
			table_id: this.tableId,
			field_id: fieldId,
			old_label: oldLabel,
			new_label: newLabel,
		});

		return { affectedRecordIds };
	},
	async remote({ fieldId, oldLabel, newLabel }) {
		await updateSelectOptionLabelRoute({
			table_id: this.tableId,
			field_id: fieldId,
			old_label: oldLabel,
			new_label: newLabel,
		});
	},
	rollback({ fieldId, oldLabel, newLabel }, { affectedRecordIds }) {
		this.updateSelectOptionLabelLocally({
			type: "update_select_option_label",
			table_id: this.tableId,
			field_id: fieldId,
			old_label: newLabel,
			new_label: oldLabel,
		});

		this.updateCellValuesLocally({
			type: "update_cells",
			table_id: this.tableId,
			record_ids: affectedRecordIds,
			field_id: fieldId,
			cell_value: oldLabel,
		});
	},
});

export const updateSelectOptionColorAction = createSyncedAction<
	TableState,
	{
		fieldId: FieldId;
		label: SelectOptionLabel;
		color: SelectOptionColor;
	},
	{
		oldColor: SelectOptionColor;
	},
	void
>({
	async local({ fieldId, label, color }) {
		const field = this.getFieldById(fieldId);
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		const oldColor = field.properties.options[label].color;

		this.updateSelectOptionColorLocally({
			type: "update_select_option_color",
			table_id: this.tableId,
			field_id: fieldId,
			label,
			color,
		});

		return { oldColor };
	},
	async remote({ fieldId, label, color }) {
		await updateSelectOptionColorRoute({
			table_id: this.tableId,
			field_id: fieldId,
			label,
			color,
		});
	},
	rollback({ fieldId, label }, { oldColor }) {
		this.updateSelectOptionColorLocally({
			type: "update_select_option_color",
			table_id: this.tableId,
			field_id: fieldId,
			label,
			color: oldColor,
		});
	},
});
