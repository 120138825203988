import type { Editor } from "@tiptap/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

interface HeadingNode {
	id: string;
	level: number;
	text: string;
	pos: number;
}

export const Outline = observer(({ editor }: { editor: Editor }) => {
	const [items, setItems] = useState<HeadingNode[]>([]);

	const handleUpdate = useCallback(() => {
		const headings: HeadingNode[] = [];
		const transaction = editor.state.tr;

		editor.state.doc.descendants((node, pos) => {
			// skip the first heading node
			// if (pos === 0) {
			//     return
			// }

			if (node.type.name === "heading") {
				const id = `heading-${headings.length + 1}`;

				// do not set markout of the title node!
				// will cause editor to keep appending empty title nodes for some reason
				if (node.attrs.id !== id && pos !== 0) {
					transaction.setNodeMarkup(pos, undefined, {
						...node.attrs,
						id,
					});
				}

				headings.push({
					level: node.attrs.level,
					text: node.textContent,
					id,
					pos,
				});
			}
		});

		transaction.setMeta("addToHistory", false);
		transaction.setMeta("preventUpdate", true);

		editor.view.dispatch(transaction);

		setItems(headings);
	}, [editor]);

	useEffect(handleUpdate, []);

	useEffect(() => {
		if (!editor) {
			return;
		}

		editor.on("update", handleUpdate);

		return () => {
			editor.off("update", handleUpdate);
		};
	}, [editor, handleUpdate]);

	// const [activeItemId, setActiveItemId] = useState<string | null>(null);
	const [_, setActiveItemId] = useState<string | null>(null);

	const activeEditorElem = document.getElementById("editor-container");

	// listen to scroll on the editor container
	useEffect(() => {
		setActiveItemId(items?.[0]?.id);

		if (!activeEditorElem) {
			return;
		}

		/**
		 * Updates the active item based on the scroll position of the container.
		 */
		const handleScroll = () => {
			const { scrollTop } = activeEditorElem;
			const offset = 200;

			// Helper function to get the offsetTop of an element by its ID
			const getOffsetTopById = (id: string) =>
				document.getElementById(id)?.offsetTop ?? null;

			const active = items.find((item, index) => {
				const nextItem = items[index + 1];

				const itemOffsetTop = getOffsetTopById(item.id);
				const nextItemOffsetTop =
					getOffsetTopById(nextItem?.id) ?? Number.POSITIVE_INFINITY;

				if (itemOffsetTop !== null) {
					return (
						itemOffsetTop <= scrollTop + offset &&
						nextItemOffsetTop >= scrollTop + offset
					);
				}
			});

			setActiveItemId(active?.id ?? items[0]?.id);
		};

		activeEditorElem.addEventListener("scroll", handleScroll);

		return () => {
			activeEditorElem.removeEventListener("scroll", handleScroll);
		};
	}, [items, activeEditorElem]);

	return (
		<>
			<div
				className={"w-full min-w-0 grow overflow-y-auto overflow-x-hidden py-2"}
			>
				{items.map((item, index) => {
					// const active = activeItemId === item.id;

					return (
						<div
							key={item.id}
							className={
								"relative flex w-full items-stretch truncate pr-2 font-medium text-xs"
							}
							style={{
								paddingLeft: `${(item.level - 1) * 2}rem`,
							}}
						>
							<button
								type="button"
								onClick={() => {
									if (index === 0) {
										if (activeEditorElem) {
											activeEditorElem.scrollTo({
												top: 0,
												behavior: "smooth",
											});
											editor.commands.setTextSelection(0);
										}
										return;
									}

									const node = document.getElementById(item.id);
									if (node) {
										const offset = node.offsetHeight + node.offsetTop - 100;

										if (activeEditorElem) {
											activeEditorElem.scrollTo({
												top: offset,
												behavior: "smooth",
											});
											editor.commands.setTextSelection(item.pos + 1);
										}
									}
								}}
								className="w-full min-w-0 truncate rounded p-0.5 text-left text-neutral-700"
							>
								{item.text ||
									(index === 0 ? "Title..." : `Heading ${item.level}`)}
							</button>
						</div>
					);
				})}
			</div>
		</>
	);
});
