import type { TableViewState } from "@/contexts/tabs-context/tab-states/table-tab-state";
import { createContext, useContext } from "react";

export const TableViewContext = createContext<TableViewState | null>(null);

export const useTableViewContext = () => {
	const context = useContext(TableViewContext);
	if (!context) {
		throw new Error(
			"useTableViewContext must be used within a TableViewProvider",
		);
	}
	return context;
};
