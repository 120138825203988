import { CustomEditor } from "@/components/editor";
import { Button } from "@/components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import type { ThreadRootMessage } from "@/contexts/app-context/db-store/message-store";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { cn } from "@/lib/utils";
import { ObjectLinkComponent } from "@/plugins/object-link";
import type {
	AssistantStatus,
	MessageId,
	MessageWithMetadata,
	SessionAssistantId,
} from "@api/schemas";
import {
	FloatingPortal,
	autoUpdate,
	offset,
	safePolygon,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import {
	ArrowBendDownRight,
	ArrowLeft,
	Cursor,
	CursorClick,
	Eyeglasses,
	HourglassHigh,
	HourglassLow,
	HourglassMedium,
	Keyboard,
	PaperPlaneRight,
	Spinner,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { motion, useAnimate } from "motion/react";
import { useEffect, useRef, useState } from "react";
import { RouterProvider, useParams } from "react-router-dom";

const BACKGROUND_COLORS = [
	"bg-blue-100",
	"bg-green-100",
	"bg-yellow-100",
	"bg-purple-100",
	"bg-pink-100",
	"bg-indigo-100",
	"bg-red-100",
	"bg-orange-100",
	"bg-gray-100",
	"bg-teal-100",
	"bg-lime-100",
	"bg-cyan-100",
	"bg-emerald-100",
	"bg-fuchsia-100",
] as const;

const CROCKFORD_BASE32_CHARS = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";

/**
 * Deterministically maps a ULID to a Tailwind background color class
 * @param ulid The ULID string
 * @returns A Tailwind background color class
 */
function getBackgroundColorForSession(ulid: string): string {
	// Get the last character of the ULID
	const lastChar = ulid.slice(-1);
	const value = CROCKFORD_BASE32_CHARS.indexOf(lastChar);
	// Map to color index using modulo
	const colorIndex = value % BACKGROUND_COLORS.length;
	const backgroundColor = BACKGROUND_COLORS[colorIndex];
	return backgroundColor;
}

const MessageContent = observer(({ content }: { content: string }) => {
	return (
		<CustomEditor
			className="w-full text-sm"
			options={{
				content,
				editable: false,
				editorProps: {
					attributes: {
						class: "py-1",
					},
				},
			}}
		/>
	);
});

const AssistantProfilePicture = observer(
	({
		sessionAssistantId,
		className,
		size = 32,
	}: {
		sessionAssistantId: SessionAssistantId;
		className?: string;
		size: number; // in pixels
	}) => {
		return (
			<div
				className={cn(
					"flex items-center justify-center rounded",
					getBackgroundColorForSession(sessionAssistantId),
					className,
				)}
				style={{
					height: `${size}px`,
					width: `${size}px`,
				}}
			>
				<Eyeglasses size={size * 0.75} weight="regular" />
			</div>
		);
	},
);

const ProfilePictureForMessage = observer(
	({
		message,
		className,
		size = 32,
	}: { message: MessageWithMetadata; className?: string; size: number }) => {
		return message.agent_type === "user" ? (
			message.user.user_image_url ? (
				<img
					src={message.user.user_image_url}
					alt={message.user.user_email || ""}
					className={cn("rounded", className)}
					style={{
						height: `${size}px`,
						width: `${size}px`,
					}}
				/>
			) : (
				<div
					className={cn("rounded bg-neutral-200", className)}
					style={{
						height: `${size}px`,
						width: `${size}px`,
					}}
				/>
			)
		) : (
			<AssistantProfilePicture
				sessionAssistantId={message.session_id as SessionAssistantId}
				className={className}
				size={size}
			/>
		);
	},
);

const ClickingIndicator = observer(({ className }: { className?: string }) => {
	const [scope, animate] = useAnimate();
	// biome-ignore lint/correctness/useExhaustiveDependencies: the selectors within the animate function are scoped to the ref defined by `scope`
	useEffect(() => {
		let isAnimating = true;
		const runSequence = async () => {
			while (isAnimating) {
				await animate([
					[
						".icon-cursor",
						{ opacity: 1.0, scale: 1.0 },
						{ at: 0.0, duration: 0.0 },
					],
					[".icon-cursor", { opacity: 1.0, scale: 1.0 }, { duration: 1.0 }],
					[".icon-cursor", { opacity: 1.0, scale: 0.8 }, { duration: 0.1 }],
					[".icon-cursor", { opacity: 0.0, scale: 0.8 }, { duration: 0.0 }],
					[".icon-cursor", { opacity: 0.0, scale: 0.8 }, { duration: 1.05 }],
					[
						".icon-cursor-click",
						{ opacity: 0.0, scale: 0.7 },
						{ at: 0.0, duration: 0.0 },
					],
					[
						".icon-cursor-click",
						{ opacity: 0.0, scale: 0.7 },
						{ duration: 1.1 },
					],
					[
						".icon-cursor-click",
						{ opacity: 1.0, scale: 0.7 },
						{ duration: 0.0 },
					],
					[
						".icon-cursor-click",
						{ opacity: 1.0, scale: 1.0 },
						{ duration: 0.1 },
					],
					[
						".icon-cursor-click",
						{ opacity: 1.0, scale: 1.0 },
						{ duration: 1.0 },
					],
				]);
			}
		};
		runSequence();
		return () => {
			isAnimating = false;
		};
	}, [scope, animate]);

	return (
		<div
			className={cn(
				"flex h-6 w-6 items-center justify-center rounded-full bg-black",
				className,
			)}
			ref={scope}
		>
			{/* This div should be the same size as the profile icon */}
			<div className="relative h-4 w-4">
				<Cursor
					size={16}
					className="icon-cursor absolute top-0 left-0 text-white"
					weight="fill"
				/>
				<CursorClick
					size={16}
					className="icon-cursor-click absolute top-0 left-0 text-white"
					weight="fill"
				/>
			</div>
		</div>
	);
});

const WaitingIndicator = observer(({ className }: { className?: string }) => {
	const [scope, animate] = useAnimate();
	// biome-ignore lint/correctness/useExhaustiveDependencies: the selectors within the animate function are scoped to the ref defined by `scope`
	useEffect(() => {
		let isAnimating = true;
		const runSequence = async () => {
			while (isAnimating) {
				await animate([
					[
						".icon-hourglass-high",
						{ opacity: 1.0 },
						{ at: 0.0, duration: 0.0 },
					],
					[".icon-hourglass-high", { opacity: 1.0 }, { duration: 1.0 }],
					[".icon-hourglass-high", { opacity: 0.0 }, { duration: 0.0 }],
					[".icon-hourglass-high", { opacity: 0.0 }, { duration: 2.0 }],
					[
						".icon-hourglass-medium",
						{ opacity: 0.0 },
						{ at: 0.0, duration: 0.0 },
					],
					[".icon-hourglass-medium", { opacity: 0.0 }, { duration: 1.0 }],
					[".icon-hourglass-medium", { opacity: 1.0 }, { duration: 0.0 }],
					[".icon-hourglass-medium", { opacity: 1.0 }, { duration: 1.0 }],
					[".icon-hourglass-medium", { opacity: 0.0 }, { duration: 0.0 }],
					[".icon-hourglass-medium", { opacity: 0.0 }, { duration: 1.0 }],
					[".icon-hourglass-low", { opacity: 0.0 }, { at: 0.0, duration: 0.0 }],
					[".icon-hourglass-low", { opacity: 0.0 }, { duration: 2.0 }],
					[".icon-hourglass-low", { opacity: 1.0 }, { duration: 0.0 }],
					[".icon-hourglass-low", { opacity: 1.0 }, { duration: 1.0 }],
				]);
			}
		};
		runSequence();
		return () => {
			isAnimating = false;
		};
	}, [scope, animate]);
	return (
		<div
			className={cn(
				"flex h-6 w-6 items-center justify-center rounded-full bg-black",
				className,
			)}
			ref={scope}
		>
			<div className="relative h-4 w-4">
				<HourglassHigh
					size={16}
					className="icon-hourglass-high absolute top-0 left-0 text-white"
					weight="fill"
				/>
				<HourglassMedium
					size={16}
					className="icon-hourglass-medium absolute top-0 left-0 text-white"
					weight="fill"
				/>
				<HourglassLow
					size={16}
					className="icon-hourglass-low absolute top-0 left-0 text-white"
					weight="fill"
				/>
			</div>
		</div>
	);
});

const ThinkingIndicator = observer(({ className }: { className?: string }) => {
	return (
		<div
			className={cn(
				"flex h-6 w-6 items-center justify-center rounded-full bg-black",
				className,
			)}
		>
			<motion.div
				className="relative h-4 w-4"
				animate={{ rotate: 360 }}
				transition={{
					duration: 1.5,
					ease: "linear",
					repeat: Number.POSITIVE_INFINITY,
				}}
			>
				<Spinner size={16} weight="bold" className="text-white" />
			</motion.div>
		</div>
	);
});

const TypingIndicator = observer(({ className }: { className?: string }) => {
	return (
		<div
			className={cn(
				"flex h-6 w-6 items-center justify-center rounded-full bg-black",
				className,
			)}
		>
			<div className="flex flex-col items-center justify-center">
				<div className="flex gap-[0.5px]">
					{[0, 1, 2].map((i) => (
						<motion.div
							key={i}
							className="h-[2.5px] w-[2.5px] rounded-full bg-white"
							animate={{ opacity: [0.4, 1, 0.4] }}
							transition={{
								duration: 1,
								repeat: Number.POSITIVE_INFINITY,
								delay: i * 0.3333,
								ease: "easeInOut",
							}}
						/>
					))}
				</div>
				<Keyboard size={14} weight="fill" className="-mt-[1px] text-white" />
			</div>
		</div>
	);
});

const StatusIndicatorMap: Record<AssistantStatus, React.ReactNode> = {
	clicking: <ClickingIndicator className="-top-2 -right-2 absolute" />,
	waiting: <WaitingIndicator className="-top-2 -right-2 absolute" />,
	thinking: <ThinkingIndicator className="-top-2 -right-2 absolute" />,
	typing: <TypingIndicator className="-top-2 -right-2 absolute" />,
};

/**
 * Shows the status of an activeassistant session.
 */
const _ActiveAssistantStatusIndicator = observer(
	({
		sessionAssistantId,
		status,
	}: { sessionAssistantId: SessionAssistantId; status: AssistantStatus }) => {
		return (
			<div className="relative h-fit w-fit">
				<AssistantProfilePicture
					sessionAssistantId={sessionAssistantId}
					size={32}
					className="rounded-full"
				/>
				{StatusIndicatorMap[status]}
			</div>
		);
	},
);

/**
 * Shows the status of an activeassistant session.
 */
export const ActiveAssistantStatusIndicator = observer(
	({ sessionAssistantId }: { sessionAssistantId: SessionAssistantId }) => {
		const appContext = useAppContext();
		const status =
			appContext.assistantSessionStore.activeAssistantSessionStatuses.get(
				sessionAssistantId,
			);
		if (!status) {
			console.error(
				"No status found for active assistant session",
				sessionAssistantId,
			);
			return null;
		}
		return (
			<_ActiveAssistantStatusIndicator
				sessionAssistantId={sessionAssistantId}
				status={status}
			/>
		);
	},
);

/**
 * Shows the message with metadata.
 */
const MessageWithMetadataComponent = observer(
	({ message }: { message: MessageWithMetadata }) => {
		const { user } = message;
		return (
			<div className="flex w-full gap-4 px-4">
				{/* Profile Picture */}
				<div className="flex-none pt-0.5">
					<ProfilePictureForMessage message={message} size={32} />
				</div>
				<div className="flex w-full min-w-0 grow flex-col">
					{/* Message Header */}
					<div className="flex justify-between text-sm">
						{/* Name */}
						<span>
							<span className="font-semibold">
								{message.agent_type === "user" ? (
									user.user_first_name ? (
										<>
											{user.user_first_name} {user.user_last_name || ""}
										</>
									) : (
										user.user_email
									)
								) : (
									"Assistant"
								)}
							</span>
							{message.agent_type === "assistant" && (
								<Tooltip>
									<TooltipTrigger>
										<ObjectLinkComponent
											pathObject={{
												path: "assistant-session",
												session_assistant_id:
													message.session_id as SessionAssistantId,
											}}
										>
											<span className="ml-2 border-0 bg-white text-neutral-500 hover:bg-neutral-100 hover:text-neutral-700">
												{message.session_id.slice(-4)}
											</span>
										</ObjectLinkComponent>
									</TooltipTrigger>
									<TooltipContent>View assistant activity</TooltipContent>
								</Tooltip>
							)}
						</span>
						{/* Timestamp */}
						<span className="text-neutral-500">
							{new Date(message.created_at).toLocaleString("en-US", {
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
							})}
						</span>
					</div>
					<MessageContent content={message.content} />
				</div>
			</div>
		);
	},
);

/**
 * A message with replies expanded; views a thread of messages.
 */
export const ThreadViewer = observer(() => {
	const appContext = useAppContext();
	const { messageId } = useParams<{ messageId: MessageId }>();
	if (!messageId) {
		console.error("Tried to render ThreadViewer without a messageId");
		appContext.rightSidebarState.navigateMessages(null);
		return null;
	}

	const thread = appContext.messageStore.getMessageByIdWithMetadata(messageId);

	const activeAssistantSessions = computed(() =>
		appContext.activeAssistantSessionsViewingThread(messageId),
	).get();

	return (
		<div className="relative flex h-full w-full flex-col overflow-y-auto">
			{/* Header */}
			<div className="absolute top-0 flex w-full flex-none items-center justify-between px-2">
				<div className="flex items-center gap-1">
					<Button
						className="h-min w-min items-start p-1"
						variant="ghost"
						onClick={() => {
							appContext.rightSidebarState.navigateMessages(null);
						}}
					>
						<ArrowLeft size={16} />
					</Button>
					<span className="font-semibold text-sm">Thread</span>
				</div>
				{/* Presence Indicators */}
				<div className="flex h-full w-full items-center justify-end gap-4 p-2">
					<div className="flex gap-1">
						{activeAssistantSessions.map((sessionAssistantId) => (
							<Tooltip key={sessionAssistantId}>
								<TooltipTrigger>
									<ActiveAssistantStatusIndicator
										sessionAssistantId={sessionAssistantId}
									/>
								</TooltipTrigger>
								<TooltipContent>
									Assistant {sessionAssistantId.slice(-4)} is viewing this
									thread
								</TooltipContent>
							</Tooltip>
						))}
					</div>
				</div>
			</div>
			<div className="mt-8 h-full min-h-0 w-full">
				{/* Main message */}
				<div className="flex w-full py-2">
					<MessageWithMetadataComponent message={thread} />
				</div>
				{/* Replies in thread */}
				{thread.replies.length > 0 && (
					<>
						<div className="flex w-full items-center gap-2 px-4">
							<span className="text-neutral-500 text-xs">
								{thread.replies.length}{" "}
								{thread.replies.length === 1 ? "reply" : "replies"}
							</span>
							<div className="h-[1px] grow bg-neutral-300" />
						</div>
						<div className="flex flex-col gap-2 pt-4">
							{thread.replies.map((reply) => (
								<MessageWithMetadataComponent
									key={reply.message_id}
									message={reply}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
});

const ThreadPreview = observer((props: { thread: ThreadRootMessage }) => {
	const appContext = useAppContext();
	const [isHovered, setIsHovered] = useState(false);
	const { refs, floatingStyles, context } = useFloating({
		placement: "top-end",
		open: isHovered,
		onOpenChange: setIsHovered,
		whileElementsMounted: autoUpdate,
		middleware: [offset({ mainAxis: -16, crossAxis: -8 })],
	});
	const hover = useHover(context, {
		handleClose: safePolygon(),
	});
	const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

	return (
		<div
			className={clsx(
				"flex w-full flex-col py-2",
				isHovered && "bg-neutral-100",
			)}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			<MessageWithMetadataComponent message={props.thread} />
			{props.thread.replies.length > 0 && (
				<Button
					variant="ghost"
					className="ml-[3.75rem] h-fit w-fit justify-start gap-1 rounded-sm px-2 py-1 font-normal text-neutral-600 hover:bg-white"
					onClick={() => {
						appContext.rightSidebarState.navigateMessages(
							props.thread.message_id,
						);
					}}
				>
					<ArrowBendDownRight size={16} />
					<span className="text-xs">
						{props.thread.replies.length}{" "}
						{props.thread.replies.length === 1 ? "reply" : "replies"}
					</span>
				</Button>
			)}
			<FloatingPortal>
				{isHovered && (
					<div
						ref={refs.setFloating}
						style={floatingStyles}
						{...getFloatingProps()}
						className="border bg-white p-1"
					>
						<Button
							variant="ghost"
							className="h-6 justify-start rounded-none px-1 py-0 font-normal text-sm"
							onClick={() => {
								appContext.rightSidebarState.navigateMessages(
									props.thread.message_id,
								);
							}}
						>
							Reply
						</Button>
					</div>
				)}
			</FloatingPortal>
		</div>
	);
});

/**
 * Message home. Shows all threads.
 */
export const Threads = observer(() => {
	const appContext = useAppContext();
	const messageStore = appContext.messageStore;
	const messagesEndRef = useRef<HTMLDivElement>(null);

	// const scrollToBottom = () => {
	// 	messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
	// };

	// // biome-ignore lint/correctness/useExhaustiveDependencies: scrollToBottom never changes, and we want to change it when openThread changes.
	// useEffect(() => {
	// 	scrollToBottom();
	// }, [appContext.session === null]);

	return (
		<>
			<div className="flex h-full flex-col overflow-y-auto">
				{messageStore.threads.map((thread) => (
					<ThreadPreview key={thread.message_id} thread={thread} />
				))}
			</div>
			<div ref={messagesEndRef} />
		</>
	);
});

export const MessageInput = observer(() => {
	const appContext = useAppContext();
	return (
		<div className="mx-2 flex grow flex-col border focus-within:border-blue-300">
			<CustomEditor
				className="w-full"
				options={{
					content: appContext.rightSidebarState.messageInputContent,
					editable: true,
					onUpdate: ({ editor }) => {
						appContext.rightSidebarState.setMessageInputContent(
							editor.getHTML(),
						);
					},
					editorProps: {
						attributes: {
							class:
								"text-sm min-h-32 max-h-96 w-full p-2 overflow-y-auto outline-none bg-white",
						},
					},
				}}
			/>
			<div className="flex w-full justify-end border-neutral-100 border-t bg-white">
				<Button
					className="m-1 h-8 w-8 p-0"
					variant="ghost"
					onClick={() => {
						const editor = appContext.rightSidebarState.messageInputContent;
						if (!editor) return;
						appContext.sendMessage({
							content: editor,
							parentMessageId:
								appContext.rightSidebarState.currentActiveMessageId,
						});
						// Clear content through state instead of editor commands
						appContext.rightSidebarState.setMessageInputContent("");
					}}
					disabled={!appContext.rightSidebarState.messageInputContent}
				>
					<PaperPlaneRight size={16} />
				</Button>
			</div>
		</div>
	);
});

export const Chat = observer(() => {
	const appContext = useAppContext();
	return (
		<RouterProvider
			router={appContext.rightSidebarState.messagesRouterForProvider}
		/>
	);
});
