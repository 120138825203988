import { getTabIcon } from "@/components/layout/tab-icons";
import { FolderTreeContextMenuWrapper } from "@/components/tree/context-menu";
import { DragOverlayComponent } from "@/components/tree/drag-overlay";
import {
	ROOT_DROPPABLE_ID,
	type TreeState,
} from "@/components/tree/tree-state";
import { Skeleton } from "@/components/ui/skeleton";
import type { AnyDirectoryNode } from "@/contexts/app-context/tree-handlers";
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	pointerWithin,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { createPortal } from "react-dom";

const FileNode = observer(
	(props: {
		node: AnyDirectoryNode;
		treeState: TreeState;
		depth: number; // Should this be on the tree somewhere?
	}) => {
		const isExpanded = props.treeState.expandedNodes.has(props.node.id);
		const isSelected = props.treeState.selectedNodes.has(props.node.id);
		const isMultiSelected =
			isSelected && props.treeState.selectedNodes.size > 1;
		props.treeState.descendantsOfDraggingNodes.has(props.node.id);
		const Icon = getTabIcon(props.node.file);
		const isRightClicked =
			props.treeState.rightClickedNode?.id === props.node.id;

		// TODO(John): for some reason, hovering is flickering whenever it's a drop target
		// We'll get around this by combining it with isOver.
		const [isHover, setIsHover] = useState(false);

		const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
			id: props.node.id,
			disabled: props.treeState.isDndEnabled,
			data: {
				node: props.node,
			},
		});

		const {
			setNodeRef: setDraggableNodeRef,
			listeners,
			attributes,
		} = useDraggable({
			id: props.node.id,
			data: {
				node: props.node,
			},
			disabled: props.treeState.isDndEnabled,
		});

		let bgColor: string;
		if (isMultiSelected) {
			if (isOver) {
				bgColor = "bg-blue-200";
			} else if (isHover) {
				bgColor = "bg-blue-100";
			} else {
				bgColor = "bg-blue-50";
			}
		} else if (isSelected) {
			if (isOver) {
				bgColor = "bg-blue-200";
			} else if (isHover) {
				bgColor = "bg-blue-100";
			} else {
				bgColor = "bg-blue-100";
			}
		} else {
			if (isOver) {
				bgColor = "bg-neutral-200";
			} else if (isHover) {
				bgColor = "bg-neutral-100";
			} else {
				bgColor = "bg-white";
			}
		}

		return (
			<>
				<div
					className={clsx(
						"flex h-8 items-center px-2",
						bgColor,
						props.treeState.draggingNodes.size > 0 && "cursor-default",
						isRightClicked &&
							"-outline-offset-1 outline outline-1 outline-blue-300",
					)}
					onClick={(e) => props.treeState.handleNodeClick(e, props.node)}
					onContextMenu={() => {
						props.treeState.setRightClickedNode(props.node);
					}}
					ref={(el) => {
						setDraggableNodeRef(el);
						setDroppableNodeRef(el);
					}}
					{...listeners}
					{...attributes}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					{(new Array(props.depth).fill(0) as number[]).map((_, i) => (
						<div
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={i}
							className="ml-2 h-8 w-3 shrink-0 border-l"
						/>
					))}
					{props.node.children.length > 0 && (isHover || isOver) ? (
						<CaretRight
							weight="regular"
							className={clsx(
								"h-4 w-4 shrink-0 bg-white text-neutral-500 hover:bg-neutral-200 hover:text-neutral-950",
								isExpanded && "rotate-90",
							)}
							onClick={(e) => {
								props.treeState.handleExpandClick(props.node);
								e.stopPropagation();
							}}
						/>
					) : (
						<Icon className="h-4 w-4 shrink-0 text-neutral-500" />
					)}
					<h2
						className={clsx(
							"ml-2 w-full min-w-0 select-none truncate text-neutral-800 text-sm",
						)}
					>
						{props.node.file.file_name}
					</h2>
				</div>
				{isExpanded &&
					props.node.children.map((node) => (
						<FileNode
							key={node.id}
							node={node}
							treeState={props.treeState}
							depth={props.depth + 1}
						/>
					))}
			</>
		);
	},
);

/**
 * Tree component that displays folders.
 */
const FolderTreeNodes = observer((props: { treeState: TreeState }) => {
	const { setNodeRef: setDroppableNodeRef } = useDroppable({
		id: ROOT_DROPPABLE_ID,
		disabled: props.treeState.isDndEnabled,
	});

	return (
		<div ref={setDroppableNodeRef} className={clsx("h-full bg-white")}>
			{props.treeState.visibleNodes.map((node) => (
				<FileNode
					key={node.id}
					node={node}
					treeState={props.treeState}
					depth={0}
				/>
			))}
		</div>
	);
});

export const FolderTree = observer(
	(props: {
		treeState: TreeState;
	}) => {
		const mouseSensor = useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		});
		const touchSensor = useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		});
		const sensors = useSensors(mouseSensor, touchSensor);

		if (props.treeState.appState.workspace === null) {
			return (
				<div className="flex flex-col gap-1.5 p-3">
					<Skeleton className="h-8 w-full" />
					<Skeleton className="h-8 w-full" />
					<Skeleton className="h-8 w-full" />
					<Skeleton className="h-8 w-full" />
					<Skeleton className="h-8 w-full" />
					<Skeleton className="h-8 w-full" />
				</div>
			);
		}

		return (
			<DndContext
				collisionDetection={pointerWithin}
				sensors={sensors}
				onDragStart={props.treeState.handleDragStart}
				onDragOver={props.treeState.handleDragOver}
				onDragEnd={props.treeState.handleDragEnd}
			>
				<FolderTreeContextMenuWrapper treeState={props.treeState}>
					<FolderTreeNodes treeState={props.treeState} />
					{createPortal(
						<DragOverlayComponent treeState={props.treeState} />,
						document.body,
					)}
				</FolderTreeContextMenuWrapper>
			</DndContext>
		);
	},
);
