import type { AppState } from "@/contexts/app-context/app-context";
import { createContext, useContext } from "react";

// Note(John): for hot module reload, the context needs to be created in a
// separate file than the provider. See
// https://github.com/vitejs/vite/issues/3301
// TODO(John): move around context files; the AppState should not live in the AppContext.
export const AppContext = createContext<AppState | null>(null);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within an AppProvider");
	}

	return context;
};
