import type { AppState } from "@/contexts/app-context/app-context";
import type {
	AssistantStatus,
	SessionAssistant,
	SessionAssistantId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";

export interface ActiveSessionWithStatus {
	assistantSession: SessionAssistant;
	status: AssistantStatus;
}

export class AssistantSessionStore {
	appState: AppState;

	assistantSessions: Map<SessionAssistantId, SessionAssistant> = new Map();
	activeAssistantSessionStatuses: Map<SessionAssistantId, AssistantStatus> =
		new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	// Mutations
	createSessionAssistantLocally = (assistantSession: SessionAssistant) => {
		this.assistantSessions.set(
			assistantSession.session_assistant_id,
			assistantSession,
		);
	};

	// Queries
	getAssistantSessionById(sessionAssistantId: SessionAssistantId) {
		return this.assistantSessions.get(sessionAssistantId) ?? null;
	}

	get activeSessionsWithStatus(): ActiveSessionWithStatus[] {
		return Array.from(this.activeAssistantSessionStatuses.entries()).map(
			([sessionAssistantId, status]) => {
				const assistantSession = this.assistantSessions.get(sessionAssistantId);
				if (!assistantSession) {
					throw new Error("Assistant session not found");
				}
				return {
					assistantSession,
					status,
				};
			},
		);
	}
}
