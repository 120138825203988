import { getTabIcon } from "@/components/layout/tab-icons";
import type { TreeState } from "@/components/tree/tree-state";
import { ROOT_DROPPABLE_ID } from "@/components/tree/tree-state";
import { DragOverlay, type Modifier } from "@dnd-kit/core";
import { getEventCoordinates } from "@dnd-kit/utilities";
import { Files } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";

const snapTopLeftToCursor: Modifier = ({
	activatorEvent,
	draggingNodeRect,
	transform,
}) => {
	if (draggingNodeRect && activatorEvent) {
		const activatorCoordinates = getEventCoordinates(activatorEvent);

		if (!activatorCoordinates) {
			return transform;
		}

		const offsetX = activatorCoordinates.x - draggingNodeRect.left;
		const offsetY = activatorCoordinates.y - draggingNodeRect.top;

		return {
			...transform,
			x: transform.x + offsetX,
			y: transform.y + offsetY,
		};
	}

	return transform;
};
interface DragOverlayProps {
	treeState: TreeState;
}

const getLabel = (treeState: TreeState): string => {
	const { dropOverNode, descendantsOfDraggingNodes } = treeState;
	if (dropOverNode === null) {
		return "";
	}
	if (dropOverNode === ROOT_DROPPABLE_ID) {
		return "Move to Home";
	}
	if (descendantsOfDraggingNodes.has(dropOverNode.id)) {
		return "";
	}
	return `Move into "${dropOverNode.file.file_name}"`;
};

export const DragOverlayComponent: React.FC<DragOverlayProps> = observer(
	({ treeState }) => {
		const { draggingNodes } = treeState;
		const label = getLabel(treeState);

		let overlayContent: ReactNode = null;

		if (draggingNodes.size === 1) {
			const node = Array.from(draggingNodes.values())[0];
			const Icon = getTabIcon(node.file);
			overlayContent = (
				<>
					<Icon className="h-4 w-4 shrink-0 text-neutral-500" />
					<span className="text-sm">{node.file.file_name}</span>
				</>
			);
		} else if (draggingNodes.size > 1) {
			overlayContent = (
				<>
					<Files weight="bold" className="h-4 w-4 shrink-0 text-neutral-500" />
					<span className="text-sm">{`${draggingNodes.size} files`}</span>
				</>
			);
		}

		return (
			<DragOverlay
				className="flex flex-col gap-2 border border-neutral-300 bg-neutral-50 p-1"
				style={{
					height: "fit-content",
					width: "fit-content",
				}}
				modifiers={[snapTopLeftToCursor]}
				dropAnimation={null}
			>
				<div className="flex items-center gap-2">{overlayContent}</div>
				{label && <span className="text-neutral-600 text-xs">{label}</span>}
			</DragOverlay>
		);
	},
);
