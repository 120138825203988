import flexsearch from "flexsearch";
import { makeAutoObservable } from "mobx";
/**
 * Generic class for storing items in a Mobx map while indexing them for search.
 *
 * @template T - The type of the items to store.
 * @template K - The type of the keys for the items.
 */
export class SearchableMap<T, K extends keyof T> {
	protected items: Map<T[K], T>;
	protected searchIndex: flexsearch.Document<T>;

	constructor(
		items: T[],
		indexConfig: {
			id: K;
			fields: (keyof T)[];
		},
	) {
		this.items = new Map(items.map((item) => [item[indexConfig.id], item]));
		this.searchIndex = new flexsearch.Document({
			tokenize: "full",
			document: {
				id: indexConfig.id as string,
				index: indexConfig.fields as string[],
			},
		});

		for (const item of items.values()) {
			this.searchIndex.add(item);
		}
		makeAutoObservable(this);
	}

	set(id: T[K], item: T): void {
		this.items.set(id, item);
		this.searchIndex.add(item);
	}

	get(id: T[K]): T | undefined {
		return this.items.get(id);
	}

	delete(id: T[K]): void {
		this.items.delete(id);
		this.searchIndex.remove(id as string);
	}

	get values(): T[] {
		// for this to work with mobx, we need to make it a computed property and return an array instead of an iterator
		return Array.from(this.items.values());
	}

	search(query: string): T[K][] {
		return this.searchIndex
			.search(query)
			.flatMap((result) => result?.result ?? []) as T[K][];
	}
}
