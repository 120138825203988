import type { TabType } from "@/contexts/tabs-context/tabs-context";
import type { File } from "@api/schemas";
import {
	// File as FileIcon,
	FilePdf,
	Folder,
	Globe,
	type Icon,
	MagnifyingGlass,
	Rss,
	Table,
} from "@phosphor-icons/react";

export const TabIconMap: Record<TabType, Icon> = {
	folder: Folder,
	upload: FilePdf,
	search: MagnifyingGlass,
	"feed-channel": Rss,
	"feed-item": Rss,
	table: Table,
	"manage-tables": Table,
	"manage-feeds": Rss,
	"web-search": Globe,
};

export const getTabIcon: (file: File) => Icon = (file) => {
	// if (file.file_type === "table") {
	// 	return file.table_is_page ? FileIcon : Table;
	// }
	return TabIconMap[file.file_type];
};
