import type {
	ChunkSearchResult,
	FileResult,
	SearchId,
	UploadMetadata,
} from "@api/schemas";

import { IS_DEV } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { createNewSearchId } from "@/lib/id-generators";
import { searchRoute } from "@api/fastAPI";
import {
	type BaseSearchResponse,
	type FeedChannelId,
	TextSearchMode,
	type UploadId,
} from "@api/schemas";
import { Circuitry, Key } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable } from "mobx";
import { toast } from "sonner";

// TODO(Tae): Implement in backend and remove these
interface SearchSynthesis {
	search_id: SearchId;
	synthesis: string;
}

export interface SearchLibraryResultWithUpload extends ChunkSearchResult {
	upload: UploadMetadata;
}

export const SearchModeMeta: Record<
	TextSearchMode,
	{
		title: string;
		description: string;
		icon: React.ReactNode;
	}
> = {
	[TextSearchMode.keyword]: {
		title: "Keyword",
		description: "Matches exact quotes and phrases",
		icon: <Key className=" text-neutral-500" weight="duotone" />,
	},
	[TextSearchMode.semantic]: {
		title: "Semantic",
		description: "Finds content with similar meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
	[TextSearchMode.hybrid]: {
		title: "Neural",
		description: "Finds content based on meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
};

// TODO(Tae): Unify with SearchPathObject
export class SavedSearchConfig {
	search_id: SearchId;
	config: SearchConfig;

	constructor(search_id: SearchId, config: SearchConfig) {
		this.search_id = search_id;
		this.config = config;
		makeAutoObservable(this);
	}
}

class SearchConfig {
	query = "";
	search_mode: TextSearchMode = TextSearchMode.hybrid;
	include_library = true;
	included_upload_ids: UploadId[] = [];
	include_feeds = true;
	included_feed_channel_ids: FeedChannelId[] = [];

	constructor() {
		makeAutoObservable(this);
	}
}

export class SearchFormData {
	config: SearchConfig = new SearchConfig();

	constructor() {
		makeAutoObservable(this);
	}
}

export class SearchResultLocal {
	searchConfig: SavedSearchConfig;
	synthesis: string | null;
	searchResultsMap: Map<string, FileResult> = new Map();
	searchComplete = false;

	constructor(searchConfig: SavedSearchConfig) {
		this.searchConfig = searchConfig;
		// TODO: This is a temporary placeholder until we have synthesis
		this.synthesis = null;
		makeAutoObservable(this);
	}

	get loadingState() {
		if (!this.searchComplete) {
			return "searching";
		}
		if (this.synthesis === null) {
			return "synthesizing";
		}
		return "completed";
	}

	get searchResults() {
		return Array.from(this.searchResultsMap.values()).sort(
			(a, b) => b.score - a.score,
		);
	}

	fromResponse(response: BaseSearchResponse) {
		this.searchComplete = true;
		this.searchResultsMap = new Map(
			response.results.map((result) => [result.file_id, result]),
		);
	}

	handleSynthesisUpdate(update: SearchSynthesis) {
		this.synthesis = update.synthesis;
	}
}

export class SearchStore {
	appState: AppState;
	searchHistory: Map<string, SearchResultLocal> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	getOrInitiateSearchResult(
		savedSearchConfig: SavedSearchConfig,
	): SearchResultLocal | null {
		const searchId = savedSearchConfig.search_id;
		if (!this.searchHistory.has(searchId)) {
			// Initialize search result w/ loading
			const searchState = new SearchResultLocal(savedSearchConfig);
			this.searchHistory.set(searchId, searchState);

			// Fetch and initialize the table
			this.initiateSearch(savedSearchConfig);
			return null;
		}
		return this.searchHistory.get(searchId) as SearchResultLocal;
	}

	async initiateSearch(savedSearchConfig: SavedSearchConfig) {
		searchRoute({
			search_id: savedSearchConfig.search_id,
			query: savedSearchConfig.config.query,
			search_mode: savedSearchConfig.config.search_mode,
			include_library: savedSearchConfig.config.include_library ?? true,
			included_upload_ids: savedSearchConfig.config.included_upload_ids ?? [],
			include_feeds: savedSearchConfig.config.include_feeds ?? true,
			included_feed_channel_ids:
				savedSearchConfig.config.included_feed_channel_ids ?? [],
		})
			.then((res) => {
				const searchResult = this.searchHistory.get(
					savedSearchConfig.search_id,
				);
				if (!searchResult) {
					return;
				}
				searchResult.fromResponse(res.data);
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to fetch search results");
				}
			});
	}

	handleSynthesisUpdate(update: SearchSynthesis) {
		const searchId = update.search_id;
		const searchResult = this.searchHistory.get(searchId);
		if (!searchResult) {
			return;
		}
		searchResult.handleSynthesisUpdate(update);
	}

	get uniqueSearchHistory() {
		return Array.from(this.searchHistory.values());
	}
}

export class SearchState {
	appState: AppState;
	type = "search" as const;

	// When you load a search result
	savedSearchConfig: SavedSearchConfig | null = null;

	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;
	searchFormData: SearchFormData = new SearchFormData();

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	constructor(appState: AppState, savedSearchConfig: SavedSearchConfig | null) {
		this.appState = appState;
		this.savedSearchConfig = savedSearchConfig;
		makeAutoObservable(this);
	}

	handleSearch() {
		const searchId = createNewSearchId();
		this.appState.tabStore.navigate(
			{
				path: "search",
				search_id: searchId,
			},
			{
				state: this.searchFormData,
			},
		);
	}
}
