import {
	SelectOptionBackgroundColors,
	SelectOptionBorderColors,
	chooseRandomSelectOptionColor,
} from "@/components/table/cell/select-colors";
import { FieldTypeIcons } from "@/components/table/field-type-indicators";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTableViewContext } from "@/contexts/tabs-context/use-table-context";
import { capitalizeFirstLetter } from "@/lib/formatting";
import {
	type Field,
	type FieldId,
	type FieldType,
	type RecordId,
	type SelectField,
	type SelectOption,
	SelectOptionColor,
	type SelectOptionLabel,
} from "@api/schemas";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import {
	Code,
	DotsSixVertical,
	DotsThree,
	Palette,
	Pencil,
	Plus,
	Trash,
} from "@phosphor-icons/react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
	type ColumnDef,
	type Header,
	createColumnHelper,
	flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { toast } from "sonner";

export const ADD_COLUMN_ID = "ADD_COLUMN";
export const SELECT_COLUMN_ID = "SELECT_COLUMN";

export const RenderColumnIcon = (fieldType: FieldType) => {
	const ColumnIcon = FieldTypeIcons[fieldType];
	return <ColumnIcon className="shrink-0 text-neutral-500" />;
};

export type MyRowType = { recordId: RecordId };
export const columnHelper = createColumnHelper<MyRowType>();

export const selectorColumn = (
	isComputedTable: boolean,
): ColumnDef<MyRowType> =>
	columnHelper.display({
		id: SELECT_COLUMN_ID,
		enableResizing: false,
		header: ({ table }) => {
			const tableViewState = useTableViewContext();
			<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
				<Checkbox
					disabled={!tableViewState.editable}
					checked={
						table.getIsAllRowsSelected()
							? true
							: table.getIsSomeRowsSelected()
								? "indeterminate"
								: false
					}
					onCheckedChange={(checked) =>
						table.toggleAllRowsSelected(checked === true)
					}
				/>
			</div>;
		},
		cell: ({ row }) => {
			const tableViewState = useTableViewContext();
			const { attributes, listeners, setNodeRef } = useDraggable({
				id: row.original.recordId,
				disabled: !tableViewState.editable,
				// No need to pass selectedRowIds here since we access them from state directly
			});

			return (
				<div
					className="flex h-full min-h-8 w-full items-center justify-between gap-0.5 bg-white pr-2 pl-1.5"
					onClick={(e) => {
						e.stopPropagation();
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.stopPropagation();
						}
					}}
				>
					{isComputedTable ? null : (
						<Tooltip>
							<TooltipTrigger asChild>
								<button
									type="button"
									className={clsx(
										"flex rounded p-0.5 text-base text-neutral-400 opacity-0",
										tableViewState.editable &&
											"hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100",
									)}
									onClick={() => {
										tableViewState.tableState.addRecords({
											precedingRecordId: row.original.recordId,
										});
									}}
								>
									<Plus weight="bold" />
								</button>
							</TooltipTrigger>
							<TooltipContent>Insert row below</TooltipContent>
						</Tooltip>
					)}
					<Tooltip>
						<TooltipTrigger asChild>
							<button
								className={clsx(
									"flex rounded text-lg text-neutral-400 opacity-0",
									tableViewState.editable &&
										"hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100",
								)}
								ref={setNodeRef}
								{...attributes}
								{...listeners}
							>
								<DotsSixVertical weight="bold" />
							</button>
						</TooltipTrigger>
						<TooltipContent>Drag to reorder</TooltipContent>
					</Tooltip>
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(checked) => row.toggleSelected(checked === true)}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			);
		},
		size: isComputedTable ? 54 : 72,
		maxSize: isComputedTable ? 54 : 72,
	});

const SelectOptionEditor = observer(
	({ option, fieldId }: { option: SelectOption; fieldId: FieldId }) => {
		const tableViewState = useTableViewContext();
		const [editing, setEditing] = useState(false);
		const [newLabel, setNewLabel] = useState(option.label);
		const inputRef = useRef<HTMLInputElement>(null);

		const renameOption = () => {
			if (newLabel === option.label) {
				setEditing(false);
				return;
			}

			if (newLabel.trim().length === 0) {
				setNewLabel(option.label);
				setEditing(false);
				toast.error("Option label can't be empty");
				return;
			}

			tableViewState.tableState.updateSelectOptionLabel({
				fieldId,
				oldLabel: option.label,
				newLabel,
			});
			setEditing(false);
		};

		const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setEditing(false);
				return;
			}
			if (e.key === "Enter") {
				renameOption();
			}
		};

		return (
			<AlertDialog>
				<div
					key={option.label}
					className="flex items-center justify-between gap-2 px-1 py-1"
				>
					{editing ? (
						<Input
							value={newLabel}
							onChange={(e) => setNewLabel(e.target.value as SelectOptionLabel)}
							onKeyDown={onKeydown}
							onBlur={renameOption}
							ref={inputRef}
						/>
					) : (
						<Badge
							variant="outline"
							className={clsx(
								SelectOptionBackgroundColors[option.color],
								SelectOptionBorderColors[option.color],
								"text-neutral-700",
							)}
						>
							{option.label}
						</Badge>
					)}
					<DropdownMenu>
						<DropdownMenuTrigger className="shrink-0 rounded p-1 hover:bg-neutral-100">
							<DotsThree weight="bold" />
						</DropdownMenuTrigger>
						<DropdownMenuContent
							align="end"
							// Prevent the menu from focusing back on the trigger when it closes,
							// so we can keep the focus on the input field
							onCloseAutoFocus={(e) => e.preventDefault()}
						>
							<DropdownMenuItem
								className="flex items-center gap-2 text-neutral-500"
								onClick={(e) => {
									setEditing(true);
									setTimeout(() => {
										inputRef.current?.focus();
									}, 0);
									e.stopPropagation();
								}}
							>
								<Pencil className="text-lg" /> Rename
							</DropdownMenuItem>

							<DropdownMenuSub>
								<DropdownMenuSubTrigger className="flex items-center gap-2 text-neutral-500">
									<Palette className="text-lg" /> Change color
								</DropdownMenuSubTrigger>
								<DropdownMenuPortal>
									<DropdownMenuSubContent>
										{Object.values(SelectOptionColor).map((color) => (
											<DropdownMenuItem
												key={color}
												className="gap-2"
												onClick={() => {
													tableViewState.tableState.updateSelectOptionColor({
														fieldId,
														label: option.label,
														color,
													});
												}}
											>
												<div
													className={clsx(
														"h-4 w-4 rounded-full",
														SelectOptionBackgroundColors[color],
														SelectOptionBorderColors[color],
													)}
												/>
												{capitalizeFirstLetter(color)}
											</DropdownMenuItem>
										))}
									</DropdownMenuSubContent>
								</DropdownMenuPortal>
							</DropdownMenuSub>

							<AlertDialogTrigger asChild>
								<DropdownMenuItem
									className="flex items-center gap-2 text-neutral-500"
									onClick={() => {
										// TODO: Implement this
										// tableContext.removeColumnCategory({
										// 	columnId,
										// 	categoryToRemove: category.value,
										// });
									}}
								>
									<Trash className="text-lg" /> Delete
								</DropdownMenuItem>
							</AlertDialogTrigger>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				{/* Deletion confirmation dialog */}
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete option?</AlertDialogTitle>
						<AlertDialogDescription>
							This will permanently delete the option from the column. Cells
							with this option will be set to empty.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel className="w-1/2">Cancel</AlertDialogCancel>
						<AlertDialogAction className="w-1/2">
							Delete option
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const SelectOptionFieldEditor = observer(
	({
		selectField,
	}: {
		selectField: SelectField;
	}) => {
		const [newLabel, setNewLabel] = useState("");
		const [showAddOption, setShowAddOption] = useState(false);
		const tableViewState = useTableViewContext();

		const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setShowAddOption(false);
				return;
			}

			if (e.key === "Enter") {
				tableViewState.tableState.addSelectOption({
					fieldId: selectField.field_id,
					newOption: {
						label: newLabel as SelectOptionLabel,
						color: chooseRandomSelectOptionColor(),
					},
				});

				setNewLabel("");
			}
		};

		return (
			<div className="mt-2">
				<div className="flex items-center justify-between">
					<Label className="text-sm">Options</Label>
					{!showAddOption && (
						<button
							type="button"
							className="flex w-full max-w-max items-center gap-2 rounded-md p-2 text-sm hover:bg-neutral-100"
							onClick={() => setShowAddOption(true)}
						>
							<Plus />
						</button>
					)}
				</div>
				{showAddOption && (
					<Input
						value={newLabel}
						onChange={(e) => setNewLabel(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder="Add an option..."
					/>
				)}
				<div className="mt-1">
					{Object.values(selectField.properties.options)
						.slice()
						.sort()
						.map((option) => (
							<SelectOptionEditor
								key={option.label}
								option={option}
								fieldId={selectField.field_id}
							/>
						))}
					{!showAddOption && (
						<Button
							variant="outline"
							className="flex w-full items-center gap-2 text-sm"
							onClick={() => setShowAddOption(true)}
						>
							<Plus />
							Add an option
						</Button>
					)}
				</div>
			</div>
		);
	},
);

export const DeleteColumnDialogContent = observer(
	({
		fieldId,
	}: {
		fieldId: FieldId;
	}) => {
		const tableViewState = useTableViewContext();

		return (
			<>
				<AlertDialogHeader>
					<AlertDialogTitle>Delete field</AlertDialogTitle>
					<AlertDialogDescription>
						This will permanently delete the field and its cells. Are you sure
						you want to proceed?
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel className="w-1/2">Cancel</AlertDialogCancel>
					<AlertDialogAction
						onClick={() => {
							tableViewState.tableState.deleteField({
								fieldId,
							});
						}}
						className="w-1/2"
					>
						Delete field
					</AlertDialogAction>
				</AlertDialogFooter>
			</>
		);
	},
);

// TODO(John): this should eventually have one for each field type...?
export const columnHeader = ({ field }: { field: Field }) =>
	observer(() => {
		const tableViewState = useTableViewContext();

		const [fieldName, setFieldName] = useState(field.name);
		const displayedFieldName = field.name || "Untitled";

		return (
			<Popover>
				<PopoverTrigger asChild disabled={!tableViewState.editable}>
					<button
						type="button"
						className={clsx(
							"flex h-full w-full select-none items-center gap-2 truncate px-1.5 py-1 font-normal hover:bg-neutral-100",
						)}
					>
						<span className="flex shrink-0 grow items-center gap-1.5 truncate">
							{RenderColumnIcon(field.type)}
							{/* TODO(John): text should be lighter if untitled */}
							<span className="truncate text-neutral-600">
								{displayedFieldName}
							</span>
						</span>
					</button>
				</PopoverTrigger>

				<PopoverContent align="start" className="w-72 p-2">
					{tableViewState.devMode && (
						<button
							type="button"
							onClick={() => {
								navigator.clipboard.writeText(field.field_id);
								toast.success("Field ID copied to clipboard");
							}}
							className="mb-2 flex max-w-max items-center gap-1 break-all rounded-md border border-neutral-200 bg-neutral-100 px-1 py-0.5 text-neutral-600 text-xs"
						>
							<Code className="text-lg" /> {field.field_id}
						</button>
					)}

					<div className="">
						<Label>Label</Label>
						<Input
							value={fieldName}
							onChange={(e) => setFieldName(e.target.value)}
							onBlur={() => {
								if (fieldName === field.name) {
									return;
								}
								if (fieldName.trim().length === 0) {
									setFieldName(field.name);
									toast.error("Field name can't be empty");
									return;
								}
								tableViewState.tableState.updateField({
									fieldId: field.field_id,
									newName: fieldName,
								});
							}}
							placeholder="Label..."
						/>
					</div>
					<div>
						{field.type === "select" && (
							<SelectOptionFieldEditor selectField={field} />
						)}
					</div>

					<DropdownMenuSeparator className="mt-4" />

					<AlertDialog>
						<AlertDialogTrigger
							onClick={(e) => {
								e.stopPropagation();
							}}
							asChild
						>
							<Button
								variant="outline"
								className="mt-2 flex w-full items-center gap-2 text-neutral-700"
							>
								<Trash />
								Delete column
							</Button>
						</AlertDialogTrigger>
						<AlertDialogContent>
							<DeleteColumnDialogContent fieldId={field.field_id} />
						</AlertDialogContent>
					</AlertDialog>
				</PopoverContent>
			</Popover>
		);
	});

export const DraggableHeader = observer(
	({
		header,
		overId,
		draggedColumnId,
	}: {
		header: Header<MyRowType, unknown>;
		overId: FieldId | null;
		draggedColumnId: FieldId | null;
	}) => {
		const { setNodeRef: setDropNodeRef } = useDroppable({
			id: header.column.id,
		});

		const tableViewState = useTableViewContext();

		const {
			attributes,
			listeners,
			setNodeRef: setDragNodeRef,
		} = useDraggable({
			id: header.column.id,
			disabled: !tableViewState.editable,
		});

		const isDragging = draggedColumnId === header.column.id;
		const isPinned = header.column.getIsPinned();

		// Disallow dragging the select and 'create column' headers
		const isReorderable = !isPinned && header.column.id !== ADD_COLUMN_ID;

		return (
			<>
				{overId === header.column.id && (
					<div
						key={`drop-indicator-${header.column.id}`}
						className="relative z-50 h-8 w-0 p-0 ring-1 ring-blue-500"
					/>
				)}
				<div
					aria-label="th"
					className={clsx(
						"relative z-10 h-8 shrink-0 whitespace-nowrap border-neutral-100 border-b bg-white p-0 text-left font-semibold text-sm",
						header.column.id !== ADD_COLUMN_ID && "border-r",
						isDragging && "bg-blue-50 opacity-50",
					)}
					style={{
						width: `calc(var(--header-${header.id}-size) * 1px)`,
						position: isPinned ? "sticky" : "relative",
						zIndex: isPinned ? 10 : 1,
						left: isPinned ? `${header.column.getStart("left")}px` : 0,
					}}
					ref={isReorderable ? setDropNodeRef : undefined}
				>
					<div
						className="relative z-10 flex h-full min-w-0 grow"
						ref={isReorderable ? setDragNodeRef : undefined}
						{...attributes}
						{...listeners}
					>
						{header.isPlaceholder
							? null
							: flexRender(header.column.columnDef.header, header.getContext())}
					</div>
					{tableViewState.editable && isReorderable ? (
						<button
							{...{
								onDoubleClick: () => header.column.resetSize(),
								onMouseDown: header.getResizeHandler(),
								onTouchStart: header.getResizeHandler(),
								className: `h-full cursor-ew-resize	 absolute right-0 z-20 w-1 top-0 bg-neutral-500 opacity-0 hover:opacity-100 ${
									header.column.getIsResizing() ? "opacity-100" : ""
								}`,
							}}
						/>
					) : null}
				</div>
			</>
		);
	},
);

export const ColumnCreationPopoverTrigger = () => {
	return (
		<PopoverTrigger asChild>
			<button type="button" className="rounded-md p-1.5 hover:bg-neutral-100">
				<Plus className="text-lg " />
			</button>
		</PopoverTrigger>
	);
};
