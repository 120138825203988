import type { AppState } from "@/contexts/app-context/app-context";
import type { AnyDirectoryNode } from "@/contexts/app-context/tree-handlers";
import type { FileId } from "@api/schemas";
import type {
	DragEndEvent,
	DragOverEvent,
	DragStartEvent,
} from "@dnd-kit/core";
import { makeAutoObservable } from "mobx";
import type { MouseEvent as ReactMouseEvent } from "react";

export const ROOT_DROPPABLE_ID = "root";

export class TreeState {
	appState: AppState;

	isDndEnabled: boolean;
	isMultiSelect: boolean;

	#getVisibleNodes: () => AnyDirectoryNode[];
	// Function to handle navigation to a node, e.g. opening a folder or
	// a file.
	#handleNodeNavigation: (node: AnyDirectoryNode) => void;

	rightClickedNode: AnyDirectoryNode | null = null;
	selectedNodes: Map<FileId, AnyDirectoryNode> = new Map();
	expandedNodes: Map<FileId, AnyDirectoryNode> = new Map();
	draggingNodes: Map<FileId, AnyDirectoryNode> = new Map();

	dropOverNode: AnyDirectoryNode | typeof ROOT_DROPPABLE_ID | null = null;

	searchQuery = "";

	// We take in a function because MobX needs property access to track
	// observables
	constructor(props: {
		appState: AppState;
		getData: () => AnyDirectoryNode[];
		handleNodeNavigation: (node: AnyDirectoryNode) => void;
		isDndEnabled: boolean;
		isMultiSelect: boolean;
	}) {
		this.appState = props.appState;
		this.#getVisibleNodes = props.getData;
		this.#handleNodeNavigation = props.handleNodeNavigation;
		this.isDndEnabled = props.isDndEnabled;
		this.isMultiSelect = props.isMultiSelect;
		makeAutoObservable(this);
	}

	get searchMatchFileIds(): Set<FileId> {
		const searchResults = this.appState.searchFiles(this.searchQuery);
		return new Set([
			...searchResults.uploads.map((upload) => upload.upload_id),
			...searchResults.feedItems.map((feedItem) => feedItem.feed_item_id),
			...searchResults.tables.map((table) => table.table_id),
			...searchResults.folders.map((folder) => folder.folder_id),
		]);
	}

	get visibleNodes(): AnyDirectoryNode[] {
		if (this.searchQuery) {
			return this.#getVisibleNodes().filter((node) =>
				this.searchMatchFileIds.has(node.id),
			);
		}
		return this.#getVisibleNodes();
	}

	setRightClickedNode = (node: AnyDirectoryNode | null) => {
		this.rightClickedNode = node;
	};

	/**
	 * Creates map of all visible nodes in depth-first traversal order.
	 * Also tracks the index of the node in the list.
	 *
	 * Used for contiguous selection.
	 */
	get flattenedVisibleNodes(): AnyDirectoryNode[] {
		function getFlattenedNodes(root: AnyDirectoryNode): AnyDirectoryNode[] {
			const flattenedNodes: AnyDirectoryNode[] = [];
			flattenedNodes.push(root);
			for (const child of root.children) {
				const childFlattenedNodes = getFlattenedNodes(child);
				flattenedNodes.push(...childFlattenedNodes);
			}
			return flattenedNodes;
		}

		const flattenedTree: AnyDirectoryNode[] = [];
		for (const parent of this.visibleNodes) {
			const flattenedNodes = getFlattenedNodes(parent);
			flattenedTree.push(...flattenedNodes);
		}

		return flattenedTree;
	}

	/**
	 * When a node is clicked, set it as selected, and navigate to the file.
	 *
	 * When a node is shift-clicked, select all nodes from the last selected
	 * node to that node, and don't navigate to the file.
	 */
	handleNodeClick = (
		e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
		node: AnyDirectoryNode,
	) => {
		// If we are not in multi-select mode, just select the node and trigger the navigation handler
		if (!this.isMultiSelect) {
			this.selectedNodes = new Map([[node.id, node]]);
			this.#handleNodeNavigation(node);
			this.setRightClickedNode(null);
			return;
		}

		if (e.shiftKey) {
			// When a node is shift-clicked, if there is already a selection, select
			// all nodes from the last selected node to that node, and don't
			// navigate to the file. If there is no selection, just select the node.
			const lastSelectedNodeId = Array.from(this.selectedNodes.keys()).at(-1);
			if (!lastSelectedNodeId) {
				this.selectedNodes = new Map([[node.id, node]]);
			} else {
				const indexOfLastSelectedNode = this.flattenedVisibleNodes.findIndex(
					(n) => n.id === lastSelectedNodeId,
				);
				const indexOfSelectedNode = this.flattenedVisibleNodes.findIndex(
					(n) => n.id === node.id,
				);
				this.selectedNodes = new Map(
					this.flattenedVisibleNodes
						.slice(
							Math.min(indexOfLastSelectedNode, indexOfSelectedNode),
							Math.max(indexOfLastSelectedNode, indexOfSelectedNode) + 1,
						)
						.map((n) => [n.id, n]),
				);
			}
		} else if (e.metaKey || e.ctrlKey) {
			if (this.selectedNodes.has(node.id)) {
				this.selectedNodes.delete(node.id);
			} else {
				this.selectedNodes.set(node.id, node);
			}
		} else {
			// Otherwise, just select the node, and navigate to the file.
			this.selectedNodes = new Map([[node.id, node]]);
			this.#handleNodeNavigation(node);
		}
		this.setRightClickedNode(null);
	};

	handleDragStart = (event: DragStartEvent) => {
		const node = event.active.data.current?.node as
			| AnyDirectoryNode
			| undefined;
		if (!node) {
			return;
		}
		// If the node we are dragging is also selected, we should drag the
		// entire selection
		if (this.selectedNodes.has(node.id)) {
			this.draggingNodes = new Map(this.selectedNodes);
		} else {
			this.draggingNodes = new Map([[node.id, node]]);
		}
	};

	handleDragOver = (event: DragOverEvent) => {
		const over = event.over;
		if (!over) {
			this.dropOverNode = null;
			return;
		}
		if (over.id === ROOT_DROPPABLE_ID) {
			this.dropOverNode = ROOT_DROPPABLE_ID;
			return;
		}
		const node = over.data.current?.node as AnyDirectoryNode | undefined;
		if (!node) {
			this.dropOverNode = null;
			return;
		}
		this.dropOverNode = node;
	};

	/**
	 * Returns all descendants of nodes being dragged.
	 *
	 * We can't drag nodes into their descendants, so this is used to disable
	 * droppables.
	 */
	get descendantsOfDraggingNodes(): Set<FileId> {
		const descendants: Set<FileId> = new Set();
		for (const draggingNode of this.draggingNodes.values()) {
			for (const descendant of draggingNode.descendants) {
				descendants.add(descendant.id);
			}
		}
		return descendants;
	}

	handleDragEnd = (event: DragEndEvent) => {
		const over = event.over;
		if (over) {
			if (over.id === ROOT_DROPPABLE_ID) {
				this.appState.moveFiles({
					fileIds: Array.from(this.draggingNodes.keys()),
					newParentId: null,
				});
			} else {
				const parentNode = over.data.current?.node as
					| AnyDirectoryNode
					| undefined;
				if (!parentNode) {
					return;
				}

				if (this.descendantsOfDraggingNodes.has(parentNode.id)) {
					console.warn("Tried to move files into one of their descendants");
					return;
				}

				this.appState.moveFiles({
					fileIds: Array.from(this.draggingNodes.keys()),
					newParentId: parentNode.id,
				});
				// Expand when drop
				this.expandedNodes.set(parentNode.id, parentNode);
			}
		}
		// If we were dragging multiple nodes, clear the multi-select
		if (this.draggingNodes.size > 1) {
			this.selectedNodes.clear();
		}
		this.draggingNodes.clear();
		this.dropOverNode = null;
	};

	handleExpandClick = (node: AnyDirectoryNode) => {
		this.toggleExpandedNode(node);
	};

	toggleExpandedNode = (node: AnyDirectoryNode) => {
		if (this.expandedNodes.has(node.id)) {
			this.expandedNodes.delete(node.id);
		} else {
			this.expandedNodes.set(node.id, node);
		}
	};

	setSearchQuery = (query: string) => {
		this.searchQuery = query;
	};
}
