import type { AppState } from "@/contexts/app-context/app-context";
import type { SessionAssistantId, Step, StepId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";

export class StepStore {
	appState: AppState;

	steps: Map<StepId, Step> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	// Mutations
	createStepLocally = (step: Step) => {
		this.steps.set(step.step_id, step);
	};

	// Queries
	getStepsForAssistantSessionId = computedFn(
		(assistantSessionId: SessionAssistantId): Step[] => {
			return Array.from(this.steps.values()).filter(
				(step) => step.session_assistant_id === assistantSessionId,
			);
		},
	);
}
