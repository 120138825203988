import type { AppState } from "@/contexts/app-context/app-context";
import { SearchableMap } from "@/contexts/app-context/db-store/searchable-map";
import type { Folder } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export class FoldersStore {
	appState: AppState;
	items: SearchableMap<Folder, "folder_id">;

	constructor(appState: AppState, folders: Folder[]) {
		this.appState = appState;
		this.items = new SearchableMap(folders, {
			id: "folder_id",
			fields: ["file_name"],
		});

		makeAutoObservable(this);
	}
}
