import type { AppState } from "@/contexts/app-context/app-context";
import { SearchableMap } from "@/contexts/app-context/db-store/searchable-map";
import type { FeedChannel, FeedItemMetadata } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export class FeedChannelsStore {
	appState: AppState;
	items: SearchableMap<FeedChannel, "feed_channel_id">;

	constructor(appState: AppState, feedChannels: FeedChannel[]) {
		this.appState = appState;
		this.items = new SearchableMap(feedChannels, {
			id: "feed_channel_id",
			fields: ["file_name"],
		});

		makeAutoObservable(this);
	}
}

export class FeedItemsStore {
	appState: AppState;
	items: SearchableMap<FeedItemMetadata, "feed_item_id">;

	constructor(appState: AppState, items: FeedItemMetadata[]) {
		this.appState = appState;
		this.items = new SearchableMap(items, {
			id: "feed_item_id",
			fields: [
				"file_name",
				"feed_item_description",
				"feed_item_link",
				"feed_item_author",
			],
		});

		makeAutoObservable(this);
	}
}
